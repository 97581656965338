.GuideTourDefaultTooltip {
  &__content {
    padding: 0 32px 0 16px;
  }
  &__afterContent {
    padding: 16px 8px 0 8px;
    width: 100%;
    :global(img) {
      border-radius: 12px;
      width: 100%;
    }
  }

  &__arrow {
    width: 16px;
    height: 16px;
    background-color: white;
    transform: rotate(45deg);
  }
}
