.projects {
  &__no-project {
    background: white;
    border: 1px solid var(--gray-300);
    padding: 40px 60px;
    text-align: center;
  }

  &__group-headline {
    &_logo {
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }
    &_text {
      vertical-align: middle;
      display: inline-block;
    }
  }
}

@keyframes delayedIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

@keyframes shrink {
  0% {
    height: 82px;
  }
  100% {
    height: 6px;
  }
}

@keyframes errorFlash {
  0% {
    border-color: $red;
  }
  100% {
    border-color: var(--gray-300);
  }
}

.project-item {
  &__placeholder {
    min-height: 82px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &.project-exit-active {
      height: 0;
      min-height: 0;
      overflow: hidden;
      transition: all 0.3s ease-in;
      transition-delay: 0.5s;
    }
  }
  &__row {
    padding: 1rem;
    background: white;
    border-style: solid;
    border-width: 1px 1px 0 1px;
    border-color: var(--gray-300);
    box-sizing: border-box;
    &--error {
      animation: errorFlash 1s step-end forwards;
    }
    &--warning {
      border-left: var(--warning) 4px solid;
      padding-left: 12px;
    }
    &--deleting {
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      animation: shrink 0.4s ease-in forwards;
      .just-ui-progressBar-container {
        visibility: hidden;
        animation: 0.5s delayedIn forwards;
      }
    }

    &:last-child {
      border-bottom-width: 1px;
    }

    &:hover {
      background-color: #eceff1;
    }
  }

  &__name-active {
    color: #605fe3;
  }

  &__dropdown {
    display: inline-block;

    button,
    label {
      cursor: pointer;
    }

    &_upload {
      padding: 0;

      label {
        padding: 10px 20px;
        width: 100%;
      }
    }
  }

  &__action {
    .fa {
      color: rgba(0, 0, 0, 0.3);
      font-size: 1.5rem;
    }

    &:hover {
      .fa {
        color: rgba(0, 0, 0, 0.5);
      }
    }

    &-label {
      padding-top: 4px;
      padding-bottom: 4px;
      p {
        color: rgba(0, 0, 0, 0.3);
      }

      .fa {
        font-size: 1rem;
      }

      &:hover {
        p {
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
  &__progress-bar {
    margin: 2rem 0;
  }
}

.createProjectModal {
  .red-dot {
    color: var(--danger);
    padding: 0 6px;
  }

  .red-dot-jaicf {
    color: #ff6269;
    padding: 0 6px;
  }

  .tab {
    overflow: hidden;
  }

  form.is-invalid {
    input:invalid {
      border-color: $danger;
      box-shadow: 0 0 0 $input-focus-width rgba($danger, 0.25);
    }
  }

  .inputs-block {
    display: flex;
    justify-content: space-between;
    width: 100%;
    div {
      &:first-child {
        width: 100%;
        flex: 1;
        margin-right: 8px;
      }
    }
  }

  .info {
    display: flex;
    padding: 10px;
    margin: 0;
    p {
      margin-bottom: 0;
      color: var(--gray-900);
    }
  }

  .bordered-box {
    border: 1px solid var(--gray-200);
    border-radius: 3px;
    padding: 1rem 1.5rem;
    p {
      font-size: 12px;
      color: var(--gray-950);
    }
    h3 {
      font-weight: 500;
      font-size: 16px;
      color: var(--gray-950);
    }
  }

  .fake-input {
    border: 1px solid var(--gray-300);
    border-radius: 0.25rem;
    padding-left: 0.775rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--gray-200);
    div:first-child {
      flex: 1;
    }
    button {
      padding: 0.375rem;
      margin: 0 3px;
    }
  }

  .radio-buttons {
    label {
      p {
        margin-bottom: 8px;
      }
    }
  }
}
