.PhraseList_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding-bottom: 24px;
}
.PhraseList_massAddingCheckbox {
  border-top: 1px solid var(--gray-200);
  border-bottom: 1px solid var(--gray-200);
  padding-left: 24px;
  height: 56px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  & > *:first-child {
    position: relative;
    margin-right: 12px;
  }
}
