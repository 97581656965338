.HeaderNav {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-grow: 1;
  overflow: hidden;
  justify-content: flex-start;
  &.active {
    /*color: var(--blue-600, #2375B0);
    fill: var(--blue-600, #2375B0);*/
    cursor: pointer;
  }
}
