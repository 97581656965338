.errorTooltip {
  visibility: hidden;
  position: absolute;
  width: fit-content;
  display: block;
  background-color: var(--red-600);
  color: var(--white);
  transform: translateY(-50%);
  z-index: 20;
  line-height: 20px;
  font-size: 14px;
  padding: 4px 8px;
  border-radius: 6px;
  box-shadow: 0px 0px 1px 0px #113c5c0f, 0px 2px 8px 0px #0c2b421f, 0px 0px 2px 0px #0000001a;
  pointer-events: none;
  max-width: 132px;
  box-sizing: content-box;
  font-size: 12px;
  overflow-wrap: normal;
  &--active {
    visibility: visible;
  }
}

.arrow-left {
  &:before {
    content: '';
    position: absolute;
    left: -6px;
    top: 7px;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    left: -5px;
    top: calc(50% - 6px);
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: var(--red-600);
  }
}
