.ContentTooltip {
  width: 340px;
  font-size: 14px;
  line-height: 20px;
  color: var(--gray-800);

  &__title {
    font-weight: 700;
    padding: 8px 12px;
    color: var(--gray-950);
  }

  &__divider {
    width: 100%;
    height: 1px;
    background-color: var(--gray-200);
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px;
  }

  &__description {
    color: var(--gray-600);
    line-height: 16px;
  }

  &__actions {
    display: flex;
    gap: 8px;
  }
}
