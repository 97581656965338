.app-header {
  z-index: 10;
  .navbar-nav {
    height: 100%;
  }
  .divider {
    height: 100%;
    min-height: 54px;
    margin-left: 8px;
    margin-right: 8px;
    border-right: var(--gray-300) 1px solid;
  }
  .navbar-brand {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .cloud-menu-wrap {
    padding-right: 8px;
    .justui_dropdown {
      .dropdown-menu.show {
        right: 0;
        left: auto !important;
        transform: translate3d(0, 40px, 0px) !important;
        padding-bottom: 0.5rem;
      }
      & > button {
        background: transparent;
        padding: 0;
        border: none;
        border-radius: 50%;
      }
      .dropdown-item {
        &:active {
          a {
            color: #fff;
          }
        }
        a {
          display: flex;
          align-items: center;
          padding: 8px 20px;
          text-decoration: none;
          color: var(--gray-800);

          img {
            height: 24px;
            margin-right: 8px;
          }
        }
      }
      .product-dropdown {
        .dropdown-item {
          padding: 0;
        }
      }
    }
  }
}
