.phraseRow {
  background-color: var(--grey-100);
  display: grid;
  grid-template-columns: 52px 1fr 52px;
  min-height: 38px;

  &__part {
    display: flex;
    align-items: center;
    box-sizing: content-box;
    width: 100%;
    :global(.textAreaLikeElement) {
      border-radius: 0;
    }
    &--error {
      :global(.textAreaLikeElement) {
        padding-right: 32px;
      }
      :global(.inner-append) {
        margin-right: -24px;
      }
    }
  }

  &__part_action {
    color: var(--grey-600);
    border-radius: 0 !important;

    &:hover {
      color: var(--grey-950);
    }
  }

  &__tooltip {
    :global(.tooltip) {
      top: -4px !important;
    }
    background-color: var(--grey-600);
    color: var(--white);
  }
}
