@import '~@just-ai/just-ui/scss/basic/custom/functions';
@import '~@just-ai/just-ui/scss/basic/custom/variables';
@import '~bootstrap-scss/mixins/breakpoints';

.tree-link {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  .tree-link__carret-style {
    margin-left: 8px;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    svg.chevron-icon {
      font-size: 0.586rem;
    }
    min-height: 14px;

    .fa-sm {
      width: 0.75rem;
      height: 0.75rem;
    }
  }
  .tree-link__leaf {
    display: flex;
    align-items: center;
    flex: 1;
    min-height: 32px;
  }
  .tree-link__leaf-light {
    color: var(--primary-700);
    background: transparent;
    box-shadow: unset;
    &.disabled {
      color: var(--gray-600);
    }
    &.selected {
      border-right: var(--primary) 4px solid;
      color: var(--gray-950);
      background-color: var(--primary-200);
    }
    &:hover {
      background-color: var(--primary-200);
    }
  }
  .tree-link__leaf-dark {
    color: var(--gray-200);
    background: transparent;
    &.disabled {
      color: var(--gray-600);
    }
    &.selected {
      background: var(--gray-800);
      color: var(--gray-100);
    }
    &:hover {
      color: var(--white);
      background: var(--indigo-900);
    }
  }
  &__text {
    > div {
      flex: 1 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre;
    }
  }
}

.tree-message {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  color: var(--gray-200);
}

.justui_tree.theme-dark {
  .justui_tree__subtree.drop-target {
    background-color: var(--gray-500);
  }
}

.justui_tree.theme-light {
  .justui_tree__subtree.drop-target {
    background-color: var(--gray-100);
  }
}
