.fileAdder {
  .fileList {
    p {
      margin-bottom: 8px;
    }
  }
  .fileItem {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    color: var(--gray-600, #6a7178);
    padding: 0 0 0 8px;
    gap: 8px;

    .fileName {
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .fixColor {
      color: var(--gray-600, #6a7178);
      fill: var(--gray-600, #6a7178);
    }
  }
}

.addLink {
  background-color: var(--gray-100, #f4f5f5);
  padding: 12px;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 16px;

  .addLinkInputContainer {
    position: relative;
    .justui__icon {
      top: 10px;
      left: 8px;
      position: absolute;
      color: var(--gray-500, #888e93);
    }
    input {
      padding-left: 32px;
    }
  }
}
