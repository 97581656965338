.Notification {
  padding-top: 8px;
  box-shadow: 0 0 1px 0 rgba(17, 60, 92, 0.06), 0 14px 28px 0 rgba(12, 43, 66, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.12);

  &.withoutPadding {
    padding: 0;
    box-shadow: none;

    & .body {
      padding: 0;
    }
  }

  & .title {
    border-top: 1px solid var(--gray-200);
    border-bottom: 1px solid var(--gray-200);
    background: var(--white);
    display: flex;
    justify-content: space-between;
    gap: 8px;
    padding: 8px;

    color: var(--gray-950);
    font-weight: 700;
    line-height: 21px;
  }

  & .body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    background: var(--white);
    padding: 12px;

    & .description {
      font-size: 14px;
      color: var(--gray-800);
      max-width: 100%;
    }
  }
}
