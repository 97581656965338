.widgetCdqa {
  padding: 0 16px;
  margin-top: 32px;
  width: 100%;
  &__info {
    padding: 12px;
    border: 1px var(--grey-200) solid;
    border-radius: 4px;
    color: var(--grey-800);
    font-size: 12px;
    margin-top: 12px;
    cursor: pointer;
    * {
      cursor: pointer;
    }
    &:hover {
      background-color: var(--grey-100);
    }
    p {
      margin-bottom: 8px;
      font-weight: bold;
    }
  }
  &__clamped {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    b {
      font-size: 12px;
    }
  }
}

.cdqaWidgetModal {
  :global(.nav-link) {
    justify-content: center;
  }
  :global(.justui_tabs) {
    margin-top: 24px;
  }
  &__skillNameRow {
    font-size: 12px;
    margin-bottom: 8px !important;
    color: var(--grey-800);
    span {
      margin-right: 8px;
      padding: 2px 4px;
      border-radius: 2px;
      background-color: var(--grey-200);
    }
  }
  &__answerText {
    display: flex;
    overflow: hidden;
    max-width: 100%;
    min-width: 50px;
    max-height: 350px;
    white-space: pre-wrap;
  }
}
