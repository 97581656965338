.ReplicaAudioUploader_container {
  &.onDrag {
    .ReplicaAudioUploader_fileContainer {
      z-index: -2;
    }
  }

  .FileUploadContainer {
    cursor: pointer !important;
    height: 56px;
  }
}
