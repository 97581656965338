.faq-answer__drag-handler {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -5px;
  visibility: hidden;
  svg {
    color: var(--grey-600);
  }
}

.faq-answer__sortable-container {
  display: flex;
  flex-direction: column;
}

.faq-answer__part-content {
  display: flex;
  flex: 1;
  margin: 0 8px;
}

.faq-answer__part-container {
  &:not(:last-child) {
    > div {
      border-bottom: 1px dashed var(--grey-300);
    }
  }
  &:first-child {
    > div {
      border-bottom: 1px dashed var(--grey-300);
    }
  }
  .justui__icon-wrapper {
    display: flex;
  }
  .justui_icon-button.justui_button.btn-flat.btn.btn-secondary {
    visibility: hidden;
    :not(:disabled) {
      &:hover {
        background-color: transparent;
        box-shadow: none;
      }
      &:active {
        background-color: transparent;
        box-shadow: none;
        border-color: transparent;
      }
      &:focus {
        background-color: transparent;
        box-shadow: none;
      }
    }
  }
  &:hover {
    * {
      visibility: visible;
    }
  }
}

.faq-answer-part--dragged {
  background-color: var(--grey-100);
  margin: 0 2px;
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.14))
    drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.18));
  * {
    visibility: visible;
  }
  .faq-answer__part-content {
    margin-left: 6px;
    margin-right: 10px;
    border-bottom: 1px dashed var(--grey-300);
  }
}

.faq-answer-part__modal {
  .modal-content {
    min-height: 370px;
    form {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }
  &--image {
    .modal-content {
      min-height: 460px;
    }
  }
  .InlineButtons-full-width {
    .btn {
      padding-top: 5px;
      padding-bottom: 5px;
      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
      &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
    }
  }
}

.faq-answer__add-part-btns {
  display: flex;
  gap: 8px;
  .faq-answer__add-btn {
    border-style: dashed;
    display: flex;
    min-width: 150px;
    .justui__icon-wrapper {
      display: flex;
    }
    &:disabled {
      pointer-events: none;
    }
  }
}

.faq-answer__payload {
  flex: 1 0 0;
  min-width: 0;

  .editorContainer {
    font-family: inherit;
    .editor {
      padding: 8px 16px 8px 4px;
      min-height: 36px;
      border: none;
      overflow-wrap: anywhere;
    }
    .editControls {
      background-color: white;
      bottom: auto;
      top: -30px;
      right: 100px;
      left: auto;
      border: 1px solid var(--grey-200);
      border-radius: 5px;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 6px 12px rgba(12, 43, 66, 0.16),
        0px 0px 1px rgba(17, 60, 92, 0.06);
    }
  }
}

.faq-anwer-modal__img-preview {
  position: relative;
  img {
    max-width: 100%;
    object-fit: cover;
  }
  .justui_icon-button.justui_button.btn.btn-none {
    position: absolute;
    padding: 6px;
    border-radius: 50%;
    background-color: white;
    top: 6px;
    right: 6px;
    .justui__icon-wrapper {
      display: flex;
      svg {
        width: 8px;
        height: 8px;
      }
    }
  }
}

.faq-answer-part__media {
  display: flex;
  padding: 10px 0;
  gap: 8px;
  > div {
    display: flex;
    flex: 1 0 0;
    overflow: hidden;
  }
  p,
  a {
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    max-width: 35vw;
  }
  &__content {
    flex: 2;
    gap: 8px;
    border: 1px dashed #c3c6c9;
    border-radius: 4px;
    min-height: 65px;
    align-items: center;
    padding: 1rem;
    img {
      width: 70px;
      height: 40px;
      object-fit: cover;
    }
    .justui_icon-button.justui_button.btn.btn-none {
      padding: 8px;
      margin-left: auto;
    }
    &--audio {
      border-color: transparent;
      padding: 0;
      flex-basis: 3% !important;
      > div {
        width: 100%;
        .audio-uploader__info-container {
          position: relative;
          background-color: transparent;
        }
      }
      > button {
        margin-bottom: auto;
        margin-top: 17px;
        z-index: 100;
      }
      .unsupported-channels-block {
        display: none;
      }
    }
    &--file {
      p {
        line-height: 21px;
      }
    }
  }
  &__url {
    flex: 2 !important;
    flex-direction: column;
    justify-content: center;
  }
}

.faq-answer-part__file-preview {
  flex: 1;
  display: flex;
  width: 100%;
  max-width: 100%;
  gap: 12px;
  overflow: hidden;
  p {
    margin-bottom: 0;
  }
  .justui__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .justui_icon-button {
    margin-left: auto;
  }
  text-align: left;

  .faq-answer-part__file-preview_fileName {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow: hidden;

    .faq-answer-part__file-preview_fileName_p {
    }
    .faq-answer-part__file-preview_fileName_b {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.faq-answer__tooltip {
  .tooltip-inner {
    p,
    a {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 0;
    }
    a {
      color: white;
    }
  }
  &--top-fix {
    .tooltip.show {
      top: -16px !important;
    }
  }
}
