.JGraphLoadError {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  background-color: var(--white);
  color: var(--gray-950);

  @keyframes smooth-appear {
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .content {
    width: 500px;
    animation: smooth-appear 1s ease forwards;
    transform: translateY(100%);
    opacity: 0;

    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      padding: 24px;
      text-align: center;
    }

    .description {
      display: flex;
      flex-direction: column;
      gap: 16px;

      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      margin: 0 24px;

      &__text {
        white-space: break-spaces;
        word-break: break-word;
      }
    }

    .hint {
      white-space: pre-wrap;
      word-break: break-word;
    }

    .actions {
      display: flex;
      justify-content: center;
      margin: 24px 0;
    }
  }
}
