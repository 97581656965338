.ReactionItemContainer {
  margin-left: -32px;
  padding-left: 32px;
  &:hover {
    .ReactionItem {
      background-color: darken(#f4f5f5, 4%);
    }
    .handler {
      display: block;
    }
  }
}
.ReactionItem {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 12px;
  align-items: flex-start;
  //border-bottom: 1px solid var(--gray-200, #E0E1E3);
  padding: 8px;
  position: relative;
  background-color: var(--gray-100, #f4f5f5);
  border-radius: 8px;
  box-shadow: 0 0 1px rgba(57, 56, 176, 0.05), 0 1px 4px rgba(57, 56, 176, 0.1);

  .noBorder {
    button {
      border-color: transparent;
      &:active {
        background-color: darken(#f4f5f5, 8%);
      }
      &:hover {
        background-color: darken(#f4f5f5, 8%) !important;
      }
    }
  }

  &.active {
    cursor: pointer;
  }

  .handler {
    display: none;
    top: 50%;
    margin-top: -16px;
    cursor: move;
    position: absolute;
    left: -28px;
    color: var(--gray-500, #888e93);
  }

  .nameAndValueContainer {
    flex-grow: 1;
    overflow: hidden;

    .name {
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.0075em;
      color: var(--gray-800, #333c45);
      margin-bottom: 4px;
    }
    .value {
      display: flex;
      align-items: center;
      gap: 4px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.0075em;
      color: var(--gray-600, #6a7178);
      b {
        max-width: 86%;
        display: inline-flex;
        margin: 0;
        flex-basis: 50%;
        flex-shrink: 3;
      }
      svg{
        display: inline-flex;
        min-width: 12px;
        width: 12px;
      }
      span{
        display: inline-flex;
        flex-basis: 80%;
        flex-shrink: 1;

      }

    }
  }

  .reactionIconContainer {
    padding-top: 4px;
  }
  .reactionIcon {
    width: 21px !important;
    height: 21px;
    color: #fd971f !important;

    &.Email {
      color: #9089fa !important;
    }
    &.violet {
      color: #9089fa !important;
    }
  }

  &.last {
    border-bottom: none;
  }
}
