.BotSayTab {
  .hint {
    padding: 12px;
    border-radius: 8px;
    background: var(--indigo-100);

    &__link {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .sayField {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--gray-200);
    border-radius: 4px;

    & :global(.textAreaLikeElement) {
      border: none;
    }

    .playWrapper {
      position: relative;
      padding: 5px 8px;
      border-top: 1px solid var(--gray-200);

      &_isPlayed {
        opacity: 0.8;
      }
    }
  }

  .error {
    margin-top: 4px;
    color: var(--red-600);
  }
}

.ttsHintWrapper {
  :global(.justui_popover-body) {
    padding: 12px;
  }

  :global(.popover) {
    top: 10px !important;
    width: 400px;
    max-width: 400px;
  }

  :global(.arrow) {
    display: none !important;
  }
}
