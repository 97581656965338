.app {
  display: grid;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  max-height: 100vh;

  grid-template-rows: min-content minmax(0, 1fr);
  grid-template-columns: min-content minmax(0, 1fr) min-content;
  grid-template-areas:
    'header  header  header'
    'leftbar main rightbar';

  & > .global-header {
    grid-area: header;
    z-index: 13;
  }

  & > .sidebar {
    grid-area: leftbar;
    border-right: 1px solid var(--grey-950);
  }

  & > .main {
    grid-area: main;
    display: grid;
    position: relative;

    grid-template-rows: min-content minmax(0, 1fr) min-content;
    grid-template-areas:
      'breadcrumbs'
      'content'
      'bottompanel'
      'bottompaneltoolbar';

    & > .breadcrumbs-panel {
      grid-area: breadcrumbs;
    }

    & > .main-container {
      grid-area: content;
      overflow-y: auto;
    }

    & > .global-bottom-panel {
      grid-area: bottompanel;
    }
    & > .global-bottom-panel-toolbar {
      grid-area: bottompaneltoolbar;
    }

    & > .global-bottom-panel.justui_vertically-resizable-area--maximized {
      grid-area: unset;
      grid-row: 1/-1;
      position: absolute;
      width: 100%;
      z-index: 5;
    }

    & > .notification-container {
      top: 30px;
      position: absolute;
      pointer-events: none;

      & > * {
        pointer-events: initial;
      }

      .notifications-hidden {
        pointer-events: none;
      }
    }
  }

  & > .global-right-panel {
    grid-area: rightbar;
  }
}

.Session_container .Session_head {
  top: -241px;
}
