.unrecognized-requests-modal {
  &__state-row {
    .unrecognized-requests-modal__remove-btn {
      visibility: hidden;
      color: gray;
      text-decoration: underline;
    }

    &:hover {
      .unrecognized-requests-modal__remove-btn {
        visibility: visible;
      }
    }
  }
}
