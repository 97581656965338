.call-list-create-form {
  .drop-zone {
    border-style: dotted;
    border-width: 1px;
    border-color: $border-color;
    padding: 1rem;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;

    &__description {
      position: absolute;
    }

    &__restrictions-list {
      list-style: none;
      padding: 0;
      margin: 0;

      & > li::before {
        content: '— ';
      }
    }
  }

  .validation-errors {
    list-style: none;
    max-height: 10rem;
    overflow: auto;
    & > li::before {
      content: '— ';
    }
  }

  .confirm-checkbox {
    display: flex;
    align-items: center;
  }

  .red-dot {
    color: var(--danger);
    padding: 0 6px;
    display: inline-block;
  }

  .btn:disabled {
    background-color: #9c9c9c;
    border-color: #6f6e6e;
    color: var(--white);
  }
}
