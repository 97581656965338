.limits-table {
  display: flex;
  justify-content: space-between;
  padding-left: 8px;

  .name-col {
    width: 30%;
  }
  .extra-col {
    width: 25%;
  }
  .used-limit-block {
    //flex-grow: 1;
  }
  .progress-limit-block {
    flex-grow: 1;
  }
  &-heading {
    font-size: 12px;
    line-height: 18px;
    color: var(--gray);
    padding-bottom: 4px;
    margin-bottom: 8px;
    border-bottom: 1px solid var(--gray-300);
    height: 23px;
    font-weight: bold;
  }
  &-row {
    line-height: 18px;
    margin-bottom: 4px;
    align-items: center;
    display: flex;
    height: 18px;

    svg {
      margin-left: 4px;
      font-size: 0.875em;
    }
  }

  .limit-progress {
    width: 145px;
    margin-left: 8px;
  }
}

.PackageAddingModal {
  .card {
    margin: 0;

    &-body {
      display: flex;

      h3 {
        margin-top: 7px;
      }

      .label-block {
        width: 300px;
      }
      .button-block {
        width: 133px;
        padding-left: 8px;
      }
      .select-col {
        flex-grow: 1;

        .select-row {
          display: flex;
          &:not(:last-child) {
            margin-bottom: 16px;
          }
          .ReactSelect {
            flex-grow: 1;
          }
        }
      }
    }
  }
  .justui_banner {
    color: var(--gray-950);
    span {
      color: var(--primary-500);
    }
    z-index: 0;
  }

  .bonus-label {
    margin-top: 24px;
    margin-bottom: 24px;
    height: 21px;
  }
  .mixed-minutes-select-menu-width-fix {
    .ReactSelectPrefix__menu {
      width: 330px;
    }
  }
}

.PackageDeductingModal {
  .card {
    margin: 0;

    &-body {
      display: flex;

      h3 {
        margin-top: 7px;
      }
      .label-block {
        width: 278px;
      }
      .button-block {
        width: 133px;
        padding-left: 8px;
      }
      .select-col {
        flex-grow: 1;

        .select-row {
          display: flex;
          &:not(:last-child) {
            margin-bottom: 16px;
          }
          .ReactSelect {
            flex-grow: 1;
          }
        }
      }
    }
  }
  .justui_banner {
    color: var(--gray-950);
    span {
      color: var(--primary-500);
    }
    z-index: 0;
  }

  .bonus-label {
    margin-top: 24px;
    margin-bottom: 24px;
    height: 21px;
  }
  .mixed-minutes-select-menu-width-fix {
    :global(.ReactSelectPrefix__menu) {
      width: 330px;
    }
  }
}

.user-tariff-limit-popover {
  .popover {
    left: 7px !important;
  }
}
