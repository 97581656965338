.call-task-edit {
  .asr-tts-balance {
    font-size: 1.2rem;
    .row {
      justify-content: space-between;
    }
    &.positive {
      color: $state-success-text;
      background-color: $state-success-bg;
      border-color: $state-success-border;
    }
    &.negative {
      color: $state-danger-text;
      background-color: $state-danger-bg;
      border-color: $state-danger-border;
    }
  }
  .input-group-text {
    margin-left: 5px;
    vertical-align: -webkit-baseline-middle;
  }

  .dateTimePicker {
    margin-top: -9px;
  }
  .timePickerRow {
    margin-top: 10px;
    align-items: center;
    justify-content: flex-start;

    .hyphen {
      max-width: 1rem;
      padding: 0;
      text-align: center;
    }
  }
}

.call-task-in-progress {
  background-color: #d9f2d9;
}

.call-task-table-head {
  background-color: var(--primary-100);
  padding-top: 5px;
  padding-bottom: 5px;
  .col {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.214rem;
    letter-spacing: 0.02em;
    color: #495057;
  }
}

@media (max-width: 1366px) {
  .col-12-custom {
    flex-basis: 100% !important;
    max-width: 100% !important;
  }
}
