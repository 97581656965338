.jsonContainer {
  background-color: var(--blue-100);
  padding: 16px;
  position: relative;
  &__text {
    white-space: pre-wrap;
    overflow-wrap: anywhere;
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
    font-family: 'IBM Plex Mono';
    font-style: normal;
    font-weight: 400;
    padding-right: 20px !important;
  }
  :global(.justui_icon-button.justui_button) {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 1;
  }
}
