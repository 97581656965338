.JGraphStartTooltip {
  position: relative;
  box-sizing: border-box;
  max-width: 500px;
  padding: 40px 24px 24px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 14px 28px rgba(12, 43, 66, 0.12), 0 0 1px rgba(17, 60, 92, 0.06);
  background: radial-gradient(50% 50% at 0 0, rgba(253, 151, 31, 0.2) 20%, rgba(253, 151, 31, 0) 100%),
    radial-gradient(50% 50% at 100% 100%, rgba(254, 223, 120, 20%) 20%, rgba(254, 223, 120, 0) 100%), white;
  border-radius: 12px;

  color: var(--gray-800);
  font-size: 16px;

  &__title {
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    font-weight: 700;
    color: var(--indigo-600);
  }

  &__content {
    padding: 24px 0 32px 0;
    text-align: center;
  }

  &__footer {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 8px;
  }
}
