.TtsOperatorsInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .table {
    table-layout: fixed;
    width: 100%;
    font-size: 14px;
    color: var(--gray-800);

    tbody tr {
      border-top: 1px solid var(--gray-200);
    }

    td, th {
      padding: 8px;
    }


    @mixin copyBox {
      cursor: pointer;
      padding: 0 4px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      border-radius: 2px;
      border: 1px solid var(--gray-300);
      background: var(--gray-100);
      white-space: pre-line;

      &:hover {
        opacity: .9;
      }
    }

    &__termRow {
      display: inline-block;
    }

    &__term {
      @include copyBox;
    }
    &__example {
      margin-top: 8px;
      @include copyBox;
    }
  }

  .footer {
    padding: 4px 0 0 0;

    .docLink {
      cursor: pointer;
      color: var(--indigo-600);
      &:hover {
        opacity: .8;
        color: var(--indigo-600);
      }
    }
  }

  .bottomNotification {
    position: relative;

    &__wrapper {
      position: absolute;
      width: 100%;
      height: 28px;
      transform: translateY(-100%);
      pointer-events: none;
      overflow: hidden;
    }

    &__notification {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 4px 8px;
      background: var(--green-500);
      color: var(--white);
      text-align: center;
      transform: translateY(100%);
      transition: transform 300ms ease-in-out;
    }

    &__notification.active {
      transform: translateY(0);
    }
  }
}


