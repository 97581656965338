.global-bottom-panel {
  background-color: var(--gray-900);
  color: var(--gray-100);
  border-left: 1px solid var(--gray-950);
}

.global-bottom-panel-toolbar {
  background-color: var(--gray-900);
  color: var(--gray-100);

  .btn {
    color: var(--gray-100);
    &:hover {
      color: var(--white);
    }
  }
}
