.HttpRequest {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;

  .HttpRequestMain {
    background-color: var(--gray-100, #f4f5f5);
    padding-bottom: 0;
  }
  .methodAndUrl {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    .method {
      max-width: 100px;
      > div {
        margin-bottom: 20px;
      }
    }
    .url {
      flex-grow: 1;
      > div {
        margin-bottom: 20px;
      }
    }
  }
  .tabs {
    display: flex;
    .justui_tabs {
      flex-grow: 1;
      margin-bottom: 8px;
      background-color: var(--gray-100, #f4f5f5);

      > li {
        flex-grow: 1;

        a {
          justify-content: center;
        }
      }
    }
  }
  .requestSettings {
    padding-top: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .responseTab {
    display: grid;
    grid-template-areas: 'title value delete';
    grid-template-columns: 2fr 3fr 38px;

    > div {
      padding: 12px 8px 12px 0;
    }

    .title {
      color: var(--gray-600, #6a7178);
      border-bottom: 1px solid var(--gray-200, #e0e1e3);
      line-height: 20px;
    }
    .headerName {
    }
  }

  .addBtn {
    display: flex;
    align-items: center;
  }
}
