.EmptyWarningBanner {
  .button {
    background: var(--gray-800);
    color: var(--yellow-300);
    border-color: transparent;

    &:focus {
      background: var(--gray-800);
      color: var(--yellow-300);
      border-color: transparent;
      border: 1px solid var(--indigo-800) !important;
    }

    &:hover {
      background: var(--yellow-300);
      color: var(--gray-800);
      border: 1px solid var(--gray-800) !important;
      border-color: var(--gray-800) !important;
    }

    :active {
      background: var(--yellow-400);
    }
  }
}
