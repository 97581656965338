.Message_Checkbox_container {
  display: grid;
  grid-template-columns: 14px auto;
  grid-template-rows: min-content;
  gap: 10px;
  padding-left: 24px;
}

.Message_labels_checkbox_container {
  height: 100%;
  width: 14px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 24px;
}
.Message_labels_checkbox_container > * {
  position: relative;
  top: 1px;
}
.Message_labels_checkbox_container ~ * {
  padding-left: 0 !important;
}

.Message_container {
  position: relative;
  padding: 24px;
  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: auto auto;
  gap: 12px;
  border-bottom: 1px solid var(--gray-200);
}
.Message_container:first-child {
  border-top: 1px solid var(--gray-200);
}
.Message_container .Message_form_buttons {
  width: 100%;
  padding-top: 0.75rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.Message_container .Message_form_buttons > div:first-child > button {
  position: relative;
  border-width: 0;
  padding: 4px 12px;
}
.Message_container .Message_form_buttons > div:first-child > button:not(:last-child):after {
  content: "";
  position: absolute;
  right: -0.5px;
  top: 3px;
  height: 24px;
  width: 1px;
  background-color: var(--gray-200);
}
.Message_container .Message_pattern_class {
  grid-column: 1/1;
  grid-row: 1/1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  margin-bottom: 4px;
}
.Message_container .Message_pattern_class * {
  font-size: 0.75rem;
}
.Message_container .Message_pattern_class > * > *:first-child {
  margin-right: 4px;
}
.Message_container .Message_time {
  margin-bottom: 4px;
  grid-column: 2/2;
  grid-row: 1/1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.75rem;
  gap: 8px;
}
.Message_container .Message_time_splitter {
  height: 14px;
  width: 1px;
  background-color: #ccc;
}
.Message_container .Message_client {
  grid-column: 1/-1;
}
.Message_container .Message_client > * {
  width: 100%;
}
.Message_container .Message_bot {
  grid-column: 1/-1;
  padding-right: 5rem;
}
.Message_container .Message_form {
  margin-top: 4px;
  grid-column: 1/-1;
  border-top: 1px solid var(--gray-200);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.Message_container .Message_form > *:first-child > .Message_container .Message_form:not(:last-child) {
  border-right: 1px solid var(--gray);
}
.Message_container .Message_client, .Message_container .Message_bot {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.Message_container.Message_container_noMarkup .Message_time {
  grid-column: 1/3;
  grid-row: 1/1;
  justify-self: start;
}
.Message_container .Message_markup {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.Message_container .Message_label {
  color: var(--gray);
}
.Message_container .Message_pattern {
  max-width: 15rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.Message_container .Message_form_item_container {
  margin-top: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.Message_container .Message_form_item_container > span:first-child {
  margin-bottom: 4px;
  font-size: 12px;
  color: var(--gray);
}
.Message_container .Message_form_item_container > div:last-child {
  width: 100%;
  min-height: 41px;
  padding: 0rem 3px 0rem 1rem;
  border: 1px solid var(--gray-200);
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--gray-100);
}
.Message_container .Message_form_item_container > div:last-child > * {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.Message_container .Message_form_item_container > div:last-child > *:last-child {
  justify-content: flex-end;
}
.Message_container .Message_form_item_labels_short {
  width: 100%;
  padding: 8px !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--gray-100);
}
.Message_container .Message_form_item_labels_short > div {
  gap: 8px !important;
}
.Message_container .Message_form_item_labels_short > div .Message_form_green {
  margin: 0.5rem;
  color: #215433;
  background-color: #C0E7CE;
  border-radius: 2px;
  padding: 4px 8px !important;
  line-height: 16px;
  font-size: 0.75rem;
  margin: 0 !important;
  background-color: #C0E7CE;
  border-color: #C0E7CE;
  color: #215433;
}
.Message_container .Message_form_item_green, .Message_container .Message_form_item_blue {
  margin: 0.5rem;
  color: #215433;
  background-color: #C0E7CE;
  border-radius: 2px;
  padding: 4px 8px !important;
  line-height: 16px;
  font-size: 0.75rem;
}
.Message_container .Message_form_item_blue {
  background-color: #2289AF;
  margin: 0.5rem 0.25rem;
  color: var(--white);
}

.MessageForm_labelsGroups {
  display: grid;
  gap: 8px;
  max-width: 100%;
}
.MessageForm_labelsGroups label {
  width: 100%;
  color: #4d4d4d;
  margin: 0 !important;
}
.MessageForm_labelsGroups > div {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 8px;
}
.MessageForm_labelsGroups > div > * {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.MessageForm_labelsGroups > div > *:not(input) > *:first-child {
  margin-right: 0.25rem;
}
.MessageForm_labelsGroups > div:not(:last-child) {
  margin-bottom: 8px;
}
.MessageForm_labelsGroups > div button {
  padding: 3px 8px !important;
  line-height: 16px;
}
.MessageForm_labelsGroups > div button.Message_label_button {
  background-color: var(--gray-100);
  color: var(--gray);
}
.MessageForm_labelsGroups > div button.Message_label_button.btn-success {
  background-color: #C0E7CE;
  border-color: #C0E7CE;
  color: #215433;
}
.MessageForm_labelsGroups > div > input {
  max-width: 15rem;
  height: 30px;
  position: relative;
}
.MessageForm_labelsGroups > div > input:after {
  content: "Enter";
  position: absolute;
  top: 0;
  right: 0.5rem;
  line-height: 30px;
}

.Message_audios_answers {
  margin-top: 12px;
  margin-left: 4rem;
  margin-bottom: 20px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 1rem;
  width: calc(100% - 4rem);
  background: var(--gray-100);
}

.Message_audio_head {
  display: grid;
  grid-template-columns: 4rem minmax(0, 1rem) auto;
}
.Message_audio_head > * {
  display: block;
}
.Message_audio_head > span {
  color: #000 !important;
}
.Message_audio_head > svg {
  margin-top: 0.125rem;
}
.Message_audio_head > *:first-child {
  width: 4rem;
  display: block;
}
.Message_audio_head > *:last-child {
  margin-left: 0.25rem;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
}

.Message_answer_question {
  margin-top: 0.75rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.Message_answer_question > *:first-child {
  width: 4rem;
}
.Message_answer_question_type-raw {
  word-break: break-all;
}

.Message_openInNewTab {
  position: absolute;
  bottom: 24px;
  right: 24px;
  font-size: 0.75rem;
  text-decoration: none;
  align-self: end;
}
.Message_openInNewTab > *:first-child {
  font-size: 0.75rem;
}
.Message_openInNewTab > *:first-child:hover, .Message_openInNewTab > *:first-child:focus {
  text-decoration: underline;
}
.Message_openInNewTab svg {
  margin-left: 0.25rem;
  height: 12px;
  width: 12px;
  transform: scale(1);
  transition: 200ms;
}
.Message_openInNewTab svg:hover, .Message_openInNewTab svg:focus {
  transform: scale(1.2);
}

