.controlsGroup {
  padding-left: 3px;
  padding-bottom: 2px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .fieldForm {
    display: flex;
    flex-direction: column;
    label {
      margin-bottom: 8px;
    }
    &__error {
      margin-top: 4px;
      font-size: 12px;
      color: var(--red-600);
    }
  }
}

.ChangeTokenModal {
  &__tokenName {
    color: var(--grey-800);
    font-weight: 700;
  }
}
