.JGraphStatusIndicator {
  position: relative;
  display: inline-flex;
  justify-content: flex-end;
  gap: 4px;
  align-items: center;
  padding: 4px;
  color: var(--gray-600);
  overflow: hidden;

  :global(.justui__icon-wrapper) {
    display: flex;
  }

  &:hover {
    border-radius: 4px;
    background: var(--gray-100);
  }

  &__title {
    white-space: nowrap;
    will-change: opacity, width, padding-left;
    transition: 500ms opacity ease-in-out, calc(var(--animationTime) * 1ms) width,
      calc(var(--animationTime) * 1ms) padding-left ease-in-out;
    overflow: hidden;
    opacity: 0;
    width: 0;
  }

  &__showTitle &__title {
    width: 100%;
    padding-left: 4px;
    opacity: 1;
  }

  &__rotateIcon svg {
    animation: rotating 2s linear infinite;
  }

  &__tooltip {
    position: absolute;
    top: 30px;
    right: 0;
    z-index: 1;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
