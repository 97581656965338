.ThemesContextMenu {
  position: fixed;
  background-color: var(--white, #fff);
  z-index: 4;
  padding: 8px 0;
  border-radius: 4px;
  box-shadow: 0 0 1px 0 rgba(17, 60, 92, 0.06), 0 6px 12px 0 rgba(12, 43, 66, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  &__item {
    padding: 8px 12px;

    color: var(--gray-800);
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.28px;

    &:not([data-disable='true']):hover {
      cursor: pointer;
      background-color: var(--gray-100);
    }

    &[data-disable='true'] {
      cursor: not-allowed;
      color: var(--gray-400);
    }
  }
}
