.shifted-snackbar {
  &--widget {
    .notification-container {
      right: 350px;
    }
  }
  &--panel {
    .notification-container {
      right: 255px;
    }
  }
}
