.AudioUploader {
  &__infoContainer {
    background: var(--gray-100);
    border: 1px dashed var(--gray-300);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    margin-right: 3px;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 8px;
    span {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__titleIcon svg {
    display: flex;
  }

  &__tools {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .hasError {
    border: 1px solid var(--danger);
  }

  .hideLoader {
    display: none !important;
  }

  .uploadBox {
    &:hover {
      cursor: pointer;
    }
  }

  .uploadLink {
    color: var(--primary);
    border-bottom: 1px dashed var(--primary);
    line-height: 14px;
  }
}
