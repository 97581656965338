.thead-default tr th {
  position: relative;
  .hoverMarksButton {
    top: 50%;
    margin-top: -17px;
    position: absolute;
    display: none;
    right: -40px;
  }
  &:hover {
    .hoverMarksButton {
      display: block;
    }
  }
}

.table.table-thin {
  th {
    padding: 0.25rem 0.75rem;
    font-weight: normal;
  }

  td {
    padding: 0.25rem 0.75rem;
  }
}
