.faqImportModal {
  &__fileUploadContainer {
    align-items: flex-start;
    padding: 1rem;
    min-height: 97px;
    a {
      text-underline-offset: 4px;
    }
    span {
      line-height: 18px;
    }
    &__progress {
      min-height: auto;
    }
  }
  &__fileStatusBlock {
    display: flex;
    align-items: center;
    gap: 16px;
    text-align: left;
    width: 100%;
    > div:last-of-type {
      margin-left: auto;
      display: flex;
      gap: 8px;
      span {
        padding: 4px;
        cursor: pointer;
      }
    }
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 4px;
    }
  }
  &__fileErrorBlock {
    min-height: 18px;
    > div {
      background-color: var(--red-100);
      padding: 1rem;
      margin-top: 1rem;
    }
  }
  &__fileErrorBlock__errorHeader {
    width: 100%;
    display: flex;
    > div {
      margin-left: auto;
      display: flex;
      align-items: center;
      gap: 12px;
      div {
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
        p {
          color: var(--primary);
          line-height: 1rem;
          font-size: 0.75rem;
        }
      }
    }
    p {
      margin-bottom: 0;
    }
  }
  &__fileErrorBlock__errorBody {
    white-space: pre-line;
    max-height: 100px;
    overflow-y: scroll;
  }
  &__infoBlock {
    background-color: var(--blue-100);

    display: flex;
    gap: 6px;
    align-items: center;

    padding: 0.75rem;
    border-radius: 3px;
    margin-bottom: 1rem;

    p {
      margin-bottom: 0;
    }

    svg {
      width: 20px !important;
      height: 20px !important;
    }
  }
}
