.ActivationsAddingMenu {
  .title {
    display: flex;
    align-items: center;
    flex-direction: row;
    color: var(--gray-600, #6a7178);
    margin-top: 8px;

    p {
      margin-bottom: 0;
      flex-grow: 1;
    }
  }
  .addingTypesContainer {
    margin: 16px 0;
  }
}
.splitter {
  height: 16px;
  width: 1px;
  background-color: var(--gray-200, #e0e1e3);
}

.EventActivation {
  .divider {
    width: 100%;
    height: 1px;
    background-color: var(--gray-200, #e0e1e3);
    margin: 20px 0;
  }
  .SearchBar {
    margin-bottom: 16px;
  }
  .GroupTitle {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0075em;
    color: var(--gray-600, #6a7178);
    margin: 12px 0;
    font-weight: 700;
  }
  .selectedEventDescription {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
    margin-top: 8px;
    background: var(--blue-100, #f2f5fb);
    border-radius: 4px;
    padding: 8px 12px;
    margin-bottom: 20px;
    div:nth-child(1) {
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.01em;
      color: var(--gray-600, #6a7178);
    }
    div:nth-child(2) {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.01em;
      color: var(--gray-600, #6a7178);
    }
  }
}
.addingType {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: 2px;
  margin-bottom: 8px;
  cursor: pointer;
  padding: 8px 12px;
  background-color: var(--white, #fff);
  border-radius: 8px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(12, 43, 66, 0.06), 0 0 1px rgba(17, 60, 92, 0.06);

  .addingTypeText {
    display: flex;
    flex-direction: column;
    gap: 2px;
    flex-grow: 1;
  }
  .addingTypeTitle {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0075em;
    color: var(--gray-800, #333c45);
  }
  .addingTypeDescription {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: var(--gray-600, #6a7178);
  }
  .addingTypeInfo {
    min-width: 24px;
  }
}

.PatternsActivation {
  .initialInput {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;
    align-items: flex-end;
    div {
      flex-grow: 1;
    }
  }
  .divider {
    width: calc(100% + 32px);
    height: 1px;
    background-color: var(--gray-200, #e0e1e3);
    margin: 16px -32px 16px 0;
  }
  .divider2 {
    width: calc(100% + 64px);
    height: 1px;
    background-color: var(--gray-200, #e0e1e3);
    margin: 20px -32px 20px -32px;
  }
  .createdPattern {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;
    align-items: center;
    margin-bottom: 8px;
  }
  .warningMessage {
    margin: 16px 0;
  }
}

.CreateCailaIntentModal {
  :global {
    .modal-body,
    .scrollbar-container,
    .modal-body-container {
      padding: 0 !important;
    }
    .scrollbar-container.ps {
      overflow: visible !important;
      .ps__rail-x,
      .ps__rail-y {
        display: none !important;
      }
    }
  }
}
