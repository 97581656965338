.qa-table {
  tr {
    td {
      padding: 0.3rem;
      &:first-child {
        border-right: 1px solid var(--gray-300);
      }
    }
  }
}

.qa-task {
  width: 300px;
  .btn {
    margin-left: 5px;
    &:first-child {
      margin-left: 0;
    }
  }

  .mark-group {
    .mark-group-name {
      font-weight: bold;
    }
  }
}

.edit-class-modal {
  .class-modal-content {
    .tab-content {
      border-bottom: 0;
    }

    .tab-pane {
      padding-bottom: 0;
    }

    .theme-selector {
      border-bottom: 0;
    }

    .nav-tabs {
      li.nav-item:first-child {
        .nav-link {
          &:before {
            height: 88%;
            background-color: var(--gray-100);
          }

          &.active {
            &:before {
              height: 100%;
            }
          }
        }
      }
    }
  }

  .nav-tabs {
    li.nav-item {
      width: 48.9%;

      .nav-link {
        &:after,
        &:before {
          background-color: var(--gray-100);
        }
      }
    }
  }

  .modal-body {
    padding-bottom: 0;
  }
}

.theme-selector {
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.25;
  background-color: var(--white);
  background-image: none;
  background-clip: padding-box;
  overflow-y: auto;
  max-height: 48vh;
  border-top: 1px solid var(--gray-300);
  border-bottom: 1px solid var(--gray-300);

  .table {
    tr:first-child {
      td {
        border-top: 0;
      }
    }
  }

  .theme {
    cursor: pointer;

    &.active {
      background-color: rgba(92, 184, 92, 0.2);
    }

    &:first-child {
      border-top: 0;
    }
    &.btn {
      padding: 0;
      white-space: normal;
    }
    &:last-child {
      margin-bottom: 0;
    }

    &__info {
      flex: 6;
      text-align: left;
      line-height: 1;
      padding: 0.15rem 0.25rem;

      &-name {
        font-size: 0.8em;
        color: $gray;
      }
      &-text {
        margin-left: 20px;
      }
    }

    &__distance {
      flex: 1;
      text-align: center;
      padding: 0.25rem 0.5rem;
      font-size: 1.2em;
      white-space: nowrap;
    }
  }
}

.nearest-class-selector {
  padding: 0.5rem 1rem;

  .theme-selector-container {
    padding-left: 0;
    padding-right: 0;
  }
}

.modal {
  .class-chooser {
    height: 60vh;
  }
}

.class-chooser {
  display: flex;
  flex-direction: row;
  height: 86vh;
  &__finder {
    flex: 3;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    border-right: 1px solid var(--gray-300);

    .finder {
      &__search-form {
        flex: 0 0 auto;
      }
      &__result-tree {
        flex: 1 0 auto;

        ul {
          &::-webkit-scrollbar {
            position: absolute;
            width: 10px;
            margin-left: -10px;
            -webkit-appearance: none;
          }

          &::-webkit-scrollbar-track {
            background-color: #e4e5e6;
            border-right: 0 none;
            border-left: 1px solid var(--gray-300);
          }

          &::-webkit-scrollbar-thumb {
            height: 50px;
            background-color: #a0a1a2;
            background-clip: content-box;
            border-color: transparent;
            border-style: solid;
            border-width: 1px 2px;
          }
        }
      }
      &__add-btn {
        flex: 0 0 auto;
      }
    }
  }

  &__examples {
    flex: 5;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    overflow-y: auto;

    &-phrase {
      padding: 5px 0;
      line-height: 1;
      border-top: 1px solid $gray-lightest;

      &:first-child {
        border-top: none;
      }
    }
  }
}
