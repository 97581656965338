.entity-modal {
  &__header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  &__table {
    width: 100%;

    tbody,
    thead {
      td:first-of-type {
        padding-left: 11px;
        width: 15.875%;
      }
      td:last-of-type {
        width: 13.375%;
        text-align: center;
      }
    }
    thead {
      height: 50px;
      background-color: var(--gray-100);
    }
    tbody {
      td.withHover {
        cursor: pointer;
        &:hover {
          color: var(--primary);
        }
      }
      tr {
        height: 44px;
        border-bottom: 1px solid var(--gray-100);
        padding: 0 12px;
      }
    }
  }
}
