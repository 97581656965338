.DragArea {
  position: relative;
  height: 0;
  width: 100%;

  &__content {
    position: absolute;

    height: 10px;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__preview {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    height: 8px;
    width: 100%;
    background-color: var(--primary-500);
  }
}
