@mixin scrollbar() {
  --primaryRGB: 96, 95, 227;

  &::-webkit-scrollbar {
    min-width: 6px;
    width: 6px;
    padding-right: 6px;
    background: transparent;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    min-height: 60px;
    height: 60px;
    border-radius: 100px;
    background-color: rgba(var(--primaryRGB), 0.3);
    opacity: 0.5;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: rgba(var(--primaryRGB), 0.7);
    }
  }

  &::-webkit-scrollbar-button {
    display: block;
    height: 0;
  }
}
