.DemoBlocker{
  padding: 12px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  &__limitBanner{
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
  &__close{
    cursor: pointer;
    margin-left: auto;
  }
}

.warning{
  color: #906812;
  background-color: #FEF6E6;
}

.danger{
  color: #8D2A3C;
  background-color: #FDEAEE;
}

.success{
  color: #166842;
  background-color: #E6F6EF;
}
