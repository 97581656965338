@import '~@just-ai/just-ui/scss/basic/custom/functions';
@import '~@just-ai/just-ui/scss/basic/custom/variables';
@import '~bootstrap-scss/mixins/breakpoints';

.justui_spoiler-text {
  display: flex;
  align-items: flex-start;

  .justui_spoiler-text__angle-button {
    margin-top: 0.1rem;
    margin-right: 0.1rem;
  }

  .justui_spoiler-text__content-wrapper {
    display: block;
    flex: 1;
    line-height: 1.5em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }
}

.justui_spoiler-header-wrap {
  cursor: pointer;
  &.dark {
    .justui_spoiler-header {
      color: $primary;
    }
    .justui_spoiler-header-content {
      color: $secondary;
    }
    .spoiler-arrow svg {
      color: white;
    }
  }
}

.justui_spoiler-header {
  &.arrow-right {
    justify-content: space-between;
    .spoiler-arrow {
      order: 3;
    }
    svg {
      margin-right: 0;
      margin-left: 8px;
    }
    & + .justui_spoiler-header-content {
      padding-left: 0;
    }
  }

  .spoiler-arrow {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;

    svg {
      font-size: 1.2rem;
      transition: all 0.25s;
      color: #4d4d4d;
      &.spoiler-open {
        transform: rotate(180deg);
      }
    }
  }
}

.justui_spoiler-header-content {
  padding-left: 24px;
  margin-top: 4px;
}

.justui_spoiler-collapse.dark {
  color: $secondary;
}
