.GlobalLLmAssistantWidget {
}

.GlobalLLmAssistantWidgetButton {
  cursor: pointer;
  position: relative;

  &__isLoading {
    animation: opacityPulse 1s linear infinite;
  }

  &__unreadMessages {
    position: absolute;
    top: 3px;
    right: 7px;
    width: 10px;
    height: 10px;

    border-radius: 50%;
    background: var(--red-500);
    border: 2px solid white;
  }
}

@keyframes opacityPulse {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.dragEdge {
  z-index: 3001;

  &::after {
    content: '';
    background-color: transparent;
    pointer-events: none;
    transition: 300ms;
    border-radius: 2px;
  }

  &__horizontal {
    height: 8px;
    width: 60px;

    position: absolute;
    bottom: 2px;
    left: 50%;
    transform: translateX(-50%);
    cursor: ns-resize;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &::after {
      height: 4px;
      width: 32px;
    }
  }

  &__vertical {
    height: 60px;
    width: 8px;

    position: absolute;
    top: 50%;
    left: 2px;
    transform: translateY(-50%);
    cursor: ew-resize;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &::after {
      height: 32px;
      width: 4px;
    }
  }

  &:hover {
    &::after {
      background-color: rgba(0, 0, 0, 0.5);
      pointer-events: all;
    }
  }
}
