@import "~@just-ai/just-ui/scss/basic/custom/functions";
@import "~@just-ai/just-ui/scss/basic/custom/variables";

.notification-container {
  position: fixed;
  right: 0;
  top: 83px;
  padding: 24px 0 0;
  z-index: 999;
  width: 408px;
  max-height: 81%;

  .notification {
    width: 384px;
    margin-bottom: 12px;
    position: relative;
    background: var(--white);
    box-shadow: 0 0 1px rgba(17, 60, 92, 0.06),
                0 14px 28px rgba(12, 43, 66, 0.12),
                0 0 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    overflow: hidden;
    &.dark-theme {
      border: 1px solid black;
    }

    .notification-header {
      border-bottom: 1px solid #ededed;
      padding: 8px 40px 7px 12px;
      display: flex;
      align-items: center;

      .title {
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: var(--gray-950);
      }

      svg {
        font-size: 1.4285714em;
        margin-right: 8px;
      }

      &.no-title {
        padding: 0;
        border: none;

        svg {
          position: absolute;
          top: 12px;
          left: 12px;
        }
      }
      &.dark-theme:not(.no-title) {
        background-color: $dark-bg;
        border-bottom: 1px solid black;
        .title {
          color: var(--white);
        }
      }
    }

    .notification-body {
      padding: 12px;

      &.dark-theme {
        background-color: $dark-bg;
        color: white;
        .notification-message {
          color: white;
        }
      }

      .notification-message {
        white-space: pre-line;
        word-wrap: break-word;
        font-size: 14px;
        line-height: 21px;
        max-height: 63px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        color: #000000;
        padding-right: 20px;
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }

      .notification-buttons {
        display: flex;

        .btn {
          margin-right: 8px;
        }
      }

      &.no-title {
        padding-left: 44px;
        padding-right: 44px;
      }
    }

    & .notification-stack-count {
      position: absolute;
      right: 12px;
      bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 20px;
      height: 20px;
      padding: 0 6px;
      border-radius: 10px;
      background-color: var(--gray-600);
      color: var(--white);
    }

    .notification-close {
      position: absolute;
      right: 8px;
      top: 6px;
      cursor: pointer;
      line-height: 0;
      padding: 6px;
    }
  }

  @keyframes slideOutRight {
    from {
      transform: translateX(0);
    }

    to {
      visibility: hidden;
      transform: translateX(100%);
    }
  }

  @keyframes slideOutLeft {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0);
    }
  }

  @keyframes slideOutTop {
    from {
      transform: translateY(100%);
    }

    to {
      transform: translateY(0%);
    }
  }

  .slideOutRight {
    -webkit-animation-name: slideOutRight;
    animation-name: slideOutRight;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  .slideOutLeft {
    -webkit-animation-name: slideOutLeft;
    animation-name: slideOutLeft;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .slideOutTop {
    -webkit-animation-name: slideOutTop;
    animation-name: slideOutTop;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .notification-hidden {
    visibility: hidden;
  }
}

.hiddenInput {
  position: absolute;
  top: -10000px;
  left: -10000px;
  visibility: visible;
}

.notification-modal {
  .modal-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .notification-modal-text {
    word-break: break-word;
    white-space: pre-wrap;
  }
}

.toasts-container {
  bottom: 1.5rem;
  top: unset;
  left: unset;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  .notification {
    width: unset;
    max-width: 1100px;
    min-width: 350px;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    .notification-close {
      position: static;
      padding: 0;
    }
    .notification-body {
      padding: 0.5rem;
      margin-top: 1px;
      flex: 1;
      .notification-message {
        max-width: 700px;
        white-space: nowrap;
        display: block;
      }
    }
  }
}
