@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -34px -34px;
  }
}

.debugContainer {
  box-sizing: content-box;
  height: 24px;
  position: absolute;
  right: 8px;
  top: 0;
  border: 1px solid var(--gray-200);
  border-radius: 0 0 4px 4px;
  overflow: hidden;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  z-index: 1;
  user-select: none;
}

.debugControls {
  display: flex;
  flex-direction: row;
  z-index: 1;

  > div {
    display: flex;
    width: 24px;
    height: 100%;
    align-items: center;
    justify-content: center;
    border-right: 1px solid var(--gray-200);
    cursor: pointer;
    color: var(--gray-600);

    &:hover {
      background-color: var(--gray-200);
    }

    &.controlDisabled {
      cursor: not-allowed;
      color: var(--gray-300);

      &:hover {
        background-color: transparent;
      }
    }

    > span {
      align-items: center;
      justify-content: center;
    }

    &:last-child {
      color: var(--red-600);
      border-right: none;
    }
  }
}

.debugIsRunning {
  --line-color: #f1eff1;
  border-right: 1px solid var(--gray-200);
  width: 156px;
  height: 100%;

  .bg {
    display: block;
    height: 100%;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: linear-gradient(
        -45deg,
        transparent 0 6px,
        var(--line-color) 6px 12px,
        transparent 12px 18px,
        var(--line-color) 18px 24px,
        transparent 24px 30px,
        var(--line-color) 30px 36px,
        transparent 36px 42px,
        var(--line-color) 42px 48px,
        transparent 48px 52px,
        var(--line-color) 52px 58px,
        transparent 58px,
        transparent
      );
      z-index: 1;
      background-size: 34px 34px;
      animation: move 2s linear infinite;
      overflow: hidden;
    }
  }

  .text {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 4px 8px;
    color: var(--gray-800, #333c45);

    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    letter-spacing: 0.01em;
  }
}
