$handle-width: 10px;

.justui_resizable-area {
  position: relative;

  .justui_drag-handle {
    background-color: transparent;
    position: absolute;
    z-index: 3001; //test widget z-index + 1
  }

  .justui_drag-handle-left {
    cursor: col-resize;
    width: $handle-width;
    height: 100%;
    top: 0;
    left: calc(-#{$handle-width} / 2);
  }

  .justui_drag-handle-right {
    cursor: col-resize;
    width: $handle-width;
    height: 100%;
    top: 0;
    right: calc(-#{$handle-width} / 2);
  }

  .justui_drag-handle-top {
    cursor: row-resize;
    height: $handle-width;
    width: 100%;
    left: 0;
    top: calc(-#{$handle-width} / 2);
  }

  .justui_drag-handle-bottom {
    cursor: row-resize;
    height: $handle-width;
    width: 100%;
    left: 0;
    bottom: calc(-#{$handle-width} / 2);
  }
}
