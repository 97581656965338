.User_container {
  .justui_dropdown .dropdown-item {
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
    &:focus {
      background-color: var(--primary);
    }
  }

  button.dropdown-item {
    color: var(--gray-800);
    outline: none;

    &:focus {
      outline: none;
      background-color: var(--primary);
      color: var(--white);
      * {
        color: var(--white) !important;
      }
    }
  }
}
