.ChatwidgetErrorModalBody {
  background-color: #ecf0f1;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: flex-start;
  padding: 12px 36px 12px 12px;
  border-radius: 4px;
  position: relative;
  span {
    word-break: break-word;
  }
  button {
    position: absolute;
    top: 3px;
    right: 0;
  }
}
