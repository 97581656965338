.IntegrationsList {
  font-size: 14px;
  color: var(--grey-800);

  &__titleRow {
    .titleWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;

      & span.title {
        font-size: 14px;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      & .subTitle {
        font-size: 12px;
        color: var(--grey-600);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  &__updatedAtRow {
    font-size: 12px;
    color: var(--grey-600);
  }

  &__statusRow {
    font-size: 12px;
    color: var(--grey-600);
  }

  &__actionsRow {
    width: 1px;
    white-space: nowrap;
    padding: 12px;
  }

  &__linksRow {
    width: 1px;
    white-space: nowrap;
    padding: 12px;

    & .subTitle {
      font-size: 12px;
      color: var(--grey-600);
    }
  }

  &__optionsRow {
    width: 1px;
    white-space: nowrap;
    padding: 12px 8px;
  }

  & :global {
    .status-dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 5px;
    }

    .status-dot.green {
      background-color: var(--green-500);
    }

    .status-dot.red {
      background-color: var(--red-600);
    }

    .status-dot.gray {
      background-color: var(--grey-400);
    }
  }

  .link {
    display: flex;
    align-items: center;
    color: var(--indigo-600);
    gap: 6px;
    text-decoration: none;
  }
}
