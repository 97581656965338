.widget {
  padding: 0 0 0 0;
  height: 100%;
  flex-grow: 1;
  background: var(--white);
  overflow: auto;
  border-left-color: var(--gray-300);
  border-left-width: 1px;
  border-left-style: solid;
  display: flex;
  flex-direction: column;

  .curp {
    cursor: pointer;
  }

  .widget-header {
    display: flex;
    background: var(--gray-950);
    padding: 10px;
    color: var(--white);

    .textes {
      display: flex;
      flex-grow: 1;
    }

    .title {
      font-weight: bold;
      text-transform: uppercase;
    }

    .iconClose {
      padding-left: 10px;
      cursor: pointer;
      svg {
        vertical-align: middle;
      }
    }
  }

  .widget-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .training {
      background: rgb(245, 154, 35);
      padding: 10px 15px;
      color: var(--white);
      font-size: 14px;
      display: flex;
      align-items: center;
    }

    .error {
      background: var(--white);
      padding: 10px 15px;
      color: var(--danger);
      font-size: 14px;
      display: flex;
      align-items: center;
      word-break: break-word;
      white-space: pre-line;
    }

    .flex-grow {
      flex-grow: 1;
    }
    .phrase {
      outline: none !important;
      box-shadow: none !important;
      padding: 24px 18px 0 16px;
    }

    .details {
      color: var(--gray-800);
      flex: 1;
      position: relative;
      height: 0;
      overflow: hidden;

      h5 {
        font-weight: 700;
        font-style: normal;
        font-size: 16px;
        color: var(--gray-950);
        line-height: 24px;
        margin-top: 20px;
      }

      .spelled-container,
      .widget-entity {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 0 15px;
      }

      .widget-intents {
        h5 {
          margin-left: 15px;
          margin-bottom: 0.25rem;
        }
        .intent-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          padding: 0.5rem 1rem;

          .justui__icon-wrapper {
            display: flex;
          }

          &.disabled {
            background-color: var(--gray-100);
            .percentage {
              opacity: 0.52;
            }
          }

          .percentage {
            min-width: 40px;
            text-align: center;
            font-style: normal;
            font-weight: 700;
            background: var(--gray-200);
            color: var(--gray-800);
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.01em;

            padding-block: 8px;
          }

          .intent-details {
            flex: 1;

            overflow: hidden;
            text-overflow: ellipsis;

            .intent-class {
              display: block;
              width: 100%;
              font-size: 12px;
              line-height: 16px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .name {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          button {
            padding: 0.25rem;
            &.disabled {
              svg {
                opacity: 1;
                color: var(--gray-300) !important;
              }
            }
            border: 1px solid var(--gray-300);
            svg {
              display: flex;
            }
          }
        }
      }

      .slotsKey {
        svg {
          color: var(--gray-950);
        }
      }
      .slotsDrop {
        font-size: 12px;
        background-color: var(--gray-100);
        padding: 8px;
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;

        &.hidden {
          display: none;
        }
        .row {
          div:first-child {
            word-break: break-all;
          }
          div:last-child {
            font-weight: bold;
            word-break: break-all;
          }
        }
      }

      .entity {
        font-size: 12px;

        border-radius: 4px;
        border: 1px solid var(--gray-200);

        padding: 16px;

        &-text {
          color: var(--gray-600);
        }
      }
    }
  }

  .text-muted {
    color: var(--gray-600);
  }

  .pseudo-link {
    font-size: 12px;
    font-style: normal;
    color: var(--primary);
    line-height: 16px;
    span {
      cursor: pointer;
    }
  }

  .center-spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
  }
  .center-text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
  }

  .pr5 {
    padding-right: 5px;
  }
  .pl0 {
    padding-left: 0;
  }

  &__rules-modal {
    margin-bottom: 18px;
  }
}

.alignment-container {
  //overflow-x: scroll;
  height: 390px;
  .words {
    display: inline-flex;
  }
  .percentage {
    display: inline-flex;
    justify-content: center;
    white-space: nowrap;
    padding: 10px;
  }
  .word {
    cursor: pointer;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .sentence1 {
    padding-bottom: 100px;
  }
  .sentence2 {
    padding-top: 100px;
  }
  .sentence1,
  .sentence2 {
    min-width: fit-content;
    display: flex;
    justify-content: space-between;
  }

  .alignment-pane {
    position: relative;
    z-index: 1;
  }
  .alignment-canvas {
    position: absolute;
    top: 35px;
    z-index: 0;
  }

  .popup {
    position: fixed;
    width: 400px;
    padding: 12px;
    height: 170px;

    &.full {
      height: 280px;
    }

    .alignment-table {
      display: flex;
      .name {
        display: flex;
        width: 50%;
        justify-content: flex-end;
      }
      .value {
        display: flex;
        flex-grow: 1;
        justify-content: flex-start;
        padding-left: 4px;
      }
    }
  }
}

.spelled-container {
  .title {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
  }
  .description {
    font-size: 12px;
    color: var(--gray);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    .icon {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      background-color: var(--gray);
      cursor: pointer;
      svg {
        color: var(--white);
        font-size: 10px;
      }
      &__enabled {
        background-color: var(--green);
      }
    }
  }
  &__error {
    color: var(--danger);
    word-break: break-word;
  }
  .spelled-sentence {
    display: block;
    padding: 4px 8px;
    border-radius: 4px;

    background: var(--gray-100);
    color: var(--gray-800);

    .correction {
      position: relative;
      color: var(--gray-950);
      border-bottom: 1px dashed var(--danger);
      cursor: pointer;
      margin: 0 3px;
      &:hover,
      &.selected {
        background-color: var(--danger);
        border-radius: 2px;
        color: var(--white);
        margin: 0;
        padding: 3px;
      }
    }
  }
}

.spelling-popover {
  .justui_popover-body {
    padding: 8px 16px;
  }
  &__item {
    color: var(--gray-950);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 16px;
    width: calc(100% + 32px);
    margin-left: -16px;
    .svg {
      font-size: 12px;
    }
    .delete {
      display: none;
      color: var(--gray);
    }
    &:hover {
      cursor: pointer;
      background-color: var(--yellow-100);
      .delete {
        display: block;
      }
    }
    &.disabled {
      color: var(--gray);
      &:hover {
        cursor: unset;
        background-color: unset;
      }
    }
    &.dictionary {
      color: var(--gray);
      &:hover {
        cursor: unset;
        background-color: unset;
      }
    }
    &.active {
      background-color: var(--yellow-100);
    }
  }
  &__separator {
    height: 1px;
    background: var(--gray-100);
    width: calc(100% + 32px);
    margin: 8px 0 8px -16px;
  }
}

.spelling-enable-popover {
  .popover {
    left: 4px !important;
  }
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    svg {
      margin-left: 8px;
    }
  }
}

.grey-panel {
  border-radius: 4px;
  background-color: var(--grey-100);
  padding: 12px;
  cursor: default;
  * {
    cursor: default;
  }
  p,
  b {
    margin-bottom: 0;
  }
}
