.cloud-menu-wrap {
  padding-right: 8px;
  list-style: none;
  .justui_dropdown {
    .dropdown-menu.show {
      right: 0;
      left: auto !important;
      transform: translate3d(0, 40px, 0px) !important;
      padding-bottom: 0.5rem;
    }
    & > button {
      background: transparent;
      padding: 0;
      border: none;
      border-radius: 50%;
    }
    .dropdown-item {
      &:active {
        a {
          color: #fff;
        }
      }
      a {
        display: flex;
        align-items: center;
        padding: 8px 20px;
        text-decoration: none;
        color: var(--gray-800);

        svg {
          height: 24px;
          margin-right: 8px;
          width: auto !important;
          flex-shrink: 0;
        }
      }
    }
    .product-dropdown {
      .dropdown-item {
        padding: 0;
      }
    }
  }
}
