@import 'src/scss/scrollbar';
.JGraph-RightSideMenu {
  width: 460px;
  height: 100%;
  display: flex;
  background: #fff;
  flex-direction: column;
  box-shadow: 0 0 3px rgba(black, 0.3);
  position: relative;

  .justui__icon-wrapper {
    display: flex;
  }

  &-footer {
    background: #f4f5f5;
    padding: 16px 32px;
    display: flex;
    gap: 8px;
  }

  &-body {
    flex-grow: 1;
    overflow: auto;
    @include scrollbar();

    &-content {
      height: 100%;
    }

    p {
      margin-bottom: 0;
      & + small {
        margin-top: 8px;
      }
    }
    .FileUploadContainer {
      background-color: var(--gray-100, #f4f5f5);
    }
  }

  &-header {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 12px 32px;
    border-bottom: 1px solid #cfd8dc;

    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 38px;

      h3 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &-dropdownWrapper {
      display: flex;
      flex-direction: row;

      button {
        outline: none;
      }
    }
  }
  &-stateSettings {
    &-group {
      padding: 20px 24px;
      border-bottom: 1px solid #cfd8dc;
    }
  }
  &-settings {
    padding: 20px 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &-group {
      .setTransitionInState {
        margin-bottom: 8px;
      }
    }

    .inline-radio {
      display: flex;
      flex-direction: row;
      gap: 24px;

      .justui_input-radioButton-container .justui_input-radioButton-label {
        left: unset;
        padding-left: 24px;
      }
    }
  }
  &-commonContainer {
    padding: 16px 32px;
    /*input[type=text],textarea{
      width: 100%;
    }*/
    label:not(.custom-control-label) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &-commonContainer + &-commonContainer {
    border-top: 1px solid var(--gray-200, #e0e1e3);
  }

  .ButtonSettings-collapse-custom {
    .inner-content {
      padding: 20px 32px;
      border-bottom: 1px solid var(--gray-200, #e0e1e3);
      h3 {
        margin-bottom: 20px;
      }

      &.no-border {
        border-bottom: none;
      }
    }

    .divider {
      width: calc(100% - 32px);
      height: 1px;
      background-color: var(--gray-200, #e0e1e3);
      margin: 0 0 0 32px;
    }

    .ButtonSettings-reaction-tabs {
      margin: 24px 0;

      .btn-group {
        display: flex;
        flex-grow: 1;
      }
    }
  }
  .ButtonSettings-reaction-tab-link {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    input {
      flex-grow: 1;
    }

    + .Message {
      margin-top: 8px;
    }
  }

  .ButtonsSettings-addNewButton {
    padding: 20px 32px;
  }

  .image {
    position: relative;
    img {
      max-width: 100%;
      border-radius: 8px;
    }
    .cleanIcon {
      width: 20px;
      height: 20px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 6px;
      right: 6px;
      border: 1px solid var(--gray-100, #c3c6c9);
      border-radius: 50%;
      background: #ffffff;
      cursor: pointer;
    }
  }

  .divider {
    width: 90%;
    margin: 28px 0 28px 5%;
    height: 1px;
    background: var(--gray-200, #e0e1e3);
  }

  .ImageSettings-FileUploadContainer {
    margin-top: 28px;
    .FileUploadContainer {
      a {
        margin-bottom: 4px;
      }
    }
  }
  .ImageSettings-text-input {
    position: relative;
    margin-top: 28px;
    svg {
      position: absolute;
      top: 10px;
      left: 8px;
      color: var(--gray-500, #888e93);
    }
    input {
      padding-left: 32px;
    }
  }
}

.InlineButtons-full-width {
  .btn-group {
    display: flex;
    flex-grow: 1;
  }
}

.ScreenBlocksList-inner {
  padding: 0 32px 8px;
  border-bottom: 1px solid var(--gray-200, #e0e1e3);
  &.withoutAdding {
    > div:last-child {
      border-bottom: none;
    }
  }
}
.ScreenBlocksList-sortable-container {
  margin: 12px 0;

  display: flex;
  flex-direction: column;
  gap: 12px;
  .ScreenBlocksList-sortable-container {
    margin: 0;
  }
}

.ScreenBlocksList-fastAdding {
  margin: 16px 0 8px;

  &.empty {
    margin: 0;
  }
  &-text {
    color: var(--gray-600, #6a7178);
    margin-bottom: 12px !important;
  }
  &-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;

    button {
      flex: 1 1 0;
      width: 0;
      display: flex;
      gap: 6px;
      align-items: center;
      justify-content: center;
      border-style: dashed;
      border-color: var(--gray-300, #c3c6c9);
      border-radius: 4px;
      &:hover {
        background: rgba(0, 0, 0, 0.04);
      }
    }
  }
}

.justui_button {
  &.disabled {
    cursor: pointer;
    svg {
      opacity: 0.5;
      pointer-events: none;
    }
    pointer-events: none;
  }
}

.draggingHelper {
  z-index: 4;
  background-color: var(--white, #ffffff);
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.03);
}

.inlineFullWidth {
  margin-bottom: 20px;

  .btn-group {
    display: flex;
    flex-grow: 1;
  }
}

.TransitionItemContainer {
  display: flex;
  flex-direction: column;

  .TransitionItem {
    display: flex;
    border-bottom: 1px solid var(--gray-200, #e0e1e3);
    padding: 8px 12px;
    flex-direction: row;
    align-items: center;
    &:last-child {
      border-bottom-color: transparent;
    }
    &:hover {
      background-color: rgba(black, 0.04);

      .actions {
        visibility: visible;
      }
    }

    .params {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      gap: 4px;
      .fromOrTo {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.01em;
        color: var(--gray-600, #6a7178);
      }
      .value {
        display: flex;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.0075em;
        color: var(--gray-800, #333c45);
        flex-grow: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        align-items: center;
        gap: 8px;
      }
    }
    .actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      visibility: hidden;
    }
  }
}

.form-group-fix {
  position: relative;
  padding-bottom: 1.875rem;
  margin-bottom: 0 !important;
}

.thunderIconSelect {
  width: 16px !important;
  height: 16px !important;
  z-index: 551;

  &.absolute {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translate(0, -50%);
  }
}
.just-select-wrapper {
  &.isSystemIntent {
    input {
      padding-left: 36px;
    }
  }
}
