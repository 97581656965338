.parse-errors-panel {
  height: 100%;

  .justui_window__content {
    background-color: black;

    .errors-area-empty-caption {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      color: var(--gray-500);
    }

    .errors-item {
      margin: 5px;

      .errors-item__locator {
        .errors-item__dependency-flag {
          background-color: var(--danger);
          padding-left: 0.3rem;
          padding-right: 0.3rem;
          border-radius: 0.5rem;
          margin-right: 0.5rem;
        }
        margin-bottom: 0;
        font-weight: bold;
        cursor: pointer;
      }

      .errors-item__error {
        margin-left: 1rem;
      }
    }
  }
}
#errorsPanelBoundingPlace {
  height: 100%;
}
