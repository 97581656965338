.AuthorLabel {
  &__tooltip {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    height: 136px;
    padding: 16px;

    background: var(--white);
    border-radius: 4px;
    box-shadow: 0 0 1px 0 rgba(17, 60, 92, 0.06), 0 6px 12px 0 rgba(12, 43, 66, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.10);

    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.12px;
    color: var(--gray-600);
  }
}
