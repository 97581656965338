.log-panel {
  height: 100%;
  .justui_window__content {
    display: grid;
    grid-template-columns: min-content minmax(0, 1fr);
    grid-template-rows: min-content minmax(0, 1fr);
    grid-template-areas:
      'filter-toolbar filter-toolbar'
      'actions content';
  }

  .filter-toolbar {
    grid-area: filter-toolbar;
    padding-left: 9px;
    padding-right: 9px;
    padding-top: 5px;
    padding-bottom: 5px;

    &__select-logger {
      min-width: 150px;
      margin-right: 10px;
    }

    &__select-level {
      min-width: 150px;
    }
  }

  .actions {
    grid-area: actions;
  }

  .log-item-panel {
    transition-property: all;
    transition-duration: 0.3s;
    overflow: hidden;
  }

  .log-area-wrapper {
    grid-area: content;
    background-color: var(--gray-950);
    white-space: pre-wrap;
    .spoiler-arrow {
      margin-top: 2px;
      svg {
        transform: rotate(-90deg);
      }
      svg.spoiler-open {
        transform: none;
      }
    }

    .justui_spoiler-header {
      color: var(--green-300);
    }

    .justui_spoiler-header-content {
      margin-top: 0;
    }

    .justui_spoiler-header-wrap {
      margin-top: 2px;
    }
  }

  .log-header-content {
    font-size: 14px;
    line-height: 21px;
    max-height: 21px;
    text-overflow: ellipsis;
    white-space: pre-line;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
  }

  .log-area-empty-caption {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: var(--gray-500);
  }

  .log-item {
    margin-left: 1.5rem;
    margin-top: 0.5rem;
    &__line {
      display: flex;
      p {
        margin-bottom: 0.5rem;
      }
      &__heading {
        flex-basis: 100px;
      }
      &__value {
        flex: 1;
      }
    }
  }

  .time-dropdown {
    button {
      box-shadow: none !important;
    }
    &__menu {
      min-width: 250px;
      text-align: right;
    }
  }
}
