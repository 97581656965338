.floatingMenu {
  position: absolute;
  z-index: 3;
  display: flex;
  align-items: center;
  min-width: 256px;
  background-color: var(--white, #fff);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 6px 12px rgba(12, 43, 66, 0.16), 0 0 1px rgba(17, 60, 92, 0.06);
  border-radius: 4px;
  padding: 4px 8px;
  gap: 8px;

  .deleteBtn {
  }

  &__dropdown {
    background: transparent !important;
    background-color: transparent !important;
    border: none !important;
    padding: 0 0 8px !important;
    left: 0 !important;
  }

  &__toggleDetailView {
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    padding: 4px;
    background-color: var(--white, #fff);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 6px 12px rgba(12, 43, 66, 0.16), 0 0 1px rgba(17, 60, 92, 0.06);
    border-radius: 8px;
    gap: 4px;
  }
}

.input {
  height: 30px !important;
}

.selectLabelColor {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  color: var(--gray-600) !important;

  &_fillBlue {
    color: var(--primary-600) !important;
    &_active,
    &:hover {
      background: var(--primary-100) !important;
    }
  }
}

.Separator {
  width: 1px;
  height: 40px;
  background-color: var(--gray-200);
}
