.dialogs-filter {
  &.old {
    max-width: 780px;
  }
  max-width: 1000px;
  margin: 0 auto;

  .form-group {
    margin-bottom: 0.4rem;
    align-items: center;
  }

  .form-inline .form-group {
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  .row {
    align-items: center;
  }
}

.dialog-detail {
  position: relative;

  &--audio {
    margin-bottom: 1rem;
  }

  .dialog-pagination-control {
    position: fixed;
    font-size: 2em;
    height: 91vh;
    padding: 0;
    width: 12px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &.prev {
      left: 258px;
      top: 83px;
    }

    &.next {
      left: 100%;
      top: 83px;
      margin-left: -29px;
      z-index: 5;
    }
  }

  .asr-audio {
    padding-right: 10%;
  }

  &--info {
    margin-bottom: 0.3rem;

    &-pattern,
    &-class {
      word-break: break-all;
      max-height: 1.5em;
      overflow: hidden;
    }

    &-time {
      &-processing {
        margin-left: 20px;
      }
    }
  }

  &--baseinfo {
    margin-bottom: 0;

    &-name {
      max-width: 200px;
    }

    &-session {
      max-width: 200px;
    }

    &-channel {
      max-width: 200px;
    }

    &-starttime-duration {
      width: 100px;
    }

    &-duration {
      width: 100px;
    }

    th,
    td {
      padding: 0.5rem;
      white-space: nowrap;
      text-align: center;

      &.align-left {
        text-align: left;
      }

      &.align-right {
        text-align: right;
      }

      i {
        vertical-align: middle;
      }
    }
  }
}

.sidebar-min {
  .dialog-pagination-control {
    &.prev {
      left: 54px !important;
    }
  }
}

.dialog-labels-navigation {
  padding: calc(0.6rem - 4px);
}

.dialog-phrase-item {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0.6rem;

  &.selected {
    background: rgba($green, 0.2);
  }

  .phrases-messages {
    width: 100%;

    &--qa {
      padding: 1.25rem;
      margin-bottom: 0.6rem;

      &-container {
        display: flex;
        align-items: center;
      }

      &-head {
        margin-left: 2rem;
        display: flex;
        cursor: pointer;
        width: 7.5rem;
        &-question {
          align-items: center;
        }
        &-without-icon {
          padding-left: 2.5rem;
          cursor: default;
        }
      }

      &-icon {
        margin-right: 1rem;
      }

      &-bottom-text {
        padding-top: 0.5rem;
      }

      .qa-block {
        width: calc(100% - 150px - 2rem);
        border: solid var(--gray-200);
        border-width: 0;
        padding: 0.75rem 1rem 0 1rem;
        margin: 1px 1px 0 1px;
        &--opened-top {
          margin: 0;
          border-width: 1px 1px 0 1px;
          border-radius: 3px 3px 0 0;
        }
        &--opened-bottom {
          margin: 0;
          border-width: 0 1px 1px 1px;
          margin-left: calc(7.5rem + 2rem);
          background-color: var(--gray-200);
          border-radius: 0 0 3px 3px;
          padding-bottom: 0.75rem;
        }
      }

      &-item {
        margin-top: 0.6rem;

        &:first-child {
          margin-top: 0;
        }
      }

      &-question {
        margin: 0.6rem 0 0 -0.9375rem;
        display: flex;
        flex-flow: column nowrap;
        &:first-child {
          margin-top: 0;
        }
      }

      .qa-field {
        margin-bottom: 12px;
        + time,
        time {
          font-size: 0.7rem;
          color: var(--gray-200);
          cursor: default;
          margin-bottom: 10px;

          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          &:before {
            content: '\f017';
            color: var(--gray-200);
            font-family: FontAwesome, sans-serif;
            display: inline-block;
            margin-right: 4px;
          }
        }
      }

      .qa-question {
        word-break: break-all;

        &--not-recognized {
          background-color: var(--yellow-100);
          padding: 0.25rem;
        }
      }

      .qa-answer {
        word-break: break-all;
        padding: 0 30px 0 15px;

        &.qa-answer-operator {
          font-weight: bold;
        }

        & > div {
          padding: 0.3rem 0;
        }
      }

      img {
        max-height: 100px;
      }
    }
  }

  .phrases-marks {
    .mark-group {
      margin-bottom: 0.6rem;

      .mark-buttons {
        margin-left: -4px;
        margin-right: -4px;
      }
    }
  }

  &--modal-view {
    flex-direction: row;

    .phrases-messages {
      width: 100%;
      padding-right: 0.3rem;
    }

    .phrases-options {
      padding-left: 0.3rem;
    }
  }

  &--page-view {
    .phrases-options {
      margin: 0 -0.6rem -0.6rem;
      padding: 0.6rem;
      border-top: 1px solid $gray-lighter;

      .qa-task {
        width: auto;
      }

      .markup-actions {
        margin-left: -8px;
        margin-right: -8px;
      }
    }
  }
}

.dialogs-content {
  margin: 0 -30px;

  & > .col-12 {
    padding: 0;
  }
}

.dialogs-filter-panel {
  position: sticky;
  top: 0;
  z-index: 2;
}

.dialogs-filter {
  .ant-select {
    width: 100%;
  }

  .collapsible-content {
    border: none;
    margin-bottom: 1rem;
  }

  .collapsible-content-headline {
    padding: 0;
    background-color: transparent;
  }
  .collapsible-content-body {
    padding: 10px 0;
  }
}

.tab-pane {
  .dialogs-list,
  .phrases-list {
    margin: 0 -1rem;
  }

  .dialogs-actions,
  .phrases-actions {
    margin: 0;
  }

  .dialogs-table,
  .phrases-table {
    margin: 15px 0;
    border-bottom: 1px solid var(--gray-300);
  }
}

.phrases-list {
  &__phrase {
    word-break: break-all;
  }
  .markup {
    width: 300px;
  }
}

.dialog-labels-navigation {
}

.responseDataJsonPath {
  &_add-action {
    font-size: 12px;
    line-height: 1;
    cursor: pointer;
    color: #605fe3 !important;
  }

  &_label {
    font-size: 12px;
    line-height: 1;
  }

  &_remove-action {
    cursor: pointer;
    display: inline-block;
    height: 36px;
    padding: 7px 0;
  }
}
