.iconPlace {
  display: flex;
  align-items: center;
  height: 16px;
  width: 16px;

  & .toggleIcon {
    color: var(--gray-600);
    padding: 4px 6px 4px 2px;
  }
}

.text {
  display: inline-flex;
  align-items: center;
  gap: 2px;

  white-space: nowrap;
}
