@import "~@just-ai/just-ui/scss/basic/custom/functions";
@import "~@just-ai/just-ui/scss/basic/custom/variables";

// TODO: change colors $black-medium, #F2F2F2, #99d5eb

.phone-container {
  position: relative;
  z-index: 1;
}

.phone-wrapper {
  border-radius: 3px;
  border: 1px solid $greyblue;
  min-height: 36px;

  .phone-input {
    width: 100%;
    height: 34px;
    padding: 8px 12px 8px 8rem;

    border: none;
    outline: none;
    box-shadow: none;
    letter-spacing: 0.02em;
    border-radius: 3px;
  }

  &.disabled{
    cursor: not-allowed;
    input{
      cursor: not-allowed;
    }
    .just-select-container-open{
      cursor: not-allowed;
    }
  }

  &.focused{
    border-color: #2EA9D6;

    @if $enable-shadows {
      box-shadow: $input-box-shadow, $input-focus-box-shadow;
    } @else {
      box-shadow: $input-focus-box-shadow;
    }
  }

  &.error {
    border-color: $danger;
  }
}

.phone-nav {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0;
  margin: 0;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;

  border: none;
  background-color: initial;
}

.phone-nav:focus {
  border-color: #99d5eb;
  outline: 0;
  border-radius: 3px;
}

.phone-nav:disabled {
  cursor: not-allowed;
}

.phone-nav__flag,
.country__flag {
  width: 27px;
  height: 19px;

  border-radius: 3px;
  border: 1px solid #F2F2F2;
}

.phone-nav__flag span,
.country__flag span {
  width: 100%;
  height: 100%;
  line-height: 1.5;
  background-size: cover;
  border-radius: 2px;
}

.phone-nav__btn {
  margin: 0 14px;
  display: flex;
  align-items: center;
  height: 19px;
}

.phone-nav__code-number {
  color: $gray-600;
  line-height: 1;
}

.phone-nav__btn .svg-inline--fa:not(.btn-icon) {
  font-size: inherit;
}

input.phone-input::-webkit-inner-spin-button {
  display: none;
}

.country-list {
  overflow-Y: auto;
  max-height: 222px;
}

.country-list-menu {
  font-size: 14px;
  width: 100%;

  overflow: hidden;
  max-height: 222px;
}

.country-list-search {
  position: relative;
  border-bottom: 1px solid #CCCCCC;

  .country-list-search__input {
    border-radius: 0;
    border: none;
    width: 100%;
    height: 100%;
    padding-right: 12px;
    padding-left: 12px;
    padding-bottom: 8px;
  }

  .country-list-search__input:focus {
    outline: 0;
  }

  .country-list-search__input::placeholder {
    font-size: 14px;
  }
}

.country-list-search__icon {
  position: absolute;
  top: 0;
  right: 12px;
}

.country-list-search__icon svg {
  color: $gray-400;
}

.country-list .justui_dropdown-item {
  padding: 8px 12px;
}

.country-list .justui_dropdown-item:focus {
  border-color: #99d5eb;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(46, 169, 214, 0.25);
  border-radius: 3px;
}

.country-list .justui_dropdown-item:hover {
  background-color: $primary;
}

.country {
  display: flex;
  align-items: center;

  font-size: 14px;
}

.country__flag {
  flex-shrink: 0;
}

.country__name {
  margin-left: 8px;
  white-space: pre-wrap;
}

.country__code {
  margin-left: auto;
  color: $secondary;
}

div .dropdown-menu {
  opacity: 1 !important;
  pointer-events: auto !important;
}
