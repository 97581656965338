.selectLabelColor {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  color: var(--gray-600) !important;

  &_fillBlue {
    color: var(--primary-600) !important;
    &_active,
    &:hover {
      background: var(--primary-100) !important;
    }
  }
}
