.partner-table {
  margin-bottom: 0;

  td {
    border: 0 none;

    &:first-child {
      padding-left: 0;
    }
  }

  td,
  th {
    padding: 0.1rem 0.5rem;
  }
}

.referrals-table {
  margin-bottom: 0;

  td {
    border: 0 none;
    vertical-align: middle;
    padding: 0 12px;

    &:first-child {
      padding-left: 0;
    }
  }

  td,
  th {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  }
}

.partner-modal {
  .d-flex,
  .row {
    margin-left: 0;
    margin-right: 0;
    white-space: pre-wrap;

    *[class*='col'] {
      &:nth-child(1) {
        padding-left: 0;
      }
      &:last-of-type {
        padding-right: 0;
      }
    }
  }

  .inline-radio {
    display: flex;
    .justui_input-radioButton-container {
      margin-right: 39px;
    }
  }
}
