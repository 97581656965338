.ace_autocomplete {
  .ace_text-layer {
    width: calc(100% - 8px);
    .ace_line {
      display: flex;

      .ace_ {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .ace_completion-meta {
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 150px;
        flex-shrink: 0;
        margin-left: auto;
      }
    }
  }
}

.ace_editor.ace_autocomplete {
  width: 600px;
}
