.tts-widget {
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;

  font-size: 12px;
  line-height: 18px;

  &__block {
    margin-bottom: 16px;

    &_mini-margin {
      margin-bottom: 16px;
    }

    &_header {
      display: flex;
      align-items: center;
    }

    &_sample {
      background-color: #f8f9fc;
      border-radius: 4px;
      margin-top: 24px;

      .justui-audio-player__custom-button svg {
        width: 12px !important;
        height: 12px !important;
      }
    }

    &_error {
      color: var(--red);
      margin-top: 12px;
    }
  }

  &__text-for-synthesis {
    textarea {
      padding: 5px 12px;
      font-size: 12px;
      &::placeholder {
        color: var(--gray-500);
      }
    }
  }

  &__title {
    color: var(--gray-800);
    margin-bottom: 8px;
    display: block;
    font-size: 12px;

    &_text-synthesized {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__close {
    margin-left: auto;
  }

  &__provider {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    > * {
      flex-basis: 50%;
    }

    &_vendor {
      margin-right: 8px;
      z-index: auto;
    }
  }

  &__ssml_hint {
    font-size: 12px;
    line-height: 18px;
    color: var(--gray-800);

    & a {
      text-decoration: none!important;
      font-weight: 400!important;
    }
  }

  &__btn {
    &_synthesis {
      width: 35%;
    }
    &_copy {
      font-weight: 700!important;
      svg {
        font-weight: 700!important;
      }
    }
  }

  &__banner {
    margin-top: 8px;
  }

  &__banner-info {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: var(--gray-800);
  }
}
