.Message_pattern_class {
  .primaryColorIconMessageStatus {
    color: var(--primary)
  }
  .grayColorIconMessageStatus {
    color: var(--gray-600)
  }
  .primaryBackgroundMessageStatus {
    background-color: var(--primary-200);
    color: var(--primary-800);
    border-radius: .15rem;
  }
  .grayBackgroundMessageStatus {
    background-color: var(--gray-200);
    color: var(--gray-800);
    border-radius: .15rem;
  }
  .errorColorIconMessageStatus {
    color: var(--red-600)
  }
  .errorBackgroundMessageStatus {
    background-color: var(--red-200);
    color: var(--red-800);
    border-radius: .15rem;
  }
};
