.thead-inverse {
  th {
    color: $table-inverse-color;
    background-color: $table-inverse-bg;
  }
}

.thead-default {
  th {
    color: $table-head-color;
    background-color: $table-head-bg;
  }
}

thead.thead-transparent {
  th {
    color: $table-head-color;
    border-top: 0;
    background-color: transparent;
    text-transform: uppercase;
    font-size: 0.725rem;
    border-bottom: 0;
  }
}

tbody {
  &.tbody-default {
    td,
    th {
      background: var(--white);
      &:first-child {
        border-left: 1px solid $table-border-color;
      }
      &:last-child {
        border-right: 1px solid $table-border-color;
      }
    }

    tr:last-of-type {
      td {
        border-bottom: 1px solid $table-border-color;
      }
    }
  }
}

.table-inverse {
  color: $table-inverse-color;
  background-color: $table-inverse-bg;

  th,
  td,
  thead th {
    border-color: $table-inverse-border;
  }

  &.table-bordered {
    border: 0;
  }

  &.table-striped {
    tbody tr:nth-of-type(odd) {
      background-color: $table-inverse-bg-accent;
    }
  }

  &.table-hover {
    tbody tr {
      @include hover {
        background-color: $table-inverse-bg-hover;
      }
    }
  }
}
