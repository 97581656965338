.faqTemplatesTabs {
  display: flex;
  background-color: var(--gray-200);

  &__tabs {
    display: inline-flex;
    margin-bottom: 0 !important;
  }

  &__actionBtn {
    display: inline-flex;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;
    color: var(--primary);

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
    &--disabled {
      cursor: default;
      opacity: 0.3;
      &:hover {
        cursor: default;
        opacity: 0.3;
      }
    }
  }

  &__applyBtn {
    display: flex;
    margin: 5px 1.5rem 5px auto;
    z-index: 10;
    :global(.justui_dropdown-menu.dropdown-menu.show) {
      left: -18px !important;
    }
    > div > button {
      padding: 4px 12px;
      min-width: 180px;
      p {
        margin-bottom: 0;
        margin-right: auto;
        font-size: 12px;
      }
    }
  }
}
