.faqTree {
  display: flex;
  flex-direction: column;
  background-color: var(--gray-800);
  color: var(--grey-200);
  height: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    button {
      &:focus {
        box-shadow: none !important;
      }
    }
  }
  &__tooltip {
    :global(.tooltip) {
      pointer-events: none;
      top: -4px !important;
    }
  }

  &__content {
    flex: 1 1 auto;
    min-height: 0;
  }
  &__deleteModalContent {
    white-space: pre-wrap;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
