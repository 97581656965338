.SavedTooltip {
  align-items: center;
  justify-content: center;
  width: 340px;
  height: 212px;
  padding: 32px 24px;
  text-align: center;
  gap: 8px;

  &__title {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  &__description {
    font-size: 12px;
    color: var(--gray-600);
    line-height: 16px;
  }
}
