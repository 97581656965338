.intentFaqEditView {
  .custom-control-label {
    color: var(--grey-600);
  }

  label::placeholder {
    color: var(--grey-500);
  }
  .collapse.show {
    .input-group {
      .btn {
        display: flex;
      }
      .justui__icon-wrapper {
        display: flex;
      }
      .form-control {
        z-index: 0;
      }
    }
  }
}

.answers-textarea.textAreaLikeElement.form-control {
  border: none;
  height: auto;
  display: grid;
  line-height: 22px;
  background-color: transparent;
  padding-left: 4px;
  &:hover {
    border: none;
  }
  &:focus {
    border: none;
  }
}

.faq-answer-container {
  border-radius: 8px;
  background-color: var(--grey-100);
  border: 1px solid var(--grey-200);
  min-height: 128px;
  padding-bottom: 32px;
  &.error {
    .faq-answer__part-container:not(.faq-answer__part-container--error) {
      .textAreaLikeElement.form-control  {
        color: var(--grey-500);
      }
    }
  }
}

.faq-answers__saving-indicator {
  display: flex;
  gap: 8px;
  color: var(--grey-500);
  .justui_icon.fa-color-secondary {
    color: var(--grey-500);
  }
  .justui__icon-wrapper {
    margin: 0 !important;
    align-items: center;
  }
}

.faq-edit__question-input {
  .IconButtons-wrap.IconButtons-wrap-append {
    .justui_icon-button.justui_button.btn-flat {
      background-color: transparent;
      pointer-events: none;
    }
  }
}
