.activate-page {
  margin: 0 auto !important;
  padding: 30px 45px;

  &.activate-custom-width {
    width: 640px;
  }
  h5 {
    border-bottom: 1px solid var(--gray-200);
  }
  .card {
    font-size: 0;
    display: block;
  }
  label {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 150px);
  }
  button {
    display: inline-block;
    vertical-align: top;
    width: 140px;
    margin-left: 10px;
  }
  .form-spinner {
    font-size: 12px;
    left: 0;
  }
  .error {
    font-size: 14px;
    color: #f63c3a;
  }
}
.tariffInfo-page {
  &.tariffInfo-custom-width {
    width: 950px;
    max-width: 950px;
  }
  .border-grey {
    border-bottom: 1px solid var(--gray-200);
  }
  .unique-users-info {
    i {
      margin-left: 10px;
    }
  }
  .keys-table td {
    white-space: nowrap;
  }
  #uniqueUsersTooltip {
    color: var(--primary) !important;
  }
  .add-key-tip {
    position: absolute;
    left: 100%;
    top: 0;
    width: 250px;
    line-height: 16px;
    max-width: calc((100vw - 980px) / 2);
    min-width: 150px;
  }
}
.add-key-modal {
  input,
  label {
    width: 100%;
  }
  button {
    margin-left: 5px;
  }
  .modal-body {
    padding-top: 15px;
  }
  .form-spinner {
    left: 0;
  }
  .error {
    font-size: 14px;
    color: #f63c3a;
  }
}

.tariff-alerts {
  i {
    font-size: 20px;
    top: 2px;
    position: relative;
  }
  span {
    padding-left: 0;
  }
  .alert-warning i {
    color: #f59a23;
  }
  .alert-info i {
    color: var(--primary);
  }
}
