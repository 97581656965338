.JGraphCailaIntentItemContainer{

  .JGraphCailaIntentItem{
    border: 1px solid var(--gray-200, #E0E1E3);
    cursor: pointer;
    align-items: center;

    .reactionIconContainerCustom{
      display: flex;
      min-width: 24px;
      padding-top: 0;

      justify-content: center;
      align-items: center;
      align-self: center;
    }

    .nameAndValueContainer{
      .value{
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 0.75rem;
        line-height: 1rem;
      }
    }
  }


}

