.similars-range {
  margin-top: 8px;
  background-color: var(--gray-50);
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  &__wrapper {
    min-width: 255px;
    display: flex;
    gap: 8px;
    align-items: center;
    text-align: center;
    p {
      margin: 0;
    }
  }
  input.form-control {
    max-width: 44px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group-append {
    padding: 3px 8px;
    text-align: center;
    background-color: var(--grey-100);
    border: 1px solid var(--grey-200);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.slider-root-styles {
  width: calc(100% - 18px);
  margin: auto;
  position: relative;
}
