.JGraphBreadcrumb {
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  padding: 0 8px;
  color: var(--gray-500);
  gap: 4px;

  & :global(.justui__icon-wrapper) {
    display: flex !important;
  }
}
