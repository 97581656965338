@import "~@just-ai/just-ui/scss/basic/custom/functions";
@import "~@just-ai/just-ui/scss/basic/custom/variables";

.justui_banner{
  position: relative;
  width: 100%;
  background: var(--blue-100);
  padding: 8px;
  text-align: center;
  font-size: 14px;
  line-height: 21px;
  color: var(--grey-950);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 46px;
  border-radius: 3px;

  &.with-close{
    padding-right: 40px;
  }

  &.danger{
    background: var(--red-100);
  }
  &.success{
    background: var(--green-100);
  }
  &.warning{
    background: var(--yellow-100);
  }

  .justui_banner-close{
    position: absolute;
    right: 8px;
    top: 8px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: right;
  }
  .justui_banner-icon-type{
    margin-right: 6px;
    margin-left: 2px;
    font-size: 1.25rem;
  }
  .justui_banner-text{
    margin-right: 8px;
    flex-grow: 1;
    text-align: left;

    a{
      font-weight: 500;
      text-decoration: underline;
    }
  }
  .justui_banner-buttons{
    display: flex;
    button{
      margin-left: 8px;
    }
  }

  /*&.dark{
    background: #1F282D;

    .justui_banner-close {
      svg{
        color: #ffffff;
      }
    }
    .justui_banner-text{
      color: #ffffff;
    }
  }*/
}
