.HierarchyContextMenu {
  z-index: 4;
  position: fixed;
  top: 0;
  left: 0;

  .dropdown {
    background: #ffffff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 6px 12px rgba(12, 43, 66, 0.16), 0 0 1px rgba(17, 60, 92, 0.06);
    border: none;
  }

  & .MenuItem {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 8px 12px;
    transition: background-color 0.2s ease-in-out;
    cursor: pointer;

    font-size: 14px;
    color: var(--gray-800);

    & svg {
      color: var(--gray-500);
    }
    &:hover {
      background-color: var(--gray-100);
    }

    &__text {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    &__icon {
      display: flex;
      align-items: center;
      width: 16px;
      height: 16px;
    }

    &__danger {
      color: var(--red-600);
      & svg {
        color: var(--red-500) !important;
      }
    }
  }
}
