.JGraphCailaIntents{
  .bodyOffset{
    margin: 0 -32px 0;
    padding: 24px 32px 16px;
    border-bottom: 1px solid var(--gray-200, #E0E1E3);
  }
  .noMatchElement{
    padding-top: 16px;
    p{
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.0075em;
      color: var(--gray-600, #6A7178);
      margin-bottom: 8px;
    }
  }
}
.collapseOffset{
  margin: -16px -32px 0;
}
