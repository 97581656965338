.header{
  font-weight: normal;
}
.collapseInner{
  background-color: var(--gray-100, #f4f5f5);
}
.collapseInnerBox{
  padding: 0 16px;
}
.customWidth{
  max-width: 259px !important;
  * {
    font-size: 12px !important;
  }
}
