.Projects {
  max-height: 292px;
  overflow: auto;

  button {
    display: block !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.DropdownMenu {
  padding: 8px 0 !important;
  width: 300px;
  box-shadow:
    0px 0px 1px 0px #113c5c0f,
    0px 6px 12px 0px #0c2b4229,
    0px 0px 2px 0px #0000001a !important;
}
.DropdownButton {
  button {
    display: flex;
  }
  button span {
    display: block;
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.Input {
  margin: 0 12px 8px 12px;
  width: auto !important;
}

.NoFound {
  margin-left: 20px;
  color: var(--gray-500);
  font-size: 14px;
}

.NoProject {
  color: var(--gray-300) !important;
}
