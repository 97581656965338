.build-general-info {
  margin-bottom: 20px;
}

.deploy-history {
  &-status {
    margin-right: 20px;
  }

  .card-header {
    .card-title {
      white-space: nowrap;

      .bot-label {
        white-space: normal;
        word-wrap: break-word;
      }
    }
  }
}

.build-list {
  list-style: outside none;
  padding-left: 0;
  display: table;
  width: 100%;

  &-item {
    display: table-row;

    &.active {
      background: lighten($brand-success, 40%);
    }

    &:hover {
      background-color: $table-row-hover;
    }

    & > div {
      display: table-cell;
      vertical-align: middle;
      padding: 5px 10px 10px 10px;
    }

    &-date {
      float: right;
      margin-left: 10px;
    }
  }
}
.code--style {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #bd4147;
  background-color: #eceff1;
}
