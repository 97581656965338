.EmptyListOfIntegrationsState {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  &__title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: var(--gray-600);
  }
}
