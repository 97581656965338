.CopyPastePermissionTipModal {
  color: var(--gray-800);

  .Content {
    display: flex;
    flex-flow: column nowrap;
    gap: 16px;
  }

  .Pointer {
    position: fixed;
    top: 10%;
    left: min(300px, 20%);
  }

  .BannerContent {
    display: flex;
    flex-flow: column nowrap;
    gap: 8px;

    & img {
      width: 100%;
    }
  }
}
