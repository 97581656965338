.OtherHudActions {
  color: var(--gray-800);
  border: none !important;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 6px 12px rgba(12, 43, 66, 0.16), 0 0 1px rgba(17, 60, 92, 0.06);

  a {
    text-decoration: none;
  }

  &_Item {
    color: var(--gray-800);
    padding: 8px 12px 8px 12px !important;
    display: flex !important;
    align-items: center;
    gap: 8px;

    & :global(.justui__icon-wrapper svg) {
      margin: 0 !important;
    }
  }

  :global(.dropdown-item) {
    &:active {
      * {
        color: var(--white);
      }
    }
  }
}
