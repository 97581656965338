.Hint {
  padding: 2px 8px;
  border-radius: 4px;
  background-color: var(--white);
  max-width: 250px;
  white-space: pre-line;
  word-break: break-word;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 14px 28px rgba(12, 43, 66, 0.12), 0 0 1px rgba(17, 60, 92, 0.06);
  z-index: 1;
  font-size: 12px;
  color: var(--gray-800);
}
