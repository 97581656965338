.Settings_container {
  .dropdown-item, a {
      color: var(--gray-800);
    padding: 0.5rem 1rem;
    a{
      width: 100%;
      height: 100%;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      color: var(--gray-800);
    }

    a:focus {
      background-color: var(--primary);
      outline: none;
      background-color: var(--primary);
      color: var(--white);

      * {
        color: var(--white) !important;
      }
    }
  }
}
