.EmptyWarningBanner {
  width: 100%;
  background: var(--yellow-300);
  color: var(--gray-800);
  min-height: 54px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
}
