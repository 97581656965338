@use 'sass:math';

.checkboxWithSmall {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;
  color: var(--gray-800, #333c45);

  small {
    color: var(--gray-600, #6a7178);
  }
}

.formGroupCustom {
  margin-bottom: 0 !important;
}
.formGroup {
  margin-bottom: #{math.div(20, 16)}rem !important;
}
.formGroupDisabled {
  label,
  .checkboxWithSmall,
  .checkboxWithSmall small {
    color: var(--gray-300, #c3c6c9);
  }
}

.forwardButton {
  margin-left: 8px;
}

.LabelComponentSelectState {
  display: flex;
  div:nth-child(1) {
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.LabelComponentSelectStateCreate {
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  letter-spacing: 0.02em;
  color: var(--gray-600, #6a7178);
}
