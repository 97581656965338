.Banner {
  display: flex;
  align-items: flex-start;
  padding: 11px 8px;
  border-radius: 4px;
  gap: 6px;
  color: var(--gray-800);

  .icon {
    margin: 2px;
    height: 24px !important;
    width: 24px !important;
    align-items: center;
    justify-content: center;
    display: flex;
    svg {
      height: 20px !important;
      width: 20px !important;
    }
  }

  &__default {
    & .icon svg {
      color: var(--gray-600);
    }
    background-color: var(--gray-100);
  }

  &__info {
    & .icon svg {
      color: var(--blue-600);
    }
    background-color: var(--blue-100);
  }

  &__danger {
    & .icon svg {
      color: var(--red-600);
    }
    background-color: var(--red-100);
  }

  &__warning {
    & .icon svg {
      color: var(--yellow-300);
    }
    background-color: var(--yellow-100);
  }

  &__success {
    & .icon svg {
      color: var(--green-600);
    }
    background-color: var(--green-100);
  }
}
