// Import Main styles for this application
@import './scss/style.scss';

body {
  background-color: var(--gray-100);
}

h2,
h1,
h3,
h4,
h5 {
  color: var(--gray-950);
}

.system-status-icon-success {
  background-color: var(--success);
}

.table {
  color: var(--gray-800);
}

//TODO delete when inputs in channels are removed
.custom-switch.custom-switch-md,
.custom-switch.custom-switch-sm {
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: var(--primary);
  }
}

//TODO delete when inputs in channels are removed
.switch-success > .switch-input:checked:not(:disabled) ~ .switch-label {
  background-color: var(--primary) !important;
}

//TODO delete when inputs in channels are removed
.switch-success > .switch-input:checked ~ .switch-handle {
  border-color: var(--primary) !important;
}

.form-control:not(.form-control-sm)::not(.form-control-lg) {
  height: calc(2.25rem + 2px);
  &.form-control-sm {
    height: calc(1.25rem + 0.5rem + 2px);
  }
}

.just-ui-custom-scrollbar-horizontal {
  &.with-gutter {
    scrollbar-gutter: stable;
  }
  &::-webkit-scrollbar {
    position: relative;
    height: 16px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    min-height: 10px;
    background-clip: padding-box;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 0 solid transparent;
    border-left: 0 solid transparent;
    border-radius: 6px;
    background-color: var(--gray-200);
    cursor: pointer;
  }

  &::-webkit-scrollbar-button {
    display: block;
    height: 0;
    width: 0;
  }
}

.just-ui-custom-scrollbar {
  &.with-gutter {
    scrollbar-gutter: stable;
  }
  &::-webkit-scrollbar {
    position: relative;
    width: 16px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    min-height: 10px;
    background-clip: padding-box;
    border-right: 5px solid transparent;
    border-top: 0 solid transparent;
    border-bottom: 0 solid transparent;
    border-left: 5px solid transparent;
    border-radius: 6px;
    background-color: var(--gray-200);
    cursor: pointer;
  }

  &::-webkit-scrollbar-button {
    display: block;
    height: 0;
  }

  &_black {
    &::-webkit-scrollbar-thumb {
      background-color: var(--gray-600);
    }
  }
  &_indigo-light {
    &::-webkit-scrollbar-thumb {
      background-color: var(--indigo-300);
    }
  }
  &_pink {
    &::-webkit-scrollbar-thumb {
      background-color: var(--indigo-200);
    }
  }
  &.justui_items-list {
    > div {
      &::-webkit-scrollbar {
        position: relative;
        width: 16px;
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        min-height: 10px;
        background-clip: padding-box;
        border-right: 5px solid transparent;
        border-top: 0 solid transparent;
        border-bottom: 0 solid transparent;
        border-left: 5px solid transparent;
        border-radius: 6px;
        background-color: var(--gray-200);
        cursor: pointer;
      }

      &::-webkit-scrollbar-button {
        display: block;
        height: 0;
      }
    }
  }
}

.btn-custom-primary {
  color: var(--primary-600);
  border: 1px solid var(--primary-600);
}

.btn-custom-primary:hover {
  color: var(--white);
  background-color: var(--primary-700);
}

.btn-custom-primary:active {
  color: var(--white);
  background-color: var(--primary-800);
}

.react-select__option--is-selected,
.header-select__option--is-selected {
  background-color: var(--primary) !important;
  color: var(--white);
}

.react-select__option--is-focused,
.header-select__option--is-focused {
  background-color: var(--primary-100) !important;
  color: var(--gray-950) !important;
}
select {
  option:hover {
    background-color: var(--primary) !important;
    color: var(--white);
  }
}

.switch-success .switch-input:hover ~ .switch-label {
  background-color: var(--primary-400);
}

.error {
  color: var(--danger);
}

.no-underline {
  text-decoration: none;
}

.tooltip--error {
  .tooltip {
    .tooltip-inner {
      color: white;
      background-color: var(--danger);
    }
    .arrow::before {
      border-bottom-color: var(--danger);
    }
  }
}

.tooltip--dark {
  .tooltip-inner {
    padding: 8px 12px;
    background-color: var(--grey-600);
    border-radius: 6px;
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0px 2px 8px rgba(12, 43, 66, 0.12))
      drop-shadow(0px 0px 1px rgba(17, 60, 92, 0.06));
  }
  .bs-tooltip-auto[x-placement^='top'] {
    span.arrow::before {
      border-top-color: var(--grey-600);
    }
  }
}

.tooltip--white {
  .tooltip-inner {
    padding: 8px 12px;
    background-color: white;
    color: var(--gray-800);
    border-radius: 6px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2), 0px 0px 8px rgba(0, 0, 0, 0.16), 0px 14px 24px rgba(12, 43, 66, 0.06);
  }
  .bs-tooltip-auto[x-placement^='top'] {
    span.arrow::before {
      border-top-color: white;
    }
  }
}

.tooltip-inner {
  white-space: pre-line;
}

.links-disabled {
  a {
    pointer-events: none;
    color: inherit;
    text-decoration: inherit;
  }
}
