
.SelectAccount {
  position: relative;
  &.notification {
    &::after {
      content: '';
      position: absolute;
      top: -3px;
      right: -3px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: var(--red-600);
      border: 1px solid white;
    }
  }

  &__button {
    padding: 5px 9px !important;
    display: flex !important;
    width: 100% !important;
    justify-content: space-between !important;
    max-width: 232px !important;
    flex-wrap: nowrap !important;
    line-height: 16px !important;
  }

  &__text {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
