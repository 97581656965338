.btn-primary-rounded {
  @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border, $btn-border-radius);
}
.btn-secondary-rounded {
  @include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-border, $btn-border-radius);
}
.btn-info-rounded {
  @include button-variant($btn-info-color, $btn-info-bg, $btn-info-border, $btn-border-radius);
}
.btn-success-rounded {
  @include button-variant($btn-success-color, $btn-success-bg, $btn-success-border, $btn-border-radius);
}
.btn-warning-rounded {
  @include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border, $btn-border-radius);
}
.btn-danger-rounded {
  @include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border, $btn-border-radius);
}
