.ucb {
  &-projects-channels-table {
    margin-bottom: 30px;
  }

  &-channel-list {
    width: 100%;

    .ucb-livechat-channel-icon--resterisk {
      background-position: left center;
    }
    &-statistic {
      background-color: var(--white);
    }

    .channel-item {
      td {
        vertical-align: middle;
      }
      &.selected {
        background: lighten($brand-success, 40%);
      }

      & .invisible_input {
        display: block;
        position: absolute;
        left: -10000px;
        top: -10000px;
      }

      & .text-muted {
        font-size: 0.725rem;
      }

      .type-column {
        max-width: 53px;
        width: 53px;
      }
      .token-column {
        word-break: break-all;
        width: 21%;

        p {
          margin-bottom: 0;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .shortNameCopyIcon {
          vertical-align: baseline;
          display: none;
          cursor: pointer;

          svg{
            color: var(--white);
          }

          @media screen and (max-width: 1875px) {
            display: inline-flex;
            min-width: 34px;
          }
        }
        .botserverBotIdWithCopy {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
        }
        span {
          color: #605fe3;
          cursor: pointer;
        }
        span:focus,
        span:hover {
          color: #605fe3;
          text-decoration: underline;
        }
      }
      .test-column {
        width: 13%;
        white-space: nowrap;

        .test-column-text {
          display: block;
          width: 32px;
          text-align: center;
        }
      }
      .status-column {
        width: 19%;
      }
      .rollout-column {
        max-width: 70px;
      }
      .branch-column {
        width: 17%;
        .branch-column-name {
          font-size: 0.75rem;
        }
      }

      .channel-log-link {
        display: block;
      }

      .dropdown {
        cursor: pointer;
      }

      .lines-column {
        width: 70px;
      }
      .integration-name-column {
        width: 90%;
      }
    }

    &.ucb-livechat-channel-list {
      .channel-item {
        .type-column {
          max-width: 53px;
          width: 53px;
        }
      }
    }
  }

  &-telephony-channel-list {
    .channel-item {
      .token-column {
        width: 21%;
        max-width: 130px;
      }
      .lines-column {
        width: 70px;
      }
    }
  }
}

.custom-edit-padding {
  padding-right: 0.725rem;
  padding-left: 0.725rem;
}

.channels__no-channels {
  padding: 40px 60px;
  background: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .color_empty {
    color: var(--gray-600);
  }
}

.customWidth {
  max-width: 588px;
}

.row.custom-margin {
  margin-right: -4px;
  margin-left: -4px;

  & > [class*='col-'] {
    padding-right: 4px;
    padding-left: 4px;
  }
}

.choose-channel {
  & .text-muted {
    color: var(--gray-200);
    font-size: 12px;
    text-transform: uppercase;
  }
  .cancel-button-container {
    margin: 1rem 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
}

.inline-radio {
  & .form-control-feedback {
    margin-top: 0;
  }

  & > div.form-control {
    display: flex;
    height: 39px;
    align-items: center;

    & .form-check {
      margin-right: 4px;
      margin-bottom: 0;

      & input[type='radio'] {
        margin-top: 2px;
        margin-left: 5px;
      }
    }
  }
}

.iconPopupMaxSize {
  width: 44px;
  min-width: 44px;
  max-width: 44px;
  height: 44px;
  overflow: hidden;
  svg {
    width: 100%;
    height: 100%;
  }
}

.f-grow-0 {
  flex-grow: 0;
}

.yandex_special_field {
  border: 2px solid rgba(black, 0);
  border-radius: 12px;
  font-size: 12px;
  background: rgba(black, 0.09);
  height: 24px;
  width: 100%;
  display: flex;
  padding: 0 4px;
  align-items: center;

  & > div {
  }

  & i {
    margin-right: 4px;
  }

  & .hostname {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & .example_token {
    position: relative;
    white-space: nowrap;
    flex-grow: 1;
    &:after {
      content: '';
      position: absolute;
      width: 102%;
      height: 100%;
      border: 2px solid red;
      left: -2px;
      top: 0;
      border-radius: 3px;
    }
  }
}

.full-width-buttons {
  width: 100%;
  & button {
    width: 50%;
    &:not(:disabled):not(.disabled).active {
      background-color: var(--gray-100);
      &:focus {
        box-shadow: none;
      }
    }
  }
}

.nowrap-buttons {
  & button {
    white-space: nowrap;
  }
}

.bot-avatar {
  position: relative;
  width: min-content;
  .bot-avatar--overlay {
    height: 100%;
  }
  &:hover {
    .bot-avatar--overlay-hover {
      display: flex;
    }
  }

  .is-invalid {
    border: 1px solid $danger;
  }

  &--overlay {
    background: rgba(0, 0, 0, 0.53);
    width: 120px;
    height: 120px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    border: none;
    &-hover {
      display: none;
    }
  }
  &--image {
    width: 120px;
    height: 120px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border: none;
    border-radius: 3px;
    box-shadow: 0 0 30px 0 rgba(102, 102, 102, 0.35);
  }
  &--button {
    width: 48px;
    height: 48px;
    padding: 0;

    label {
      display: block;
      width: 100%;
      height: 100%;
      margin-bottom: 0;
      padding: 0.5rem;
      cursor: pointer;
    }
  }
}

@media (max-width: 1366px) {
  .col-12-custom {
    width: 100% !important;
  }
}
