.RenderingModal{
  position: absolute;
  background: white;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;

  .RenderingModalContainer{
    min-width: 300px;
    text-align: center;
  }
}
