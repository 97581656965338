.justui_scroll-area {
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;

  &__horizontal {
    overflow-x: auto;
  }

  &__vertical {
    overflow-y: auto;
  }
}
