$plainColor: #bfbebd;
$focusColor: #7f7d7a;
$black: #2a2722;
$warning: #f6a801;

.editorContainer {
  position: relative;
  font-family: 'Montserrat', sans-serif;

  * {
    outline: none;
  }
  .editor {
    padding: 6px 12px 56px;
    border: 1px solid #e0e1e3;
    border-radius: 3px;
    font-size: 14px;
    line-height: 20px;
    min-height: 115px;
    box-sizing: border-box;

    &:focus {
      border-color: var(--primary, #605fe3);
      ~ .editControls {
        color: $focusColor;
        svg path {
          fill: $focusColor;
        }
      }

      + label {
        transform: translateY(40%) scale(0.8);
      }
    }

    &:not(:empty) {
      + label {
        transform: translateY(40%) scale(0.8);
      }
    }

    + label {
      top: 0;
      left: 16px;
      color: $plainColor;
      position: absolute;
      font-size: 14px;
      transform: scale(1) translateY(100%);
      transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      font-family: 'Montserrat', sans-serif;
      transform-origin: top left;
    }

    pre {
      margin: 0;
      padding: 8px;
      border: 1px solid #ccc;
      background: #f5f5f5;
      border-radius: 3px;
      max-width: 100%;
      white-space: normal;
    }
  }
  .selection {
    position: absolute;
    background: #227fc1;
    opacity: 0.3;
  }
  .editControls {
    position: absolute;
    max-width: 100%;
    bottom: 9px;
    left: 9px;
    right: 9px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    color: $plainColor;

    .buttons {
      button {
        width: 32px;
        height: 32px;
        border-radius: 3px;
        background: transparent;
        text-align: center;
        border: none;
        cursor: pointer;

        svg {
          pointer-events: none;
          path {
            fill: $plainColor;
          }
        }

        &:hover:not(.active) {
          color: $focusColor;
          background-color: #f9f9f8;
        }

        &.active {
          background-color: #eae9e9;
          path {
            fill: $black;
          }
        }
      }
    }

    .subtext {
      font-size: 12px;
      line-height: 16px;
      max-width: 36%;
      right: 9px;
    }
  }
}
.WysiwygUrlEditor {
  width: 400px;
  position: absolute;
  padding: 16px 16px 22px;
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 6px 12px rgba(12, 43, 66, 0.16), 0 0 1px rgba(17, 60, 92, 0.06);
  display: grid;
  gap: 8px;
  font-family: 'Montserrat', sans-serif;
  z-index: 10;
  opacity: 0;

  &.show {
    opacity: 1;
  }

  .urlEditorInput {
    position: relative;

    input {
      padding: 9px 52px 9px 44px;
      outline: none;
      border: 1px solid #eae9e9;
      line-height: 20px;
      font-size: 14px;
      border-radius: 3px;
      margin: 0;
      width: 100%;
      font-family: 'Montserrat', sans-serif;

      &:focus {
        border-color: $warning;
      }
    }
    .urlEditorInputIconLeft {
      position: absolute;
      top: 12px;
      left: 11px;
    }
    .urlEditorInputIconRight {
      position: absolute;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 11px;
      right: 0;
      top: 0;
      cursor: pointer;

      svg {
        pointer-events: none;
      }
    }
  }
  .helperText {
    color: #7f7d7a;
    font-size: 12px;
    line-height: 16px;
  }
}
