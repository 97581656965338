.FAQ__file-upload.FileUploadContainer_DnD {
  align-items: center;
}

.cdqa-modal {
  .form-control {
    height: auto;
    line-height: 20px;
  }
}
