.searchState {
  position: absolute;
  top: 125px;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  justify-content: center;
  width: 50%;
  min-width: 400px;
  border-radius: 8px;

  background-color: white;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 6px 12px rgba(12, 43, 66, 0.16), 0 0 1px rgba(17, 60, 92, 0.06);
  color: var(--gray-900);
  z-index: 3;

  .searchContainer {
    display: flex;
    flex-direction: column;
    width: 100%;

    & .filterHeader {
      display: flex;
      flex-flow: column nowrap;
      gap: 8px;
      padding: 8px 12px;
    }

    & .inputContainer {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      gap: 8px;
    }

    & .searchInput {
      outline: none;
      border: none;
      padding: 0;
    }

    & .searchIcon {
      width: 16px;
      height: 16px;
    }

    & .searchInfo {
      font-size: 12px;
      padding: 8px 12px;
      color: var(--gray-600);
      border-top: 1px solid var(--gray-200);
      border-bottom: 1px solid var(--gray-200);

      display: inline-flex;
      justify-content: space-between;
    }

    .noResults {
      padding: 16px;
      text-align: center;
      white-space: break-spaces;

      font-size: 14px;
      color: var(--gray-600);
      border-top: 1px solid var(--gray-200);
    }
  }

  .searchList {
    margin-bottom: 8px;

    & .searchOption {
      padding: 8px 12px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      min-width: 0;

      &:hover,
      &:focus {
        cursor: pointer;
        background-color: var(--gray-100);
        outline: none;
      }

      &__icon {
        & svg {
          fill: var(--gray-600);
        }
      }

      &__content {
        display: flex;
        flex-flow: column;
        justify-content: center;
        min-width: 0;

        .text {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        & .searchMatch {
          background: var(--gray-600);
          color: var(--white);
          border-radius: 1px;
        }
      }

      &__location {
        display: inline-flex;
        flex-wrap: nowrap;
        color: var(--gray-600);
        max-width: 100%;

        & .theme {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 100%;
          display: inline;
          min-width: 0;
          flex-shrink: 0;
        }

        & .path {
          overflow: hidden;
          white-space: nowrap;
          direction: rtl;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.backDrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
