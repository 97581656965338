.textAreaLikeElement {
  width: 100%;
  height: 100%;
  padding: 8px 12px;
  box-sizing: border-box;
  border: 1px solid var(--grey-200);
  border-radius: 0.25rem;
  overflow-wrap: anywhere;
  word-break: break-word;
  color: var(--grey-950);
  position: relative;
  line-height: 20px;

  transition: border-color 0.3s ease-in-out;

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-300);
    border-radius: 5px;
    &:hover {
      background-color: var(--gray-500);
    }
  }

  &.disabled {
    color: var(--grey-300);
  }
  &.form-control {
    color: var(--grey-950);
    &.disabled {
      border-color: var(--grey-200);
      background-color: var(--grey-100);
      color: var(--grey-300);
    }
  }

  display: grid;

  &:hover {
    border-color: var(--grey-300);
  }

  &:focus-within {
    outline: none;
    border-color: var(--primary);
  }

  &.is-invalid {
    border-color: unset;
    border: 1px solid var(--danger);
  }

  &::after {
    content: attr(data-replicated-value) '';
    white-space: pre-wrap;
    visibility: hidden;
  }

  & > textarea {
    resize: none;
    overflow: hidden;
    border: none;
    background: transparent;
    padding: 0;
    color: transparent;
    line-height: 20px;
    font-size: 0.875rem;

    &::placeholder {
      color: var(--grey-600);
    }
    &:focus {
      outline: none;
      color: inherit;
    }
  }

  &.clearable {
    padding-right: 32px;
  }

  & > textarea,
  & > .textAreaLikeElement_text,
  &::after {
    grid-area: 1 / 1 / 2 / 2;
  }

  & > .textAreaLikeElement_text {
    white-space: pre-wrap;
  }

  .inner-append {
    grid-area: 1/1/2/2;
    justify-self: flex-end;
    align-self: center;
  }

  .clear {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: calc(1.5em + 0.75rem);
    height: calc(1.5em + 0.75rem);
  }
  .inner-append {
    grid-area: 1/1/2/2;
    justify-self: flex-end;
    align-self: center;
  }
}
