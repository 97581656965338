@mixin inertNode {
  pointer-events: none;
  & > * {
    pointer-events: auto;
  }
}

.JGraphHud {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-flow: row nowrap;

  @include inertNode;

  .canvasArea {
    position: relative;
    flex: 1;
    @include inertNode;
  }

  &__container {
    z-index: 551; // over all just selects
  }
}
