.textAreaLayer {

  &__text_wrapper {
    display: flex;
    align-items: center;
    z-index: 1;
  }

  &__text {
    text-align: center;
    vertical-align: top;
    display: inline-block;
    line-height: 150%;
    color: black;
    //overflow: hidden;
    min-width: 100%;
    min-height: 60px;
    &:focus {
      outline: none;
    }
  }

  .editorNotificationLayout {
    display: flex;
    position: absolute;
    -ms-flex-pack: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 500ms ease-in;
    z-index: -1;

    &_visible {
      opacity: 1;
    }

    &__textlimit {
      width: 100%;
      height: 100%;
      opacity: 0.3;
      background-size: 70%;
      background-position: 50% 35%;
      background-repeat: no-repeat;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQwcHgiIGhlaWdodD0iMTQwcHgiIHZpZXdCb3g9IjAgMCAxNDAgMTQwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnNrZXRjaD0iaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoL25zIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggMy40ICgxNTU4OCkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+d2FyPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc2tldGNoOnR5cGU9Ik1TUGFnZSI+CiAgICAgICAgPGcgaWQ9IndhciIgc2tldGNoOnR5cGU9Ik1TTGF5ZXJHcm91cCIgZmlsbD0iIzAwMDAwMCI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik03MCwxMjAgTDcwLDEyMCBDOTcuNjE0MjM3NSwxMjAgMTIwLDk3LjYxNDIzNzUgMTIwLDcwIEMxMjAsNDIuMzg1NzYyNSA5Ny42MTQyMzc1LDIwIDcwLDIwIEM0Mi4zODU3NjI1LDIwIDIwLDQyLjM4NTc2MjUgMjAsNzAgQzIwLDk3LjYxNDIzNzUgNDIuMzg1NzYyNSwxMjAgNzAsMTIwIEw3MCwxMjAgTDcwLDEyMCBaIE03MCwxNDAgQzMxLjM0MDA2NzUsMTQwIDAsMTA4LjY1OTkzMiAwLDcwIEMwLDMxLjM0MDA2NzUgMzEuMzQwMDY3NSwwIDcwLDAgQzEwOC42NTk5MzIsMCAxNDAsMzEuMzQwMDY3NSAxNDAsNzAgQzE0MCwxMDguNjU5OTMyIDEwOC42NTk5MzIsMTQwIDcwLDE0MCBMNzAsMTQwIFoiIGlkPSJTaGFwZS1Db3B5LTIiIHNrZXRjaDp0eXBlPSJNU1NoYXBlR3JvdXAiPjwvcGF0aD4KICAgICAgICAgICAgPHBhdGggZD0iTTE2Ljk2Njk5MTQsMzEuMTA5MTI3IEwzMS4xMDkxMjcsMTYuOTY2OTkxNCBMMTIzLjAzMzAwOSwxMDguODkwODczIEwxMDguODkwODczLDEyMy4wMzMwMDkgTDE2Ljk2Njk5MTQsMzEuMTA5MTI3IEwxNi45NjY5OTE0LDMxLjEwOTEyNyBaIiBpZD0iUGF0aCIgc2tldGNoOnR5cGU9Ik1TU2hhcGVHcm91cCI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
    }
  }
}
