.justui-range-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;
  .justui-range-steps {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    position: absolute;
    top: calc(100% - 0.25rem);
    z-index: -1;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    & > * {
      width: 1.5rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      color: var(--gray-800);
      font-size: 0.75rem;
      &:first-child {
        align-items: flex-start;
      }
      &:last-child {
        align-items: flex-end;
      }
    }
  }
}

.just-ui__slider {
  .slider-handle {
    position: absolute;
    transform: translate(-50%, -70%);
    z-index: 5;
    width: 16px;
    height: 16px;
    cursor: pointer;
    border-color: var(--primary);
    border-width: 1px;
    background-color: transparent;
  }
  div[role='slider'] {
    position: absolute;
    transform: translate(-50%, -70%);
    z-index: 2;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border-color: var(--primary);
    border-width: 1px;
    border-style: solid;
  }
  .slider-track {
    position: absolute;
    transform: translate(0%, -150%);
    height: 4px;
    z-index: 1;
    border-radius: 7px;
    cursor: pointer;
  }
  .slider-outer-rail {
    width: 100%;
    height: 4px;
    transform: translate(0%, 50%);
    border-radius: 7px;
    cursor: pointer;
  }
  .slider-inner-rail {
    width: 100%;
    height: 4px;
    transform: translate(0%, -50%);
    border-radius: 7px;
    pointer-events: none;
    background-color: var(--grey-200);
  }
}
