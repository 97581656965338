.validation-error-panel {
  padding: 16px;
  background-color: var(--primary-100);
  border-radius: 4px;
  display: flex;
  gap: 16px;
  &__info-block {
    color: var(--grey-800);
  }
  &__links-block {
    color: var(--grey-950);
  }
  &__link {
    display: flex;
    margin-bottom: 8px;
    gap: 4px;
    p {
      margin-bottom: 0;
    }
    .pseudoLink {
      border: none;
      padding: 0;
      svg.fa-arrow-up {
        transform: rotate(45deg);
        margin-left: 3px;
      }
    }
  }
  &__btn-block {
    display: flex;
    gap: 8px;
    .btn-flat.btn-secondary {
      color: var(--grey-950);
    }
  }

}
