.FaqHomeView {
  padding: 32px 24px;
  background: var(--gray-100);
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;

  &__content {
    max-width: 900px;
    display: flex;
    flex-direction: column;
    width: 100%;

    h1.title {
      margin-bottom: 24px;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: var(--gray-950);
    }
  }

  &__addPanel {
    padding: 8px;
    background-color: white;
    margin-bottom: 24px;
    border-radius: 4px;
    display: flex;
    gap: 4px;
  }

  .table {
    --col-1-width: 100%;
    --col-2-width: 200px;
    --col-3-width: 200px;
    --col-4-width: 100px;
    --col-5-width: 67px;

    border-collapse: separate;
    border-spacing: 0 16px;

    @for $i from 1 through 5 {
      th:nth-child(#{$i}),
      td:nth-child(#{$i}) {
        width: var(--col-#{$i}-width);
        min-width: var(--col-#{$i}-width);
        max-width: var(--col-#{$i}-width);
      }
    }

    thead tr th {
      font-weight: 400;
      color: var(--gray-600);
      padding: 0 8px;
      &:nth-child(1) {
        padding-left: 16px;
      }
      &:nth-last-child(1) {
        padding-right: 16px;
      }
      &:nth-child(4) {
        text-align: end;
      }
    }

    tbody tr td {
      background: var(--white);
      padding: 20px 8px;
      border-bottom: 1px solid transparent;
      border-top: 1px solid transparent;

      &:nth-child(1) {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        padding-left: 16px;
        border-left: 1px solid transparent;
      }
      &:nth-child(4) {
        text-align: end;
      }
      &:nth-last-child(1) {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        padding-right: 16px;
        border-right: 1px solid transparent;
      }
    }

    tr:not(.cdqa):focus {
      outline: none;
      td {
        border-color: var(--primary-500) !important;
      }
    }

    tbody tr:not(.cdqa):hover .titleCell {
      cursor: pointer;
      color: var(--primary-700);
    }
    tbody tr:not(.cdqa):active .titleCell {
      color: var(--primary-800);
    }
    tbody tr.disabled .titleCell {
      color: var(--gray-600);
    }

    tbody tr.loading {
      animation: pulse 1200ms infinite;
    }


  }

  .dropDownElement {
    padding: 8px 12px 8px 8px;

    &.danger {
      color: var(--red-600);
    }
    :global(.dropdown-item--cdqa-copy) {
      display: flex;
      p {
        margin: 0;
      }
    }
  }
}

@keyframes pulse {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.3;
  }
}

.EmptyFaqContent {
  padding-top: 128px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  gap: 24px;

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: var(--gray-500);
  }
}
