.tariff-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--gray-900);
  border-top: 1px solid var(--gray-900);
  border-bottom: 1px solid var(--gray-900);
  margin-top: auto;
  padding: 1rem;
  p {
    margin: 0;
    font-size: 12px;
    color: var(--grey-300);
    &:first-child {
      font-weight: bold;
    }
  }
}
