.CommitButtonDropDown{
  color: var(--gray-800);


  &_Item{
    color: var(--gray-800);
    position: relative;
    padding: 8px 12px 8px 12px !important;

    &_ExtraPadding{
      padding-left: 36px !important;
    }

    small{
      color: var(--gray-600);
      width: 320px;
      max-width: 320px;
      white-space: normal;
      display: block;
    }
  }
  &_Icon{
    position: absolute;
    top: 10px;
    left: 12px;
    width: 16px;
    height: 16px;
    margin: 0!important;
    svg{
      margin: 0!important;
    }
  }


  :global(.dropdown-item){
    &:active{
      * {
        color: var(--white);
      }
    }
  }
}
