.settings {
  .settings-nav {
    position: relative;
  }

  .btn > .icon-settings {
    margin-top: 0;
  }

  .nav {
    flex-direction: column !important;

    .nav-item {
      min-width: auto;
      position: relative;
      margin: 0;
      transition: background 0.3s ease-in-out;
      text-align: left;
      white-space: nowrap;

      ul {
        max-height: 0;
        padding: 0;
        margin: 0;
        overflow-y: hidden;
        transition: max-height 0.3s ease-in-out;

        li {
          padding: 0;
          list-style: none;
        }
      }

      .nav-link {
        display: block;
        padding: 10px 20px;
        text-decoration: none;
        color: $gray-dark;

        &:hover {
          background-color: $brand-primary;
          color: var(--white);

          i {
            color: var(--white);
          }
        }

        &.active {
          background: rgba(0, 0, 0, 0.1);

          &:hover {
            background-color: $brand-primary;
            color: var(--white);

            i {
              color: var(--white);
            }
          }
        }
        [class^='icon-'],
        [class*=' icon-'] {
          display: inline-block;
          margin-top: -4px;
          vertical-align: middle;
        }

        i {
          width: 20px;
          margin: 0 ($sidebar-nav-link-padding-x * 0.5) 0 0;
          font-size: 14px;
          color: $sidebar-nav-link-icon-color;
          text-align: center;
        }

        .badge {
          float: right;
          margin-top: 2px;
        }

        &.nav-dropdown-toggle {
          &::before {
            position: absolute;
            top: ($sidebar-nav-link-padding-y + ($font-size-base * 0.25));
            right: $sidebar-nav-link-padding-x;
            display: block;
            width: ($font-size-base);
            height: ($font-size-base);
            padding: 0;
            font-size: $font-size-base;
            line-height: ($font-size-base * 3 * 0.25);
            text-align: center;
            content: '\2039';
            transition: 0.3s;
          }
        }
      }

      &.nav-dropdown {
        &.open {
          background: rgba(0, 0, 0, 0.05);

          > ul,
          > ol {
            max-height: 1000px;
          }
          a.nav-link {
            border-left: 0 !important;
          }

          > .nav-link.nav-dropdown-toggle::before {
            -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
          }

          .nav-dropdown.open {
            border-left: 0;
          }
        }
        &.nt {
          transition: 0s !important;
          > ul,
          > ol {
            transition: 0s !important;
          }
          a.nav-link {
            &.nav-dropdown-toggle {
              &::before {
                transition: 0s !important;
              }
            }
          }
        }
      }

      a.nav-label {
        display: block;
        padding: ($sidebar-nav-link-padding-y * 0.125) $sidebar-nav-link-padding-x;
        color: $sidebar-nav-title-color;

        &:hover {
          color: $sidebar-color;
          text-decoration: none;
        }

        i {
          width: 20px;
          margin: -3px ($sidebar-nav-link-padding-x * 0.5) 0 0;
          font-size: 10px;
          color: $sidebar-nav-link-icon-color;
          text-align: center;
          vertical-align: middle;
        }
      }

      @if (lightness($sidebar-bg) < 40) {
        .progress {
          background-color: lighten($sidebar-bg, 15%) !important;
        }
      }
    }
  }
}
