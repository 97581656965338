.floatingMenu {
  display: flex;
  align-items: center;
  background-color: var(--white, #fff);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 6px 12px rgba(12, 43, 66, 0.16), 0 0 1px rgba(17, 60, 92, 0.06);
  border-radius: 4px;
  padding: 4px 4px;
  gap: 8px;

  &__dropdown {
    background: transparent !important;
    background-color: transparent !important;
    border: none !important;
    padding: 0 0 8px !important;
    left: -5px !important;
  }

  &__btn {
    padding: 4px !important;
  }
}
