.editor__editor-page {
  margin: 0 !important;
  height: 100%;
  background-color: var(--gray-900);
  color: var(--gray-300);

  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content min-content minmax(0, 1fr);
  grid-template-areas:
    'sidebar tabs'
    'sidebar maintoolbar'
    'sidebar content';

  .editor__left-panel {
    grid-area: sidebar;
  }

  .editor__tabs-toolbar {
    grid-area: tabs;
  }

  .editor__main-toolbar {
    grid-area: maintoolbar;
  }
  .editor__editor {
    grid-area: content;
    z-index: 0;
    display: flex;
    flex-direction: column;

    & .ace_gutter {
      background: var(--gray-800);

      border: 1px solid var(--gray-950);
      border-top: unset;

      & .ace_gutter-cell {
        color: var(--gray-500);
      }
      & .ace_gutter-active-line {
        color: var(--gray-300);
        background: var(--gray-900);
      }
    }

    & .adjustVoicePlayButton::before {
      content: ' ';
      display: inline-block;
      cursor: pointer;
      background-image: url('./img/svg-icon.svg');
      height: 9px;
      width: 8px;
      position: absolute;
      left: 4px;
      top: 4px;
    }
  }
  &--left-panel-tabs.justui_tabs.nav {
    margin-bottom: 5px;
    li a {
      justify-content: center;
      padding: 0.27em 1rem;
    }
  }
}
