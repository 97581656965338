.EmptyBlocksPlaceholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;

  background-color: var(--gray-100);
  border-radius: 8px;
  padding: 16px 24px;
  color: var(--gray-600);

  &__title {
    font-weight: 700;
  }

  &__description {
    text-align: center;
  }
}
