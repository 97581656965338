#editor-popup {
  position: fixed;
  z-index: 9999;

  border: 1px solid var(--gray-600);

  background: var(--gray-950);
  color: #c1c1c1;
  font-size: 14px;
  line-height: 20px;

  #editor-popup-container {
    min-width: 120px;

    & .noFound {
      padding: 0 4px;
    }

    & ul.options {
      max-height: 240px;
      overflow-y: auto;

      list-style: none;
      margin: 0;
      padding: 0;

      &::-webkit-scrollbar {
        width: 5px;
        background: transparent;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: var(--gray-800);
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0 4px;
        cursor: pointer;
        border: 1px solid transparent;

        &.selected {
          background-color: #3a674e;
        }
        &:hover {
          border: 1px solid rgba(109, 150, 13, 0.8);
          background: rgba(58, 103, 78, 0.62);
        }
      }
    }
  }

  #editor-popup-loading {
    display: grid;

    place-content: center;
    padding: 4px;

    & .loader {
      --size: 12px;
      width: var(--size);
      height: var(--size);

      border-radius: 50%;
      position: relative;
      animation: rotate 1s linear infinite;
    }
    & .loader::before {
      content: '';
      box-sizing: border-box;
      position: absolute;
      inset: 0;
      border-radius: 50%;
      border: 2px solid #fff;
      animation: prixClipFix 2s linear infinite;
    }

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes prixClipFix {
      0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
      }
      25% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
      }
      50% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
      }
      75% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
      }
      100% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
      }
    }
  }
}
