.Tag{
  align-self: center;
  padding: 0 4px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;

  /* text/invert--white */

  &.secondary{
    background-color: var(--gray-600, #6A7178);
    color: #FFFFFF;

  }
}
