.NeedToRedeployWidget {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  background: var(--gray-900);
  z-index: 9;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 6px 12px rgba(12, 43, 66, 0.16), 0 0 1px rgba(17, 60, 92, 0.06);
  border-radius: 3px;
  cursor: pointer;
  .text {
    padding: 8px 16px;
    border-right: 1px solid var(--gray-800);
    color: var(--gray-100);
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
  }
  .button {
    padding: 0 8px;
    display: flex;
    align-items: center;
    [class*='justui__icon-wrapper'] {
      line-height: 16px;
      height: 16px;
      svg {
        color: var(--gray-300) !important;
      }
    }
  }
}
