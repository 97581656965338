.PatternInfoModal {
  & .form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: var(--gray-800);

    .label {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.0075em;

      margin-bottom: 4px;
    }

    .field {
      background: var(--gray-100);
      border-radius: 4px;

      padding: 4px 8px;
    }
  }
}
