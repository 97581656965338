.global-right-panel {
  width: 360px;
  height: 100%;
  position: relative;

  .justwidget {
    z-index: unset;
  }

  & > .test-widget {
    height: 100%;
  }

  .just-ui-spinner {
    z-index: 500 !important;
  }
}

.tts-widget-background {
  background-color: var(--white);
}
