@import 'src/scss/scrollbar';

.CreationScreenMenu {
  position: fixed;
  width: 260px;
  background-color: var(--white, #fff);
  z-index: 4;
  padding: 12px 0;
  border-radius: 8px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 6px 12px rgba(12, 43, 66, 0.16), 0 0 1px rgba(17, 60, 92, 0.06);

  .header {
    padding: 0 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 12px;
  }
  .title {
    display: flex;
    color: var(--gray-800, #333c45);
  }

  .parentSelection {
    padding: 0 12px;
    margin-bottom: 12px;
  }

  .inGroupInfo {
    padding: 8px 12px;
    background-color: var(--gray-100, #f4f5f5);
    .inGroupInfoTitle {
      color: var(--gray-600, #6a7178);
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.01em;
    }
    .inGroupInfoBody {
      color: var(--gray-600, #333c45);
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.01em;
      overflow: hidden;
      word-break: break-word;
    }
  }
  .body {
    margin: 12px 0;

    .item {
      padding: 8px 12px;
      display: flex;
      flex-direction: row;
      cursor: pointer;
      align-items: center;
      position: relative;

      &:hover {
        background-color: var(--gray-100, rgba(0, 0, 0, 0.04));

        & > .AddingSimpleBlock {
          display: flex;
          flex-direction: column;
        }
      }

      .itemTitle {
        flex-grow: 1;
      }
    }
  }

  .footer {
    padding: 0 12px;
    button {
      width: 100%;
    }
  }
}

.AddingSimpleBlock {
  display: none;
  position: absolute;
  left: 260px;
  width: 170px;
  background-color: var(--while, #fff);
  padding: 8px 0;
  border-radius: 8px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 6px 12px rgba(12, 43, 66, 0.16), 0 0 1px rgba(17, 60, 92, 0.06);
  cursor: default;
  max-height: 290px;
  overflow: hidden;

  &.meta {
    width: 244px;
  }

  svg {
    color: var(--gray-500, #888e93);
  }

  .container {
    overflow: auto;
    @include scrollbar();
  }

  .addingItem {
    cursor: pointer;
    color: var(--gray-800, #333c45);
    padding: 8px 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-size: 14px;

    &:hover {
      background-color: var(--gray-100, rgba(0, 0, 0, 0.04));
    }

    .reactionName {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .addingItemTitle {
    color: var(--gray-600, #6a7178);
    cursor: default;
    font-weight: 700;
    &:hover {
      background-color: transparent;
    }
  }
}
