.Message{
  &.danger{
    background-color: #FFEEEF;
  }
  &.success{
    background-color: #E1F4E8;
  }
  &.warning{
    background-color: #FEF9E7;
  }
  &.info{
    background-color: #E5F4F7;
  }

  padding: 15px 19px;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  gap: 18px;

}
