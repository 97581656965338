.activity-chart {
  height: 280px;
  padding: 20px;
}

.chartWrapper {
  height: 100%;
  width: 100%;

  &_common-controls {
    .form-inline {
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-start;

      .form-group {
        flex-wrap: nowrap;
        white-space: nowrap;
      }
    }
  }

  & > div {
    height: calc(100% - 32px);
    width: 100%;
  }

  &_scrollable {
    height: auto;
    width: auto;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    margin-bottom: -17px;
    margin-right: -17px;

    &.noVerticalScroll {
      margin-right: 0;
    }
  }
}

.vertical-scroll_chart,
.horizontal-scroll_chart {
  z-index: 9;
  background: rgba(0, 0, 0, 0.35);
  cursor: pointer;
  border-radius: 3px;
}

.vertical-scroll_chart {
}

.horizontal-scroll_chart {
}

.legend {
  margin: 0 0 20px 0;
  padding: 0;

  .legend-item {
    display: inline-block;
    cursor: pointer;

    &.legend-item--hidden {
      text-decoration: line-through;
    }

    & + .legend-item {
      margin-left: 16px;
    }

    &--color {
      width: 30px;
      height: 10px;
      display: inline-block;
      border-width: 2px;
      border-style: solid;
      vertical-align: middle;
      margin-right: 10px;
    }

    &--tex {
      vertical-align: middle;
      font-size: 0.75rem;
    }
  }
}
