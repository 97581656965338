@import 'src/scss/scrollbar';
$notifications_padding: 0.75rem 0.75rem 0.5rem 0.75rem;
$font-small: 12px;

.notifications {
  .popover {
    max-width: 420px;
    width: 100%;
    left: 6px !important;
    top: -2px !important;
    z-index: 1049;
    letter-spacing: 0.02em;
  }

  .justui_popover-body {
    padding: 0;
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__hidden {
    display: none;
  }

  &__logs {
    max-width: 100%;
    padding: 16px;
    color: var(--gray-950);
    background-color: var(--blue-100);
    word-break: break-word;
    white-space: pre-wrap;
    position: relative;
    max-height: 320px;
    @include scrollbar();

    &_button {
      position: absolute;
      top: 8px;
      right: 8px;
      display: flex !important;
      padding: 0.5rem !important;
      z-index: 1;
    }
  }

  &__tabs {
    border-bottom: 1px solid var(--gray-300);
    padding: 0.5rem 0.75rem;

    .justui_tabs.nav {
      .nav-item {
        margin-right: 0.5rem;

        &.active {
          border-radius: 4px;
        }

        .nav-link {
          padding: 0.25rem 0.5rem;
          border: 1px solid transparent;
          font-size: $font-small;

          &:hover {
            background-color: var(--gray-200);
            border-radius: 4px;
          }

          &.active {
            background-color: var(--primary-200);
            color: var(--primary-500);
            border: 1px solid var(--primary-300);
            border-radius: 4px;
          }
        }
      }
    }
  }

  &__date {
    padding: 0.5rem 0.75rem;
    font-size: $font-small;
    color: var(--gray);

    &:not(:first-child) {
      border-top: 1px solid var(--gray-200);
    }
  }

  &__empty {
    padding: $notifications_padding;
    height: 260px;
    color: var(--gray-300);
    font-weight: 500;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__delete {
    border-top: 1px solid var(--gray-300);
    display: flex;
    justify-content: flex-end;
    padding-right: 4px;

    .btn {
      font-size: 12px;
      letter-spacing: 0.02em;
      svg {
        font-size: 14px;
      }
    }
  }

  &__scroll-area {
    height: 60vh;
  }

  &__button {
    &-active {
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: var(--danger);
        border-radius: 50%;
        left: 21px;
        top: 7px;
        border: 2px solid var(--white);
      }
    }
  }

  .notification {
    display: flex;
    justify-content: flex-start;

    border-top: 1px solid var(--gray-200);

    &__body {
      color: var(--gray-950);
      width: 100%;
      max-width: 333px;

      h6 {
        font-size: $font-small;
        padding-top: 0.75rem;
        font-weight: 700;
        line-height: 1.125rem;
      }
    }

    &__icon {
      padding: 12px 12px 12px 10px;
    }

    &__time {
      color: var(--gray);
      font-size: 0.75rem;
      margin-bottom: 4px;
    }

    &__message {
      font-size: $font-small;
      margin-bottom: 12px;
      line-height: 16px;
    }

    &__buttons {
      display: flex;
      margin-bottom: 0.75rem;
    }

    &__delete {
      margin: 2px 0.25rem 0 0;
      color: var(--gray);
    }

    &.notification-red {
      h6 {
        color: var(--danger);
      }

      .notification__icon {
        color: var(--danger);
        box-shadow: -3px 0 0px 0px var(--danger);
        margin-left: 2px;
      }
    }

    &.notification-blue {
      h6 {
        color: var(--primary);
      }

      .notification__icon {
        color: var(--primary);
        box-shadow: -3px 0 0px 0px var(--primary);
        margin-left: 2px;
      }
    }

    &.notification-green {
      h6 {
        color: var(--success);
      }

      .notification__icon {
        color: var(--success);
        box-shadow: -3px 0 0px 0px var(--success);
        margin-left: 2px;
      }
    }

    &.notification-orange {
      h6 {
        color: var(--yellow-300);
      }

      .notification__icon {
        color: var(--yellow-300);
        box-shadow: -3px 0 0px 0px var(--yellow-300);
        margin-left: 2px;
      }
    }

    &.seen {
      .notification__icon {
        box-shadow: -3px 0 0px 0px transparent;
        margin-left: 2px;
      }
    }
  }
}

.notification-message {
  text-align: left;
}

.tasks {
  .popover {
    left: 6px !important;
    z-index: 1049;
    letter-spacing: 0.02em;
  }

  &__date {
    margin: 4px 0;
    padding: 0.5rem 0.75rem 0.5rem 14px;
    font-size: $font-small;
    color: var(--gray);
    background-color: var(--white);
  }

  &__button {
    &-active {
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: var(--danger);
        border-radius: 50%;
        left: 22px;
        top: 6px;
        border: 2px solid var(--white);
      }
    }
  }

  &__container {
    width: 100%;
    height: 60vh;
    padding-bottom: 40px;
    background-color: var(--gray-200);

    &.empty {
      background-color: var(--white);
    }
  }

  &__delete {
    width: 100%;
    text-align: right;
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: white;
    padding: 3px 5px 3px 0;
    border-radius: 0 0 3px 3px;

    button {
      font-size: 12px;
      svg {
        font-size: 14px;
      }
    }
  }
}

.task {
  &-card {
    background-color: white;
    margin: 4px 0;
    padding-left: 2px;
    color: var(--gray);
    box-shadow: -3px 0 0px 0px transparent;

    &.hovered {
      box-shadow: -3px 0 0px 0px var(--primary);
      padding-left: 0;
      margin-left: 2px;
    }

    &__name {
      overflow: auto;
    }

    &__stop {
      padding-right: 6px;
      cursor: pointer;
    }

    &__bot-id {
      font-size: 0.75rem;
      margin-bottom: 0;
    }

    .justui_spoiler-header {
      .spoiler-arrow {
        width: unset !important;
        height: unset !important;
        margin-right: 0 !important;
        svg {
          font-size: 14px !important;
          color: var(--gray) !important;
        }
      }
    }

    h3 {
      color: var(--gray-950);
      font-size: 12px;
      font-weight: 700;
    }

    &__info {
      display: flex;
      justify-content: space-between;
      padding: 12px;
    }

    &__control {
      display: flex;
      align-items: flex-start;

      p {
        margin-right: 8px;
        font-size: 12px;
      }

      button {
        margin-top: -7px;
        margin-right: -7px;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        svg {
          font-size: 14px;
        }
      }
    }
    &__button {
      margin-left: 0.75rem;
      margin-bottom: 1rem;
    }
  }
}

.spoiler {
  &-header {
    display: flex;
    align-items: center;
    font-size: 12px;

    svg {
      margin-right: 8px;
    }
  }

  &-container {
    padding: 0 23px 16px 15px;
    font-size: 12px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.task-spinner {
  animation: spin 1s linear infinite;
}

.event {
  &-circle {
    border-radius: 50%;
    border: 2px solid var(--gray-200);
    width: 10px;
    height: 10px;
    margin-right: 12px;
  }

  &-heading {
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }
  }

  &-line {
    width: 2px;
    background: var(--gray-200);
    margin-right: 20px;
    margin-left: 4px;
  }

  &-message {
    display: flex;

    &__text {
      background: var(--yellow-100);
      border-radius: 3px;
      color: #645006;
      padding: 8px 12px;
      margin: 12px 0;
      word-break: break-word;
    }
  }
}
