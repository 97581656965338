.faqPage {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  color: var(--gray-800);

  &__description {
    max-width: 674px;
    white-space: pre-line;
  }
  p {
    margin-bottom: 0;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    padding: 24px;
    background: var(--white);
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.16), 0px 1px 3px rgba(12, 43, 66, 0.06), 0px 0px 1px rgba(17, 60, 92, 0.06);
  }

  &__content {
    width: 100%;
    padding: 24px;
  }

  .faqContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 24px 24px;
    box-sizing: border-box;

    font-size: 16px;
    font-weight: 500;
    padding: 24px;

    background: var(--white);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(12, 43, 66, 0.06), 0 0 1px rgba(17, 60, 92, 0.06);
    border-radius: 4px;
    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      width: 100%;
      &--fullscreen {
        width: 860px;
        gap: 32px;
      }
    }

    &__list {
      display: flex;
      width: 100%;
      gap: 16px;
      flex-wrap: wrap;
    }
    &__footer {
      display: flex;
      width: 100%;
      align-items: center;
      gap: 16px;
      button {
        width: 100%;
        max-width: 192px;
        margin-top: 8px;
      }
    }
    &__error {
      font-weight: 400;
      color: var(--red-600);
    }
    &--fullscreen {
      position: absolute;
      top: 0%;
      bottom: 0%;
      right: 0%;
      margin: 0;
      border-radius: 0;
      align-items: center;
      overflow-x: hidden;
    }
  }
}
