.node-graph-container {
  color: #323232;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  background: url('../../img/gridtile.png');
  display: inline-flex;
  .overflow-auto {
    overflow: auto;
    position: relative;
    padding-left: 0;
  }
}

.aceEditableNode,
.class-chooser-popup-menu {
  position: absolute;
  width: 500px;
  z-index: 9;
  background-color: #30373a;
  border: 2px solid #b7b7b7;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  &.opacity {
    opacity: 0.4;
  }
  &.right {
    .buttons {
      right: -25px;
    }
  }
  &.left {
    .buttons {
      left: -25px;
    }
  }
  .aceArrow {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    top: 0;
  }
  .aceArrow.right {
    left: -15px;
    border-width: 17.5px 15px 17.5px 0;
    border-color: transparent #b7b7b7 transparent transparent;
  }
  .aceArrow.left {
    right: -15px;
    border-width: 17.5px 0 17.5px 15px;
    border-color: transparent transparent transparent #b7b7b7;
  }
  .buttons {
    position: absolute;
    top: -2px;
    z-index: 9;
    button {
      width: 25px;
      height: 25px;
      text-align: center;
      display: block;
      margin: 0;
      padding: 0;
      .fa-times {
        color: var(--danger);
      }
      .fa-check {
        color: var(--success);
      }
    }
  }
}
.class-chooser-popup-menu {
  position: absolute;
  width: 300px;
  background: white;
}

#svgContainer {
  position: absolute;
  z-index: 6;
}
.redDebugDot {
  z-index: 150;
  position: absolute;
  width: 5px;
  height: 5px;
  background: red;

  &.--blue {
    background: #00cdff;
  }
  &.--yellow {
    background: #fff513;
  }
}

.htmlOverflow {
  overflow-x: hidden;
}
.container-changeable {
  width: 10000px;
  height: 10000px;
  position: absolute;
}

#nodeGraph {
  width: 100%;
  height: 100%;
  position: absolute;
}

.node {
  position: absolute;
  width: 300px;
  border: 1px solid transparent;
  border-radius: 4px;
  //padding-bottom: 5px;
  -webkit-box-shadow: 6px 6px 28px -2px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 6px 6px 28px -2px rgba(0, 0, 0, 0.4);
  box-shadow: 6px 6px 28px -2px rgba(0, 0, 0, 0.4);
  background-color: rgba(0, 0, 0, 0.7);
  user-select: none;
  &.dummy {
    opacity: 0.5;
    position: fixed;
  }
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.58);
    z-index: 1;
    text-align: center;
    font-weight: bold;
    color: var(--white);
  }

  &.InputText {
    -webkit-box-shadow: 6px 6px 28px -2px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 6px 6px 28px -2px rgba(0, 0, 0, 0.4);
    box-shadow: 6px 6px 28px -2px rgba(0, 0, 0, 0.4);
    background-color: rgba(4, 24, 39, 0.7);
    &.selected {
      background-color: rgba(4, 24, 39, 0.9);
    }
    .node-header,
    .node-header--fake {
      background-color: #2f5e7f;
    }
  }
  &.InputNumber {
    -webkit-box-shadow: 6px 6px 28px -2px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 6px 6px 28px -2px rgba(0, 0, 0, 0.4);
    box-shadow: 6px 6px 28px -2px rgba(0, 0, 0, 0.4);
    background-color: rgba(#7f4640, 0.7);
    &.selected {
      background-color: rgba(darken(#7f4640, 15%), 0.9);
      .node-header,
      .node-header--fake {
        background-color: rgba(darken(#7f4640, 20%), 0.9);
      }
    }
    .node-header,
    .node-header--fake {
      background-color: #7f4640;
    }
  }
  &.Switch {
    -webkit-box-shadow: 6px 6px 28px -2px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 6px 6px 28px -2px rgba(0, 0, 0, 0.4);
    box-shadow: 6px 6px 28px -2px rgba(0, 0, 0, 0.4);
    background-color: rgba(#7f5377, 0.7);
    &.selected {
      background-color: rgba(darken(#7f5377, 15%), 0.9);
      .node-header,
      .node-header--fake {
        background-color: rgba(darken(#7f5377, 20%), 0.9);
      }
    }
    .node-header,
    .node-header--fake {
      background-color: #7f5377;
    }
  }
  &.Confirmation {
    -webkit-box-shadow: 6px 6px 28px -2px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 6px 6px 28px -2px rgba(0, 0, 0, 0.4);
    box-shadow: 6px 6px 28px -2px rgba(0, 0, 0, 0.4);
    background-color: rgba(#4c7f47, 0.7);
    &.selected {
      background-color: rgba(darken(#4c7f47, 15%), 0.9);
      .node-header,
      .node-header--fake {
        background-color: rgba(darken(#4c7f47, 20%), 0.9);
      }
    }
    .node-header,
    .node-header--fake {
      background-color: #4c7f47;
    }
  }
}

.node-edit {
  font-size: 16px;
  float: right;
  padding-right: 5px;
  &:hover {
    color: var(--success);
  }
}

.node.selected {
  border: 1px solid var(--gray);
  box-shadow: 0 0 8px 3px #555;
  z-index: 999 !important;
  background-color: rgba(0, 0, 0, 0.9);
}

.node-seperator {
  color: black;
  border-color: black;
}

.node-inputs {
  padding: 10px;
}
.button-margin-between {
  overflow: hidden;
  > div {
    margin-top: 5px;
  }

  div.btn {
    position: relative;
    margin-bottom: 5px;
    margin-top: 5px;
    margin-left: 2px;
  }
}
.menuImageAdder {
  position: absolute;
  z-index: 999;
  ul {
    margin: 0;
    padding: 5px;
    background: black;
    li,
    label {
      display: block;
      list-style: none;
      cursor: pointer;
      color: var(--white);
    }
  }
}
.node-container.screen {
  &.is-dragover {
    .dummy.--image-block {
      display: block;
    }
  }
}
@keyframes moveDeleting {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}
.dummy.--image-block {
  border: 2px dashed rgba(white, 0.85);
  border-radius: 5px;
  height: 32px;
  display: none;
  margin: 5px 5px 0;
}
$exampleGroups-context: #00ffd7;
$exampleGroups-global: #317b73;
$exampleGroups-theme: #ff00c9;

.node-content {
  color: #f4f4f4;
  position: relative;
  padding: 5px 5px 0 5px;
  clear: both;
  word-break: break-all;
  .btn {
    color: #f4f4f4;
  }
  .--image-block {
    background-position: center center;
    background-repeat: no-repeat;
    &.is-dragover {
      border: 2px dashed white;
    }
  }
  .tooltip {
    white-space: nowrap;
    display: none;
    .tooltip-inner {
      max-width: 500px;
    }
    &.show {
      display: block;
    }
  }
  &.deleting {
    .--image-block {
      background-color: #f1a165;
      background-image: -moz-linear-gradient(top, #f1a165, #f36d0a);
      background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #f1a165), color-stop(1, #f36d0a));
      background-image: -webkit-linear-gradient(#f1a165, #f36d0a);

      &:after {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;
        bottom: 0;
        right: 5px;
        background-image: linear-gradient(
          -45deg,
          rgba(255, 255, 255, 0.2) 25%,
          transparent 25%,
          transparent 50%,
          rgba(255, 255, 255, 0.2) 50%,
          rgba(255, 255, 255, 0.2) 75%,
          transparent 75%,
          transparent
        );
        z-index: 1;
        background-size: 50px 50px;
        animation: moveDeleting 2s linear infinite;
        overflow: hidden;
      }
    }
  }
  .expander {
    min-height: 28px;
  }
  .adding-button {
    position: absolute;
    bottom: -15px;
    right: -15px;
    padding: 0;
    width: 30px;
    line-height: 28px;
    opacity: 0;
    border-radius: 50%;
    z-index: 9;
    transition: none;
  }
  .popupMenu {
    position: absolute;
    left: 100%;
    width: 165px;
    top: 0;
    margin-top: -16%;
    background: rgba(black, 0.9);
    z-index: 10;
    &.right {
      right: 0;
      left: auto;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        margin-bottom: 5px;
      }
      li:last-child {
        margin-bottom: 0;
      }
    }
    ul.submenuLevel1 {
      > li {
        width: 100%;
        display: block;
        position: relative;
        cursor: pointer;

        div {
          padding: 0.25rem 0;
          @include button-outline-variant($white, $black, $white);
        }
        > ul.submenuLevel2 {
          position: absolute;
          background: var(--gray-950);
          display: none;
          left: 100%;
          top: 0;
          min-width: 150px;
          &.right {
            left: auto;
            right: 100%;
          }
          > li {
            position: relative;
            ul.submenuLevel3 {
              position: absolute;
              background: var(--gray-950);
              display: none;
              left: 100%;
              top: 0;
              z-index: 1;
              &.right {
                left: auto;
                right: 100%;
              }
            }
            &:hover {
              > ul.submenuLevel3 {
                display: block;
              }
            }
          }
        }

        &:hover {
          > ul.submenuLevel2 {
            display: block;
          }
        }
      }
    }
  }
  .expander {
    width: 100%;
    text-align: left;
    padding: 0.25rem 0.25rem 0.25rem 0.5rem;
    &.--text {
      background: rgba($gray, 0.35);
      white-space: normal;
    }
    &.--image {
      background-color: rgba(91, 91, 91, 0.65);
    }
    &.--transition {
      background: rgba($gray, 0.35);
    }
    &.--script {
      background: rgba(105, 49, 49, 0.35);
    }
    &.--exampleGroups {
      background: rgba($exampleGroups-context, 0.35);
      &.global {
        background: rgba($exampleGroups-global, 0.45);
      }
      &.theme {
        background: rgba($exampleGroups-theme, 0.35);
      }
    }
    &.--pattern {
      background: rgba($orange, 0.35);
      &.global {
        background: rgba(255, 63, 68, 0.45);
      }
      &.theme {
        background: rgba(92, 255, 67, 0.35);
      }
    }
    &.--example {
      background: rgba($orange, 0.35);
      &.global {
        background: rgba(255, 63, 68, 0.45);
      }
      &.theme {
        background: rgba(92, 255, 67, 0.35);
      }
    }
    &.--events {
      background: rgba($orange, 0.35);
      &.global {
        background: rgba(255, 63, 68, 0.45);
      }
      &.theme {
        background: rgba(92, 255, 67, 0.35);
      }
    }
    //&.--events{
    //  background: rgba(darken($pink, 20%), .35);
    //}
    &.--buttons {
      background: rgba($blue, 0.35);
      + .popupMenu {
        top: 89px;
        left: 89%;
      }
    }
    &.--condition {
      background: rgba($purple, 0.35);
    }
    &.--random {
      background: rgba($brand-warning, 0.35);
    }
    &.--add {
      background: rgba($brand-success, 0.35);
    }

    .danger-delete-button {
      opacity: 0;
    }
    &:hover {
      .danger-delete-button {
        opacity: 1;
      }
    }
  }
}

.addingMenu {
  position: absolute;
  width: 165px;
  background: rgba(black, 0.9);
  z-index: 10;
  &.right {
    right: 0;
    left: auto;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin-bottom: 5px;
    }
    li:last-child {
      margin-bottom: 0;
    }
  }
  ul.submenuLevel1 {
    > li {
      width: 100%;
      display: block;
      position: relative;
      cursor: pointer;

      div {
        padding: 0.25rem 0;
        @include button-outline-variant($white, $black, $white);
      }
      > ul.submenuLevel2 {
        position: absolute;
        background: var(--gray-950);
        display: none;
        left: 100%;
        top: 0;
        min-width: 150px;
        &.right {
          left: auto;
          right: 100%;
        }
        > li {
          position: relative;
          ul.submenuLevel3 {
            position: absolute;
            background: var(--gray-950);
            display: none;
            left: 100%;
            top: 0;
            z-index: 1;
            &.right {
              left: auto;
              right: 100%;
            }
          }
          &:hover {
            > ul.submenuLevel3 {
              display: block;
            }
          }
        }
      }

      &:hover {
        > ul.submenuLevel2 {
          display: block;
        }
      }
    }
  }
}

.node:hover {
  .adding-button {
    opacity: 1;
  }
}

.node-header,
.node-header--fake {
  cursor: all-scroll;
  background-color: black;
  //background: rgba(248,80,50,1);

  padding-left: 10px;
  text-transform: uppercase;

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  border-bottom: 1px solid #353535;

  padding-top: 3px;
  height: 23px;

  font-size: 10px;
  letter-spacing: 1px;

  color: white;
  font-weight: 600;
}

.connector {
  fill: none;
  stroke: #dadada;
  stroke-width: 2;
  -webkit-box-shadow: 6px 6px 28px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 6px 6px 28px -2px rgba(0, 0, 0, 0.75);
  box-shadow: 6px 6px 28px -2px rgba(0, 0, 0, 0.75);
  -webkit-filter: drop-shadow(-5px -5px 5px var(--gray-950));
  filter: drop-shadow(-5px -5px 5px var(--gray-950));
}

.connector-click-area {
  cursor: pointer;
  fill: none;
  stroke: transparent;
  stroke-width: 15;
}

.connector-click-area:hover + .connector,
.connector:hover {
  cursor: pointer;
  stroke-width: 4;
}

.connector.selected {
  stroke: #337ab7;
}

.trash-icon .trash-icon-trashcan {
  opacity: 0.8;
}

.trash-icon:hover {
  cursor: pointer;
}

.trash-icon:hover .trash-icon-bg {
  stroke: #337ab7;
  stroke-width: 2;
  opacity: 0.5;
}

.trash-icon:hover .trash-icon-trashcan {
  opacity: 1;
}

.nodeInputWrapper {
  width: 65%;
  float: left;
}

.nodeOutputWrapper {
  width: 5%;
  float: right;
  text-align: right;
}

.nodeInputList,
.nodeOutputList {
  list-style: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.nodeOutputList li {
  cursor: pointer;
}

.nodeOutputList li a {
  font-size: 13px;
  text-decoration: none;
  background: transparent;
  box-sizing: border-box;
  color: #337ab7;
}

.nodeOutputList li a i {
  //padding-left: 6px;
}

.nodeOutputList li:hover a i {
  vertical-align: top;
  font-size: 15px;
  margin-top: 3px;
  margin-right: -2px;
  padding-left: 3px;
}

.nodeOutputList li:hover a {
  vertical-align: top;
  font-weight: bold;
}

.nodeInputList li a {
  cursor: default;
  font-size: 13px;
  color: var(--white);
  display: block;
  text-decoration: none;
  background: transparent;
  box-sizing: border-box;
}
.nodeInputList li a i {
  padding-right: 6px;
}

.dragging .nodeInputList li a i.hover {
  vertical-align: top;
  font-size: 15px;
  color: var(--white);
  margin-top: 2px;
  margin-left: -2px;
  padding-right: 3px;
}

.dragging .nodeInputList li a i.hover + span {
  vertical-align: top;
  font-weight: bold;
  color: var(--white);
}

.inputDot {
  width: 12px;
  height: 12px;
  display: inline-block;
  position: absolute;
  background: var(--gray-200);
  border-radius: 50%;
  top: 0;
  right: 3px;
  left: auto;
  border: 2px solid var(--white);
}

.EndPoint {
  fill: none;
  stroke: var(--gray-600);
  stroke-width: 2;
}

.pin-input {
  background: transparent;
  padding: 5px;
  width: 50px;
  display: inline-block;
  margin-left: 16px;
  border-color: var(--gray-900);
}

.node-menu {
  display: block;
  z-index: 10020;
}

.topEditorPanel {
  padding: 7px 15px;
  background: rgba(black, 0.9);
  z-index: 9;
  position: absolute;
  top: 0;
  left: 30%;
  color: var(--white);
  .window-element {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    margin-right: 5px;
    padding: 0 10px;
    $opacity: 0.7;
    &.screen {
      background: rgba(29, 29, 29, $opacity);
    }
    &.InputText {
      background: rgba(#2f5e7f, $opacity);
    }
    &.InputNumber {
      background: rgba(#7f4640, $opacity);
    }
    &.Switch {
      background: rgba(#7f5377, $opacity);
    }
    &.Confirmation {
      background: rgba(#4c7f47, $opacity);
    }
  }
}

.danger-delete-button {
  position: absolute;
  font-size: 16px;
  top: -5px;
  left: -3px;
  color: var(--danger);
  cursor: pointer;
}

.oneCircleComponent {
  .nodeOutputList {
    position: relative;
    margin-bottom: 0;
    li {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
.nowrapRow {
  white-space: nowrap;
}
.thumb-horizontal {
  cursor: pointer;
  background: rgba($brand-primary, 0.7);
}
.buttonItem {
  width: 95%;
  margin: 5px auto 2px !important;
  &.editing {
    background: none !important;
  }
  .danger-delete-button {
    display: none;
  }
  &:hover {
    .danger-delete-button {
      display: block;
    }
  }
  &.jsonButton {
    @include button-variant($white, $teal, darken($teal, 15%));
  }
  input {
    outline: none;
    border: none;
    background: none;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    color: var(--white);
  }
  button {
    line-height: 1;
  }
}

.randomItem {
  .danger-delete-button {
    display: none;
  }
  &:hover {
    .danger-delete-button {
      display: block;
    }
  }
}
.contextEdit {
  width: 100%;
  height: 100px;
  background: none !important;
  border: 1px solid $brand-primary;
  color: var(--white) !important;
  outline: none;
}
.button-success-text-save {
  background: var(--success);
  color: var(--white);
  cursor: pointer;
  text-align: center;
  font-size: 20px;
  padding-left: 5px;
  padding-right: 5px;
  min-width: 25px;
}

.buttonGroupOutput {
  .nodeOutputList {
    padding: 5px 0 0;
    li {
      height: 32px;
      margin-bottom: 5px;
      padding-top: 5px;
      a {
        margin-top: 7px;
      }
    }
  }
}

.borderSelected {
  border: 0;
  background-image: url(../../img/selection.gif);
  > div {
    margin: 1px;
    background-color: black;
  }
}

.patternHr {
  margin-top: 5px;
  margin-bottom: 0;
  border: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}

.btn-outline-exampleGroups-context {
  @include button-outline-variant($exampleGroups-context, $gray-dark);
}
.btn-outline-exampleGroups-global {
  @include button-outline-variant($exampleGroups-global, $white);
}

.btn-outline-context-pattern {
  @include button-outline-variant($orange, $gray-dark);
}
.btn-outline-theme-pattern {
  @include button-outline-variant(rgb(92, 255, 67), $gray-dark);
}
.btn-outline-purple {
  @include button-outline-variant($purple, $white, lighten($purple, 50%));
}
.btn-outline-event {
  @include button-outline-variant(darken($pink, 20%), $white, lighten($pink, 10%));
}
.btn-outline-systemButton {
  @include button-outline-variant($teal, $white, $teal);
}
.btn-outline-script {
  @include button-outline-variant(rgb(105, 49, 49), $white, lighten(rgb(105, 49, 49), 30%));
}
.btn-outline-image {
  @include button-outline-variant(rgb(204, 204, 0), $white, lighten(rgb(204, 204, 0), 30%));
}
.btn-outline-global-pattern {
  @include button-outline-variant(rgb(255, 63, 68), $white, lighten(rgb(255, 63, 68), 10%));
}
