.LeftSideBar {
  height: 100%;
  display: flex;
  flex-flow: row nowrap;

  & .menuPanel {
    display: flex;
    flex-flow: column nowrap;
    gap: 8px;
    padding: 4px;

    background: var(--white);

    & :global(.btn) {
      padding: 8px !important;
      border: none !important;

      &:global(.active) {
        background: var(--indigo-600) !important;
        & :global(.justui__icon-wrapper) {
          color: var(--white) !important;
        }
      }

      & :global(.justui__icon-wrapper) {
        display: flex;
        width: 16px;
        height: 16px;
        color: var(--gray-600) !important;
      }
    }
  }

  .detailPanel {
    background: var(--white);
    border-left: 1px solid var(--gray-200);
    border-top: 1px solid var(--gray-200);
  }
}
