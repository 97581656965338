.faqPage {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  height: 100%;

  &__content {
    position: relative;
    flex: 1 1 auto;
    min-height: 0;
    display: flex;
    height: auto;
  }

  &__sidebar {
    position: relative;
    flex: 1;
    background-color: var(--gray-800);
    min-width: 334px;
  }

  &__detailPanel {
    position: relative;
    flex: 4;
  }

  &__notification {
    width: 100%;
    padding: 8px;
    background-color: var(--yellow-100);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    button:first-of-type {
      margin-left: 8px;
    }
  }
}
