@mixin green() {
  margin: 0.5rem;
  color: #215433;
  background-color: #C0E7CE;
  border-radius: 2px;

  padding: 4px 8px !important;
  line-height: 16px;

  font-size: 0.75rem;
}
@mixin blue() {
  background-color: #2289AF;
  margin: 0.5rem 0.25rem;
  color: var(--white);
}

@mixin formContainer() {
  margin-top: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  & > span:first-child {
    margin-bottom: 4px;
    font-size: 12px;
    color: var(--gray);
  }

  & > div:last-child {
    width: 100%;
    min-height: 41px;
    padding: 0rem 3px 0rem 1rem;

    border: 1px solid var(--gray-200);
    border-radius: 2px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background-color: var(--gray-100);

    & > * {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;

      &:last-child {
        justify-content: flex-end;
      }
    }
  }
}


.Message_Checkbox_container {
  display: grid;
  grid-template-columns: 14px auto;
  grid-template-rows: min-content;
  gap: 10px;
  padding-left: 24px;
}
.Message_labels_checkbox_container {
  height: 100%;
  width: 14px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 24px;
  & > * {
    position: relative;
    top: 1px;
  }
  & ~ * {
    padding-left: 0 !important;
  }
}
.Message_container {
  position: relative;
  padding: 24px;
  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: auto auto;
  gap: 12px;
  border-bottom: 1px solid var(--gray-200);

  .Message_withMassLabels {

  }

  &:first-child {
    border-top: 1px solid var(--gray-200);
  }

  .Message_form_buttons {
    width: 100%;
    padding-top: 0.75rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & > div:first-child {
      & > button {
        position: relative;
        border-width: 0;
        padding: 4px 12px;

        &:not(:last-child):after {
          content: '';
          position: absolute;
          right: -0.5px;
          top: 3px;
          height: 24px;
          width: 1px;
          background-color: var(--gray-200);
        }
      }
    }
  }


  .Message_pattern_class {
    grid-column: 1/1;
    grid-row: 1/1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    margin-bottom: 4px;

    * {
      font-size: 0.75rem;
    }

    & > * > *:first-child {
      margin-right: 4px;
    }
  }

  .Message_time {
    margin-bottom: 4px;
    grid-column: 2/2;
    grid-row: 1/1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    font-size: 0.75rem;
    gap: 8px;

    &_splitter {
      height: 14px;
      width: 1px;
      background-color: #ccc;
    }
  }

  .Message_client {
    grid-column: 1/-1;

    & > * {
      width: 100%;
    }
  }

  .Message_bot {
    grid-column: 1/-1;
    padding-right: 5rem;
  }

  .Message_form {
    margin-top: 4px;
    grid-column: 1/-1;
    border-top: 1px solid var(--gray-200);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    & > *:first-child > &:not(:last-child) {
      border-right: 1px solid var(--gray);
    }
  }

  .Message_client, .Message_bot {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &.Message_container_noMarkup {
    .Message_time {
      grid-column: 1/3;
      grid-row: 1/1;
      justify-self: start;
    }
  }
  .Message_markup {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .Message_label {
    color: var(--gray);
  }

  .Message_pattern {
    max-width: 15rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .Message_form_item {
    &_container {
      @include formContainer();
    }

    &_labels_short {
      width: 100%;
      padding: 8px !important;

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      & > div {
        gap: 8px !important;
        .Message_form_green {
          @include green();
          margin: 0 !important;
          background-color: #C0E7CE;
          border-color: #C0E7CE;
          color: #215433;
        }
      }

      background-color: var(--gray-100);
    }

    &_green, &_blue {
      @include green();
    }

    &_blue {
      @include blue();
    }
  }
}

.MessageForm_labelsGroups {
  display: grid;
  gap: 8px;
  max-width: 100%;

  label {
    width: 100%;
    color: #4d4d4d;
    margin: 0 !important;
  }

  & > div {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 8px;
    & > * {
      display: flex;
      flex-shrink: 0;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      &:not(input) > *:first-child {
        margin-right: 0.25rem;
      }
    }
    &:not(:last-child) {
      margin-bottom: 8px;
    }

    button {
      padding: 3px 8px !important;
      line-height: 16px;

      &.Message_label_button {
        background-color: var(--gray-100);
        color: var(--gray);

        &.btn-success {
          background-color: #C0E7CE;
          border-color: #C0E7CE;
          color: #215433;
        }
      }
    }

    & > input {
      max-width: 15rem;
      height: 30px;
      position: relative;

      &:after {
        content: 'Enter';
        position: absolute;
        top: 0;
        right: 0.5rem;
        line-height: 30px;
      }
    }
  }
}

.Message_audios_answers {
  margin-top: 12px;
  margin-left: 4rem;
  margin-bottom: 20px;
  padding: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  gap: 1rem;

  width: calc(100% - 4rem);
  background: var(--gray-100);

}

.Message_audio_head {
  display: grid;
  grid-template-columns: 4rem minmax(0, 1rem) auto;
  & > * {
    display: block;
  }
  & > span {
    color: #000 !important;
  }
  & > svg {
    margin-top: 0.125rem;
  }
  & > *:first-child {
    width: 4rem;
    display: block;
  }

  & > *:last-child {
    margin-left: 0.25rem;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: keep-all;
  }
}

.Message_answer_question {
  margin-top: 0.75rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  & > *:first-child {
    width: 4rem;
  }

  &_type-raw {
    word-break: break-all
  }
}

.Message_openInNewTab {
  position: absolute;
  bottom: 24px;
  right: 24px;
  font-size: 0.75rem;
  text-decoration: none;
  align-self: end;
  & > *:first-child {
    font-size: 0.75rem;
    &:hover, &:focus {
      text-decoration: underline;
    }
  }
  svg {
    margin-left: 0.25rem;
    height: 12px;
    width: 12px;
    transform: scale(1);
    transition: 200ms;
    &:hover, &:focus  {
      transform: scale(1.2);
    }
  }
}
