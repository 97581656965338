.StepperControl {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  gap: 8px;
  width: 100%;

  &__item {
    height: 8px;
    width: 16px;
    border-radius: 2px;
    background: var(--gray-100);
    cursor: pointer;

    &_active {
      cursor: auto;
      background: var(--primary);
    }
  }
}
