.justui_badge {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 2px 4px;
  border-radius: 2px;
  line-height: 16px;
  font-size: 12px;
  white-space: nowrap;

  .iconLeft {
    margin-right: 0.25rem;
  }

  .iconRight {
    margin-left: 0.25rem;
  }

  .justui__icon-wrapper {
    display: flex;
    align-items: center;
  }
}
