.justui_toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.justui_toolbar__vertical {
  height: 100%;
  display: inline-flex;
  flex-direction: column;
}

.justui_toolbar-left {
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  margin-right: auto;

}

.justui_toolbar-right {
  display: flex;
  flex-shrink: 0;
  margin-left: auto;
  justify-self: flex-end;
  justify-content: flex-end;
}

.justui_toolbar-top {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: flex-start;
}

.justui_toolbar-bottom {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-top: auto;
  justify-self: flex-end;
  justify-content: flex-end;
}

.justui_toolbar {
  font-size: 12px;
  box-shadow: 0 1px 0 #273238;

  .justui_toolbar_section {
    display: flex;
    align-items: center;
    border-left: 1px solid #273238;
    padding: 0 8px;

  }

  .justui_toolbar_section-name {
    padding-left: 8px;
    padding-right: 8px;
    vertical-align: middle;
    color: #CFD8DC;
  }

  .button-box {
    display: flex;
    padding: 0 8px;
  }

  .toolbar-btn {
    box-shadow: none;
  }

  .btn:disabled {
    background-color: unset;
    color: #666666;

    svg {
      color: #666666 !important;
    }
  }

  .justui_toolbar-left {
    display: flex;
    align-items: center;

    .justui_toolbar_mode-buttons .btn.active {
      background-color: #02a7f0;
    }

    .justui_toolbar_file-name {
      margin-left: 9px;
      color: #CFD8DC;
    }
  }
}

