.JGraphCailaIntentChooseCreation{
  display: flex;
  flex-grow: 1;
  gap: 12px;
  align-items: center;

  button{
    display: flex;
    flex-grow: 1;
    flex-basis: 50%;
    border-style: dashed;
    gap: 6px;

    .heightFix {
      height: 16px;
      line-height: 16px;
    }

    &.buttonCollapse{
      flex-grow: unset;
      flex-basis: auto;
      padding-left: 10px;
      padding-right: 10px;
      border-style: solid;
    }
  }

  &.expanded{
    flex-basis: 90%;
  }

  [class*='just-select-container-open']{
    display: none;
  }

  .splitter{
    height: 16px;
    width: 1px;
    background-color: var(--gray-200, #E0E1E3);
  }

  .faEnterSymbol{
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 12px;
    z-index: 551;
  }
}

.JGraphCailaIntentChooseCreationHelper{
  display: inline-block;
  position: relative;


  &.isShown{
    .helperPopup{
      display: flex;
    }
  }

  .pseudoLink{
    font-size: 12px;
  }

  .helperPopup{
    flex-direction: column;
    padding: 8px 12px;
    gap: 8px;
    display: none;
    background: #FFFFFF;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 2px 8px rgba(12, 43, 66, 0.12), 0 0 1px rgba(17, 60, 92, 0.06);
    border-radius: 8px;
    position: absolute;
    z-index: 1000;
    .line{
      color: var(--gray-600);
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      letter-spacing: 0.01em;
      gap: 4px;
      white-space: nowrap;

    }

  }
}
