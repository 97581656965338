.justui_window {
  display: grid;
  grid-template-rows: min-content minmax(0, 1fr);
  grid-template-areas:
    "titlebar"
    "content";

  &.reverse {
    grid-template-rows: minmax(0, 1fr) min-content;
    grid-template-areas:
      "content"
      "titlebar";
  }

  outline-width: thin;
  outline-color: #1F282D;
  outline-style: solid;

  .justui_window__title-bar {
    grid-area: titlebar;
    padding-left: 9px;
    min-height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .justui_window__title {
      font-weight: bold;
    }

    .justui_window__buttons .btn {
      color: black;
    }
  }

  .justui_window__content {
    grid-area: content;
  }

  &.dark {
    background-color: var(--grey-900);
    color: var(--grey-100);
    .justui_window__title-bar .justui_window__buttons .btn {
      color: var(--grey-100);;
    }
  }
}

