.account-selector {
  display: table-row;

  &--selector {
    display: table-cell;
    vertical-align: middle;
    min-width: 120px;

    .Select-control {
      border-radius: 0;
      .Select-placeholder,
      .Select--single > .Select-control .Select-value,
      .Select-input {
        height: 30px;
        line-height: 30px;
      }
    }
  }

  &--logout {
    vertical-align: middle;
    display: table-cell;
    height: 36px;
  }
}
