.faq-template-card {
  box-sizing: border-box;
  border-radius: 4px;
  padding: 22px;
  border: 1px solid var(--gray-200);
  height: 136px;
  max-width: 275px;
  width: 100%;

  display: grid;
  grid-auto-rows: max-content;
  grid-template-areas:
    'icon'
    'title'
    'description';
  cursor: pointer;
  transition: color 0.2s, background-color 0.2s, border-color 0.2s;

  &__icon {
    grid-area: icon;
    margin-bottom: 7px;
  }

  &__title {
    grid-area: title;
    display: flex;
    flex-direction: column;

    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: var(--gray-950);
  }

  &__description {
    display: flex;
    grid-area: description;
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-800);
  }

  &__examples-link {
    justify-self: baseline;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    color: var(--jaicp-600);
    &:hover {
      color: var(--jaicp-700);
    }
  }

  &:hover,
  &--active {
    border: 2px solid var(--primary);
    padding: 21px;
    .faq-template-card__icon,
    .faq-template-card__title {
      color: var(--primary-950);
    }
    .faq-template-card__description {
      color: var(--primary-900);
    }
  }

  &--active {
    background-color: var(--primary-100);
  }
  &--disabled {
    pointer-events: none;
    background-color: var(--grey-200);
    h4 {
      color: var(--grey-500);
    }
    :global(.justui__icon-wrapper) {
      color: var(--grey-500);
    }
  }
}

.faqAddSkill__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  > div {
    display: flex;
    cursor: pointer;
    align-items: center;
    gap: 8px;
    color: var(--grey);
    margin-left: 12px;
  }
}

.faqQuestionsModal {
  li {
    margin-bottom: 8px;
  }
}

.empty-template {
  ul {
    padding-left: 0;
  }
}
