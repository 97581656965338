.intents-tree-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .tree-content {
    flex: 1;
    overflow: hidden;
  }
}

.intents-tree .tree-link__leaf {
  flex: 1;
  justify-content: space-between;
  width: 100%;
  padding-left: 10px;
  &.selected {
    .intents-tree__counter {
      display: none;
    }
    .intents-tree__icon {
      display: block;
    }
  }

  &.disabled {
    color: var(--gray-400);
  }

  .intents-tree__name {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
  }

  .intents-tree__counter {
    display: block;
    line-height: 12px;
    padding-left: 3px;
  }

  .intents-tree__icon {
    display: none;
    margin-right: -2px;
  }

  &:hover {
    .intents-tree__counter {
      display: none;
    }
    .intents-tree__icon {
      display: block;
    }
  }

  .intents-tree__dropdown-toggle {
    padding-left: 18px;
    padding-right: 18px;
    color: var(--gray-200);
    &.selected {
      color: var(--white);
    }
  }
  &.tree-link__leaf-light {
    border-bottom-width: 0 !important;
  }

  &.white {
    .intents-tree__name {
      color: var(--gray-700);
    }
    .intents-tree__dropdown-toggle {
      .intents-tree__icon,
      .intents-tree__counter {
        color: var(--gray-700);
      }
      &.selected {
        color: var(--white);
      }
    }
    &:hover {
      color: #929290;
      .intents-tree__name {
        color: #929290;
      }
      .intents-tree__dropdown-toggle {
        .intents-tree__icon,
        .intents-tree__counter {
          color: #929290;
        }
      }
    }
    &.disabled {
      .intents-tree__name {
        color: var(--gray-400);
      }
    }
  }
}
