.colorsDropdown {
  background: var(--gray-100);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 6px 12px rgba(12, 43, 66, 0.16), 0 0 1px rgba(17, 60, 92, 0.06);
  border-radius: 8px;
  padding: 8px;

  display: grid;
  gap: 5px;
  grid-template-columns: repeat(4, 32px);

  & > * {
    align-self: center;
    justify-self: center;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
  }

  .selectedOption {
    background-color: var(--yellow-200) !important;
    border-radius: 9px;
  }
}
