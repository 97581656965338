.tabs-collapsible__wrapper {
  display: flex;
  .nav .scrollbar-container {
    padding: 0;
  }
  .nav-item {
    a {
      color: var(--gray-950) !important;
    }
    &.disabled a {
      color: var(--gray-600) !important;
    }
  }
}

.justui_tabs-tab-container {
  &.hidden {
    opacity: 0;
  }
}

.justui_tabs-scrollable.nav {
  flex-wrap: nowrap;
  width: 100%;
  white-space: nowrap;
  cursor: pointer;

  .scrollbar-container {
    flex-grow: 1;
    display: flex;
    padding-bottom: 10px;
  }

  .ps__rail-x {
    height: 0;
  }

  .ps__thumb-x {
    height: 6px !important;
  }

  &.justui_tabs-fullwidth {
    .justui_tabs-tab-container {
      flex-grow: 1;
      flex-basis: 0;
      min-width: 100px;
    }
  }
  &.justui_tabs-compact {
    .justui_dropdown-toggle {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.faq-tabs-dropdown-menu {
  max-height: 200px;
  overflow: auto;
  .dropdown-item.disabled {
    cursor: pointer !important;
  }
  &::-webkit-scrollbar {
    position: relative;
    width: 16px;
    background: white;
    padding-top: 8px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    margin-right: -8px;
  }

  &::-webkit-scrollbar-track {
    margin-top: 8px;
    margin-bottom: 8px;
    width: 16px;
  }

  &::-webkit-scrollbar-thumb {
    min-height: 50px;
    background-clip: padding-box;
    border-right: 5px solid transparent;
    border-top: 0 solid transparent;
    border-bottom: 0 solid transparent;
    border-left: 5px solid transparent;
    border-radius: 6px;
    background-color: var(--jaicp-200);
    cursor: pointer;
  }
}

.faq-tab-name-input__wrapper {
  display: flex;
  align-items: center;
  max-width: 100%;
  p {
    margin-bottom: 0;
    font-weight: 700;
  }
  input {
    overflow: auto;
    border: none;
    font-weight: 700;
    &:focus-visible {
      border: none;
      box-shadow: none;
      outline: none;
    }
  }
}
