.refresh-clientslog {
  .hover {
    display: none;
  }
  &:hover {
    .unhover {
      display: none;
    }
    .hover {
      display: inline-block;
    }
  }
}
