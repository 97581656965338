.sales-request-modal {
  &_description {
    padding: 12px 15px;
    display: flex;
    ul {
      padding: 0;
      margin: 0;
      padding-left: 23px;
    }
    & > div {
      padding-left: 8px;
    }
  }
  &_form {
    overflow: hidden;

    label {
      margin: 0;
      line-height: 38px;
    }
  }
}
