.radioPads {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  color: var(--gray-950);

  & .pad {
    padding: 16px;
    margin: 1px;
    border: 1px solid var(--gray-200);
    border-radius: 8px;
    box-sizing: border-box;
    cursor: default;

    &.active {
      margin: 0;
      background: var(--indigo-100);
      border: 2px solid var(--indigo-600);
    }
    &.disabled {
      margin: 1px;
      border: 1px solid var(--gray-200);
      cursor: not-allowed;
      color: var(--gray-500);
    }

    &:not(.disabled) {
      &:hover {
        margin: 0;
        border: 2px solid var(--indigo-600);
      }
      &:focus-within {
        outline: var(--indigo-400) solid 1px;
      }
      &:active {
        margin: 0;
        border: 2px solid var(--indigo-700);
      }
    }
  }

  & :global(input[type='radio']) {
    appearance: none;
  }
}
