.main {
  &.dark-theme {
    background-color: #304047;
    border-left: 1px solid #1c2429;

    .card {
      background-color: var(--gray-dark);
      border: 1px solid #1c2429;

      .card-footer {
        background-color: rgba(0, 0, 0, 0.2);
        border-top: 1px solid #1c2429;
      }
    }

    .ace-monokai {
      background: rgba(0, 0, 0, 0.1);

      .ace_gutter {
        background-color: rgba(255, 255, 255, 0.05);
      }

      .ace_marker-layer .ace_active-line {
        background: rgba(0, 0, 0, 0.2);
      }
    }

    .editor {
      flex-wrap: nowrap;
      .editor-tree {
        border-right: 1px solid #1c2429;

        ul {
          &::-webkit-scrollbar-track {
            background-color: #304047;
            border-right: 1px solid #1c2429;
            border-left: 1px solid #1c2429;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #11171a;
          }
        }
      }

      .editor-text {
        .ace_scrollbar {
          &.ace_scrollbar-v {
            &::-webkit-scrollbar-track {
              background-color: #304047;
              border-left: 1px solid #1c2429;
              border-right: 1px solid #1c2429;
            }

            &::-webkit-scrollbar-thumb {
              background-color: #11171a;
            }
          }

          &.ace_scrollbar-h {
            &::-webkit-scrollbar-track {
              background-color: #304047;
              border-bottom: 1px solid #1c2429;
              border-top: 1px solid #1c2429;
            }

            &::-webkit-scrollbar-thumb {
              background-color: #11171a;
            }
          }
        }
      }
    }
  }

  .ace-kuroir {
    background: transparent;
  }

  .ace_gutter-cell.ace_error {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAABOFBMVEX/////////QRswFAb/Ui4wFAYwFAYwFAaWGAfDRymzOSH/PxswFAb/SiUwFAYwFAbUPRvjQiDllog5HhHdRybsTi3/Tyv9Tir+Syj/UC3////XurebMBIwFAb/RSHbPx/gUzfdwL3kzMivKBAwFAbbvbnhPx66NhowFAYwFAaZJg8wFAaxKBDZurf/RB6mMxb/SCMwFAYwFAbxQB3+RB4wFAb/Qhy4Oh+4QifbNRcwFAYwFAYwFAb/QRzdNhgwFAYwFAbav7v/Uy7oaE68MBK5LxLewr/r2NXewLswFAaxJw4wFAbkPRy2PyYwFAaxKhLm1tMwFAazPiQwFAaUGAb/QBrfOx3bvrv/VC/maE4wFAbRPBq6MRO8Qynew8Dp2tjfwb0wFAbx6eju5+by6uns4uH9/f36+vr/GkHjAAAAYnRSTlMAGt+64rnWu/bo8eAA4InH3+DwoN7j4eLi4xP99Nfg4+b+/u9B/eDs1MD1mO7+4PHg2MXa347g7vDizMLN4eG+Pv7i5evs/v79yu7S3/DV7/498Yv24eH+4ufQ3Ozu/v7+y13sRqwAAADLSURBVHjaZc/XDsFgGIBhtDrshlitmk2IrbHFqL2pvXf/+78DPokj7+Fz9qpU/9UXJIlhmPaTaQ6QPaz0mm+5gwkgovcV6GZzd5JtCQwgsxoHOvJO15kleRLAnMgHFIESUEPmawB9ngmelTtipwwfASilxOLyiV5UVUyVAfbG0cCPHig+GBkzAENHS0AstVF6bacZIOzgLmxsHbt2OecNgJC83JERmePUYq8ARGkJx6XtFsdddBQgZE2nPR6CICZhawjA4Fb/chv+399kfR+MMMDGOQAAAABJRU5ErkJggg==)!important;
    background-repeat: no-repeat!important;
    background-position: 2px center!important;
  }

  .ace_marker-layer {
    .ace_format_error-marker {
      position: absolute;
      border-bottom: 2px dashed red;
      z-index: 9;
    }
  }
}

.contextMenu {
  position: fixed;
  background: white;
  box-shadow: 0 2px 10px var(--gray-500);
  z-index: 1000;
  &--option {
    padding: 6px 50px 5px 10px;
    min-width: 160px;
    cursor: default;
    font-size: 12px;
    &.--delete {
      background: rgba(255, 72, 80, 0.55);
      color: #313131;
      &:hover {
        background: linear-gradient(to top, rgb(255, 72, 80), rgb(155, 44, 48));
        color: #ebebeb;
      }
    }
    &:hover {
      background: linear-gradient(to top, #555, #333);
      color: white;
    }

    &:active {
      color: #e9e9e9;
      background: linear-gradient(to top, #555, #444);
    }

    &.disabled {
      color: var(--gray-500);
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  &--separator {
    width: 100%;
    height: 1px;
    background: var(--gray-200);
    margin: 0 0 0 0;
  }
}

.editor-page {
  margin: 0 !important;
  height: 100%;
  .card {
    margin-bottom: 0;
    height: 100%;
    border-radius: 0;
  }
}

.editor-tabs {
  border-bottom: none;
  .nav-item {
    margin-top: -1px;
    margin-bottom: 0;
    a {
      color: rgba(#fff, 0.5);
      background: var(--gray-800);
      border-color: rgba(#263238, 0.5);
      border-top-color: transparent;

      &.active,
      &:hover {
        color: var(--white);
        background: var(--gray-800);
        border-color: rgba(#263238, 0.5);
        border-top-color: transparent;
      }
    }
  }
}

.editor-tab {
  margin-top: 0;
  background: none;
  border: none;
  flex: 1;
  .tab-pane {
    padding: 0;
    height: 100%;
  }
}

.visual-editor-wrapper {
  height: 100%;
  & > div {
    height: 100%;
    & > div {
      height: 100%;
      & > div {
        height: 100%;
        & > div {
          height: 100%;
        }
      }
    }
  }
}

.editor {
  height: 100%;
  margin: 0;
  .editor-tree {
    position: relative;
    padding-right: 0;
    border-right: 1px solid var(--gray-300);
    margin-left: 15px;
    .changeWidth {
      position: absolute;
      right: -2px;
      height: 100%;
      width: 4px;
      top: 0;
      background: rgba(#fff, 0.5);
      cursor: w-resize;
      z-index: 10;
      &:hover {
        box-shadow: 0 0 1px 1px rgba(#fff, 0.9);
      }
    }
    ul {
      &::-webkit-scrollbar {
        position: absolute;
        width: 10px;
        margin-left: -10px;
        -webkit-appearance: none;
      }

      &::-webkit-scrollbar-track {
        background-color: #e4e5e6;
        border-right: 0 none;
        border-left: 1px solid var(--gray-300);
      }

      &::-webkit-scrollbar-thumb {
        height: 50px;
        background-color: #a0a1a2;
        background-clip: content-box;
        border-color: transparent;
        border-style: solid;
        border-width: 1px 2px;
      }
    }
  }

  .editor-text {
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100%;

    .ace_scrollbar {
      &.ace_scrollbar-v {
        &::-webkit-scrollbar {
          position: absolute;
          width: 10px;
          margin-left: -10px;
          -webkit-appearance: none;
        }

        &::-webkit-scrollbar-track {
          background-color: #e4e5e6;
          border-left: 1px solid var(--gray-300);
          border-right: 1px solid var(--gray-300);
        }

        &::-webkit-scrollbar-thumb {
          height: 50px;
          background-color: #a0a1a2;
          background-clip: content-box;
          border-color: transparent;
          border-style: solid;
          border-width: 1px 2px;
        }
      }
      &.ace_scrollbar-h {
        &::-webkit-scrollbar {
          position: absolute;
          height: 8px;
          -webkit-appearance: none;
        }

        &::-webkit-scrollbar-track {
          background-color: #e4e5e6;
          border-bottom: 1px solid var(--gray-300);
          border-top: 1px solid var(--gray-300);
        }

        &::-webkit-scrollbar-thumb {
          width: 50px;
          background-color: #a0a1a2;
          background-clip: content-box;
          border-color: transparent;
          border-style: solid;
          border-width: 1px 2px;
        }
      }
    }
  }
}

.bottom-panel {
  margin: 0;
}

.editor-test-widget .justwidget.sided {
  bottom: 40px !important;
}

.editor-test-widget .justwidget--close {
  display: none !important;
}

.test-widget-button {
  margin-right: 15px;
}

.file_search {
  position: absolute;
  left: 0;
  bottom: 37px;
  background: var(--gray-800);
  border-top: 1px solid #1e2019;
  border-bottom: 1px solid #1e2019;
  width: 46px;
  z-index: 9;

  .notFound {
    box-shadow: inset 0 0 40px red;
    button {
      background: transparent;
    }
  }

  input[type='text'] {
    border: none;
    outline: none;
    box-shadow: none;
    border-radius: 0;
    background: transparent;
    color: var(--white);
    padding-left: 0;
  }

  .btn {
    text-align: center;
    color: var(--white);
    width: 100%;
    background: var(--gray-800);
    padding: 6px 8px;
    &:hover {
      background: #0c5480;
    }

    &.close {
      padding: 6px 0;
    }
  }

  .variants {
    margin-top: 1px;

    svg {
      display: block;
      width: 16px;
      height: 16px;
    }

    button {
      border: none;
      border-radius: 0 !important;

      &.active {
        background: rgba(0, 0, 0, 0.3);
        svg path {
          fill: #ffbe34;
        }
      }
    }
  }

  .input-group-append,
  .input-group-prepend {
    background: transparent;
    border-radius: 0 !important;
    border-color: transparent;
    text-align: center;
    padding: 6px 12px;
    display: flex;
    align-items: center;
    color: var(--primary-500);
    i {
      color: var(--white);

      &.fa-exclamation-triangle {
        color: red;
      }
    }

    &.end {
      padding: 0;
      button {
        border: none;
      }
    }
  }

  .col-auto {
    &.end {
      padding-right: 30px;
    }
  }
}

.editorTab {
  .ace-monokai .ace_marker-layer .ace_selection {
    &.simple {
      background: #508a45;
    }
    &.orangeMarker {
      background: #7b7425;
      box-shadow: 0 0 1px 0 #ff1300;
    }
  }

  .ace-kuroir {
    .ace_marker-layer .ace_selection {
      &.orangeMarker {
        background: rgba(39, 45, 123, 0.49);
        box-shadow: 0 0 1px 0 #0010ff;
      }
    }
  }
}

@media (max-height: 700px) {
  .editor-test-widget .justwidget.sided {
    max-height: calc(100vh - 130px);
  }
}
