.MenuItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 10px 13px;
  width: 100%;
  font-size: 14px;

  &:hover {
    cursor: pointer;
    background: var(--gray-200);
  }

  &__text {
    white-space: nowrap;
    color: var(--gray-800);
  }

  &__menu {
    padding-bottom: 0.5rem !important;
  }

  &__arrowIcon svg {
    color: var(--gray-500);
  }
}
