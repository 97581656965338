.markup {
  .comment {
    padding: 0.2rem;
    height: 38px;
    &.editing {
      border-color: $brand-warning;
    }
    &.pending {
      border-color: $brand-primary;
    }
    &.saved {
      border-color: var(--success);
    }
    &.error {
      border-color: var(--danger);
    }
  }
  .input-group {
    .input-group-addon,
    .input-group-append {
      min-width: 30px;
      padding: 0.5rem;
      &:not(.default) {
        color: var(--white);
      }
      &.warning {
        background: $brand-warning;
      }
      &.primary {
        background: $brand-primary;
      }
      &.success {
        background: var(--success);
      }
      &.danger {
        background: var(--danger);
      }
    }
  }
  .groups-select {
    width: 100%;
    .Select-menu-outer {
      z-index: 999;
      .Select-option.is-disabled {
        cursor: default;
        padding-left: 0.5rem;
        font-weight: bold;
        color: #1f1f1f;
      }
      .Select-option:not(.is-disabled) {
        padding-left: 0.75rem;
      }
    }
  }
  .select_with_spinner {
    &.editing {
      > div {
        border-color: $brand-warning;
      }
    }
    &.pending {
      > div {
        border-color: $brand-primary;
      }
    }
    &.saved {
      > div {
        border-color: var(--success);
      }
    }
    &.error {
      > div {
        border-color: var(--danger);
      }
    }
  }
}
