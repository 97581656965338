.ThemesInformerModal {
  .stepper {
    margin-top: 88px;
  }

  .stepWrapper {
    display: flex;
    flex-flow: column nowrap;
    gap: 24px;

    & img {
      width: 100%;
      min-height: 266px;
    }

    &__content {
      display: flex;
      flex-flow: column nowrap;
      gap: 8px;
      white-space: break-spaces;
    }
  }
}
