.CurrentGroupPath {
  display: flex !important;
  flex-flow: row nowrap;
  align-items: center;
  gap: 4px;
}

.pathSeparator {
  color: var(--gray-500);
}

.themeContainer {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 4px;

  &.active {
    color: var(--indigo-600);
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
