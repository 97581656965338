.ScreenBlocksListOpenAdder {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
  width: 100%;
  border-style: dashed !important;
  border-color: var(--gray-300, #c3c6c9) !important;
  border-radius: 4px !important;
}
