$table-border: #cfd8dc;
$table-caption: #607d8b;
$table-row-hover: #eceff1;

.ucb {
  &-credit-list {
    border: 1px solid $table-border;
    margin: 0;
    padding: 0;
    background-color: var(--white);
    display: table;
    width: 100%;

    &--caption {
      background-color: $table-border;
      display: table-row;
      & > span {
        display: table-cell;
        padding: 0.75rem;
        vertical-align: top;
        color: $table-caption;
        font-weight: bold;
      }
    }

    &--item {
      display: table-row;

      & > span {
        display: table-cell;
        padding: 0.75rem;
        vertical-align: top;
        border-top: 1px solid $table-border;
      }

      &:hover {
        background-color: $table-row-hover;
      }
    }
  }
}
