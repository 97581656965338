.month-picker {
  &__wrapper {
    position: relative;
  }

  &__input-wrapper {
    position: relative;
  }

  &__input {
    padding-right: 30px;

    &[readonly] {
      background-color: $white;
    }
  }

  &__icon {
    position: absolute;
    right: 10px;
    top: 10px;
    color: $brand-primary;
  }

  &__picker {
    position: absolute;
    background-color: $white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    padding: 16px 16px 6px 16px;
    width: 250px;
    display: none;

    &_open {
      display: block;
    }
  }

  &__years {
    text-align: center;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__year--control {
    margin: 0 10px;
    cursor: pointer;
    &.disable {
      color: $gray-lighter;
      cursor: not-allowed;
    }
  }

  &__months {
    & > div {
      padding-right: 5px;
      padding-left: 5px;
      margin-bottom: 10px;

      &:nth-child(3n + 1) {
        padding-left: 15px;
      }
      &:nth-child(3n + 3) {
        padding-right: 15px;
      }
    }
  }

  &__month {
    cursor: pointer;
    font-size: 12px;
    border: 1px solid $gray-lighter;
    border-radius: 3px;
    color: $brand-primary;
    text-align: center;
    padding: 2px 3px;

    &--selected {
      background-color: $brand-primary;
      color: $white;
    }

    &.disabled {
      cursor: not-allowed;
      pointer-events: none;
      background-color: $gray-lighter;
      color: $white;
    }
  }
}
