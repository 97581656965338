.helpPopup {
  position: absolute;
  margin: 24px 16px;
  width: calc(100% - 32px);
  box-sizing: border-box;
  max-height: 214px;
  // max-width: 288px;
  border-radius: 6px;
  background-color: var(--blue-500);
  color: var(--gray-100);
  padding: 8px 12px;
  gap: 8px;
  transition: height 0.2s;
  bottom: 0;
  font-size: 12px;
  overflow-y: hidden;

  &__header {
    display: flex;
    position: relative;
    width: 100%;
    h4 {
      color: var(--gray-100);
      font-size: 12px;
      font-weight: 700;
    }
  }

  &__control {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    width: min-content;
    color: var(--gray-100);
    gap: 16px;
  }

  & .button {
    padding: 0;
    color: var(--gray-100);
    background-color: transparent;
    height: min-content;

    &:hover {
      color: var(--gray-200);
    }
    &:focus {
      box-shadow: none;
    }
    :global(.justui__icon-wrapper) {
      display: flex;
    }
  }

  &__content {
    margin-top: 8px;
    display: flex;
    transition: max-height 0.2s, margin 0.2s;
    max-height: inherit;
    line-height: 16px;

    ul {
      padding-left: 16px;
    }
    &.helpPopup__collapsed {
      max-height: 0;
      overflow: hidden;
      margin: 0;
    }
  }
}
