.PhrasesAddingToggle{
  display: inline-block;

  &.disabled{
    color: var(--primary-300);
    border-bottom-color: var(--primary-300);
  }
}

.tooltipCustomWidth{
  width: 238px !important;
  max-width: 238px!important;
}
