.AudioSettings {
  .collapse {
    background: var(--gray-100);

    &__title {
      color: var(--indigo-600);
      line-height: 16px;
      border-bottom: 1px dashed var(--indigo-600);
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
    }

    &__body {
      display: flex;
      flex-direction: column;
      gap: 8px;
      background: var(--gray-100);
      padding: 0 32px 16px 32px;
      font-size: 12px;
      color: var(--gray-800);

      & ul {
        padding-inline-start: 20px;
        font-size: 12px;
      }
      & p {
        font-size: 12px;
      }
    }
  }
}
