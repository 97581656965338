.Jgraph-container {
  background-color: var(--gray-200);
  color: black;
  position: absolute;
  width: 100%;
  height: 100%;
  user-select: none;

  &.Group {
    top: 0;
  }
  canvas {
    user-select: none;
  }

  & .sticker-mode-enabled {
    cursor: url('../../../img/jgraph/sticker.svg') 8 8, default;
  }

  .jgraph-toolbar-container {
    position: absolute;
    left: 0;
    top: 0;
    padding: 8px;
    pointer-events: none;

    .horizontal-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 8px;

      pointer-events: none !important;
      & > * {
        pointer-events: all;
      }
    }

    .vertical-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;

      pointer-events: none !important;
      & > * {
        pointer-events: all;
      }
    }
  }
}

.editor__main-toolbar {
  &.visual {
    background: #ffffff;
    align-items: center;
    height: 28px;
    z-index: 1;
    box-shadow: none;
    outline: none;

    .JGraphPath {
      color: var(--gray-800);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: stretch;
      gap: 4px;

      .btn:not(.JGraphPathButton) {
        font-size: 12px;
        padding: 0;
        align-items: center;
        justify-content: center;
        vertical-align: unset;
      }

      .JGraphPathButton {
        position: absolute;
        top: 36px;
        width: auto !important;
        border: 1px solid var(--primary);
        z-index: 10;
      }
    }

    .editorModeBtn {
      height: 24px;
      width: 26px;
      border: none;
      min-width: 24px;
      color: var(--primary) !important;
      &:active {
        outline: none;
      }
      &.active {
        background-color: #ffffff !important;
        &:not(:focus) {
          box-shadow: 0 0 1px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(12, 43, 66, 0.06), 0 0 1px rgba(17, 60, 92, 0.06);
        }
      }
    }

    .editor__main-toolbar_section {
      padding: 1px 4px;
      background-color: var(--gray-100, #f4f5f5);
      outline: var(--gray-200) solid thin;

      .justui__icon-wrapper {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
      }
    }

    .editor__main-toolbar_file-name {
      display: none;
    }

    .justui_toolbar-right {
      padding-right: 8px;
      svg {
        color: var(--gray-500, #888e93);
      }
    }
  }
}
