.phraseRow {
  .searchMark {
    display: inline-block;
    background: var(--primary-200);
    border-radius: 2px;
  }
}

.question-phrases-list {
  > div > div {
    &:first-child {
      > div {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      .phraseRow__part--prepend {
        border-top-left-radius: 4px;
        button {
          border-top-left-radius: 4px !important;
          border-right: none;
          border-color: var(--grey-200);
        }
      }
      .phraseRow__part--append {
        border-top-right-radius: 4px;
        button {
          border-top-right-radius: 4px !important;
          border-color: var(--grey-200);
          border-left: none;
        }
      }
    }
    &:last-child {
      > div {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      .phraseRow__part--prepend {
        border-bottom-left-radius: 4px;
        button {
          border-bottom-left-radius: 4px !important;
        }
      }
      .phraseRow__part--append {
        border-bottom-right-radius: 4px;
        button {
          border-bottom-right-radius: 4px !important;
        }
      }
    }
  }
  .edit-phrase-block {
    .textAreaLikeElement {
      transition: none;
      &:hover {
        z-index: 9;
      }
      &:focus-within {
        z-index: 10;
      }
    }
    .phraseRow__part--prepend {
      button {
        border-color: var(--grey-200);
        border-right: none;
      }
    }
    .phraseRow__part--append {
      button {
        border-color: var(--grey-200);
        border-left: none;
      }
    }
  }
}

.edit-phrase-block {
  .input-group-prepend,
  .input-group-append {
    > .btn {
      height: 100%;
      padding: 0 19px !important;
      line-height: 1.25rem;
      box-sizing: border-box;
      border: 1px solid var(--grey-300);
      background-color: var(--grey-100);
      color: var(--grey-600);
      z-index: 0 !important;
      &:hover {
        color: var(--grey-700);
        background-color: var(--black-4per);
      }
      &:focus {
        box-shadow: none !important;
      }
      &:disabled {
        color: var(--grey-300);
      }
      .justui__icon-wrapper {
        display: flex;
      }
    }
  }
  .input-group-append {
    border-left: 1px solid transparent;
  }
}

