.dialog-detail--pagination-row {
  & + .dialog-detail--labels-row {
    margin-top: 10px;
  }
}

.dialog-detail--labels-row {
  & + .dialog-detail--pagination-row {
    margin-top: 10px;
  }
}

#chartjs-tooltip {
  opacity: 1;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 3px;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  pointer-events: none;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.chartjs-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

.dialog-list-item__first-question {
  max-width: 850px;
  word-break: break-all;
}

#dialogDetailAudio {
  min-height: 67px;
  .audio-fixed {
    position: fixed;
    width: calc(100% - 266px);
    z-index: 1;
    background-color: var(--white);
    box-shadow: 0px 0px 1px rgba(17, 60, 92, 0.06), 0px 1px 3px rgba(12, 43, 66, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.16);
    margin-left: -30px;

    .volume-container {
      bottom: auto;
      top: 40px;
    }

    .sidebar-hidden & {
      width: calc(100% - 16px);
    }
  }
}

.shortName {
  display: flex;
  align-items: center;
  div {
    display: block;
    max-width: 36ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .shortNameCopyIcon {
    visibility: hidden;
  }

  &:hover {
    .shortNameCopyIcon {
      visibility: visible;
    }
  }
}

.tooltipCustomSize {
  width: auto;
  max-width: 100vw;
  .tooltip-inner {
    max-width: 100vw;
  }
}
