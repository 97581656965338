.faqTemplatesTabs {
  .justui__tab-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .justui_tabs.nav .nav-item {
    border-bottom-width: 2px;
    &.active {
      background-color: var(--white);
    }

    .nav-link {
      height: 100%;
      margin-top: 2px;
      &.active {
        color: var(--grey-950);
        .justui__tab-name {
          font-weight: bold;
        }
      }
    }
  }

  .justui_tabs.nav {
    border-bottom: none;
    flex-wrap: nowrap;
    .nav-item {
      margin-bottom: 0px;
      max-width: 169px;
    }
  }
  .justui_dropdown-toggle.justui_icon-button {
    height: 100%;
    &:focus {
      box-shadow: none;
    }
    .justui__icon-wrapper {
      display: flex;
    }
  }
}

.btn-group.combo-btn {
  > :first-child {
    display: flex;
    .just-ui-spinner {
      background-color: transparent !important;
      margin-right: auto;
    }
    &.btn-outline-primary {
      border-right-color: transparent;
      margin-right: -1px;
    }
  }
  > :last-child {
    z-index: 1;
    &::before {
      content: '';
      border-left: 1px solid white;
      height: 67%;
      position: absolute;
      top: 18%;
    }
    &.outline {
      &::before {
        border-color: var(--jaicp-600);
      }
    }
    &:focus-within {
      &::before {
        display: none;
      }
    }
    .justui_dropdown-toggle {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      height: 100%;
      &.btn-outline-primary {
        border-left-color: transparent;
      }

      .justui__icon-wrapper {
        display: flex;
      }
    }
  }
  :focus {
    ~ * {
      &::before {
        display: none;
      }
    }
  }
  .justui_button,
  .justui_icon-button {
    &.btn {
      &:focus {
        border-color: var(--jaicp-700);
      }
    }
    &.btn-outline-primary {
      &:hover,
      &:focus {
        background-color: transparent;
        color: var(--jaicp-700);
        border-color: var(--jaicp-700);
      }
      &:active {
        border-color: var(--jaicp-800);
        color: var(--jaicp-800);
        background-color: transparent;
      }
      &:disabled {
        border-color: var(--grey-500);
        color: var(--grey-500);
        cursor: initial;
      }
    }
  }
  :disabled {
    ~ * {
      &::before {
        border-color: var(--grey-500) !important;
      }
    }
    ~ .outline {
      &::before {
        border-color: var(--grey-500) !important;
        height: 100%;
        top: 0;
      }
    }
  }
}
