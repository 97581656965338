.HierarchyHeader {
  padding: 8px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  &__title {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__actions {
    display: flex;
    gap: 2px;

    a {
      text-decoration: none;
    }

    & :global(.justui_button) {
      border: none;
      padding: 4px !important;
    }
    & :global(.justui_icon-button) {
      border: none;
      padding: 4px !important;
    }
    & :global(.justui_dropdown-item) {
      padding: 8px 12px;
      display: flex;
      align-items: center;
      gap: 8px;

      :global(.justui_icon) {
        margin: 0;
      }
    }
    & :global(.dropdown-menu) {
      min-width: auto;
    }
  }
}
