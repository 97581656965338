.icon {
  width: 32px;
  height: 32px;
  min-height: 0;
  min-width: 0;
  color: white;
  text-align: center;
  vertical-align: middle;
}

.iconMiddle {
  width: 32px;
  height: 32px;
  line-height: 1.4;
  font-size: 1em;
}

.iconMedium {
  width: 24px;
  height: 24px;
  line-height: 1;
  font-size: 1em;
  display: inline-block;
}

.iconLarge {
  width: 40px;
  height: 40px;
  line-height: 1;
  font-size: 1em;
  display: inline-block;
}

.iconSmall {
  width: 18px;
  height: 18px;
  font-size: .7em;
}
