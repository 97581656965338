.NotificationBell {
  height: 100%;

  .bell {
    position: relative;
    color: white;
    height: 100%;

    &_active:after {
      content: '';
      position: absolute;
      top: 5px;
      right: -1px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: var(--red-600);
      border: 1px solid black;
    }

    &:hover {
      opacity: 0.8;
      color: white;
    }
  }

  &.darkTheme .bell {
    color: var(--gray-600);

    &_active:after {
      top: 1px;
      right: -1px;
      border: 1px solid white;
    }

    &:hover {
      opacity: 1;
      color: black;
    }
  }

  &__popover {
    :global(.justui_popover-body) {
      padding: 0;
    }
    :global(.arrow) {
      padding-left: 5px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 328px;
    background: var(--gray-100);
    max-height: 500px;
    overflow-y: auto;
    padding-bottom: 8px;
  }

  &__footer {
    border-top: 1px solid var(--gray-200);
    background: var(--white);
    padding: 8px 12px;
    display: flex;
    justify-content: flex-end;

    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &__empty {
    border-top: 1px solid var(--gray-200);
    background: var(--white);
    padding: 48px 12px;
    width: 328px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
:global(.aa_notification_task .notification-message) {
  max-height: none !important;
}
:global(.aa_notification_task .notification-buttons) {
  display: none !important;
}
