.TotalIntentsInfo {
  position: relative;

  padding: 8px 32px;
  border-top: 1px solid #1f282d;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0075em;
  color: #ffffff;

  &:hover {
    background-color: var(--indigo-900);
  }
}

.TotalIntentsInfoTooltip {
  position: absolute;
  z-index: 1;
  padding: 4px;

  &__wrapper {
    background-color: white;
    box-shadow:
      0 6px 12px 0 #0c2b4229,
      0 0 2px 0 #0000001a;

    color: var(--gray-800);
    font-size: 14px;
    border-radius: 4px;
  }

  &__title {
    padding: 8px 12px;

    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0075em;

    color: var(--gray-950);
  }

  &__content {
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__row {
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: 8px;

    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__divider {
    height: 1px;
    background-color: var(--gray-200);
  }
}
