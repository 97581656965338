$default-width: 300px;

.justui_sidebar {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: $default-width;
  box-sizing: border-box;
  border-width: thin;
  border-color: rgb(207, 216, 220);
  border-style: solid;

  background-color: #ffffff;
  color: #000000;

  &.dark {
    border-color: #1F282D;
    background-color: #273238;
    color: #E7EBED;
  }
}
