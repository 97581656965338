.StateNameEditField {
  display: block;
  position: absolute;
  width: 212px;
  height: 24px;
  border: 1px solid #2375b0;
  box-sizing: border-box;
  border-radius: 4px;
  background: #ffffff;
  padding: 2px 10px;
  z-index: 1;
  color: #333c45;
  font-weight: bold;
  &:focus {
    outline: none;
  }
}
