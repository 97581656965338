.HierarchyView {
  height: 100%;
  color: var(--gray-800);

  display: flex;
  flex-flow: column;
  padding-bottom: 4px;

  & :global(.justui__icon-wrapper) {
    display: flex;
    color: var(--gray-600);
  }

  &__treeView {
    flex: 1;

    & :global(.justui__icon-wrapper) {
      color: var(--nodeColor, var(--gray-600));
      stroke: var(--nodeStrokeColor, transparent);
    }
  }
}
