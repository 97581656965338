.edit-phrase-block {
  .input-group-prepend,
  .input-group-append {
    > .btn {
      height: 100%;
      padding: 0 19px;
      border: 1px solid var(--grey-300);
      background-color: var(--grey-100);
      color: var(--grey-600);
      z-index: 0 !important;
      &:hover {
        color: var(--grey-700);
        background-color: var(--black-4per);
      }
      &:focus {
        box-shadow: none !important;
      }
      &:disabled {
        color: var(--grey-300);
      }
      .justui__icon-wrapper {
        display: flex;
      }
    }
  }
  .input-group-append {
    border-left: 1px solid transparent;
  }
}
