@use 'sass:math';

@import '../../../../scss/scrollbar';

.JGraph-addingMenu {
  position: absolute;
  background: #ffffff;
  box-shadow:
    0 0 2px rgba(0, 0, 0, 0.1),
    0 6px 12px rgba(12, 43, 66, 0.16),
    0 0 1px rgba(17, 60, 92, 0.06);
  border-radius: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  z-index: 551; // over all just selects
  overflow: hidden;
  flex-direction: column;

  &-scrollContainer {
    flex-grow: 1;
    position: relative;
    padding-left: 12px;
    padding-right: 12px;
    overflow: auto;
    @include scrollbar;
  }

  &.actions {
    width: 250px;
    height: 372px;
  }
  &.intents {
    width: 284px;
    height: 450px;
    padding-bottom: 0;
  }

  &-searchContainer {
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 28px;
    svg {
      path {
        fill: #999 !important;
      }
    }
    input {
      border-radius: 0.25rem !important;
      border: 1px solid #f2f2f2 !important;
      background-color: #f2f2f2 !important;
      color: #999 !important;

      &.active {
        background-color: #fff !important;
        color: #333 !important;
        border: 1px solid #666 !important;
      }
    }
  }

  &-subgroup {
    margin: 8px 0;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  &-group {
    margin: 8px 0 16px;
    &:last-child {
      margin-bottom: 8px;
    }
    &-title {
      padding: 0 12px;
      font-weight: 700;
      letter-spacing: 0.0075em;
      font-size: #{math.div(14, 16)}rem;
      line-height: #{math.div(20, 16)}rem;
      margin-bottom: 8px;
      color: #6a7178;
    }
  }

  &-action {
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 3px;
    display: flex;
    gap: 8px;
    align-items: center;

    font-weight: 400;
    letter-spacing: 0.0075em;
    font-size: #{math.div(14, 16)}rem;
    line-height: #{math.div(20, 16)}rem;
    svg {
      color: var(--gray-500, #888e93);
    }
    &.active,
    &:hover {
      background: rgba(#6a7178, 0.3);
    }
  }

  &-createButton {
    text-align: center;
    padding: 12px 0;
    border-top: 1px solid #cccccc;
    background: #f4f5f5;
    color: #2375b0;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
    cursor: pointer;
    &:hover {
      background: darken(#f4f5f5, 5%);
    }
  }
}
