.EmptyJgraphView {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 4;

  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--gray-200);

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 225px;

    text-align: center;
    font-weight: 700;
    font-size: 20px;
  }

  &__cornerButton {
    position: absolute;
    top: 34px;
    left: 8px;
    display: flex;
    align-items: flex-start;
    gap: 8px;
  }
}

.StartStateCreationMenu {
  background: var(--white);
  padding: 12px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 6px 12px rgba(12, 43, 66, 0.16), 0 0 1px rgba(17, 60, 92, 0.06);
  border-radius: 8px;
  width: 256px;

  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 12px;
  }
  .title {
    display: flex;
    color: var(--gray-800, #333c45);
  }
  .footer {
    button {
      width: 100%;
    }
  }
}
