.LevelUpButton {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  line-height: 1rem !important;
  font-size: 14px !important;
  width: auto;
  height: 40px;
  white-space: nowrap;

  span {
    pointer-events: none;
  }
  &:hover {
    background: var(--primary-100);
    color: var(--primary-600) !important;
  }
  &:global(.active) {
    background: var(--primary-100);
    color: var(--primary-600);
  }
}
