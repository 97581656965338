.cdqa-file-upload {
  padding: 16px;
  &__info {
    width: 100%;
    text-align: start;
    p {
      margin-bottom: 8px;
    }
  }
  ul {
    font-size: 12px;
  }
  .upload-success,
  .upload-error {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 12px;
    text-align: start;
    p {
      margin-bottom: 0;
    }
  }
}

.cdqa-modal__header {
  margin-bottom: 4px;
}

.cdqa-modal__docs-link {
  display: block;
  font-weight: 700;
  text-decoration: none;
  margin-bottom: 24px;
}

.file-req-list {
  list-style-type: none;
  color: var(--grey-600);
  padding-left: 0;
  li {
    &::before {
      content: '—';
      color: var(--grey-600);
      margin-right: 4px;
    }
  }
  &.text-danger {
    color: var(--danger);
    li::before {
      color: var(--danger);
    }
  }
}
