.SearchFilter {
  & .filterButton {
    padding: 4px !important;

    & :global(.justui__icon-wrapper) {
      display: flex;
      svg {
        color: var(--gray-600) !important;
      }
    }

    &.active,
    &:focus,
    &:hover {
      box-shadow: none;
      outline: none;
      & :global(.justui__icon-wrapper) {
        svg {
          color: var(--gray-950) !important;
        }
      }
    }
  }

  .option {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;

    padding: 8px 12px;
    font-size: 14px;

    width: 230px;

    &:focus,
    &:hover {
      cursor: pointer;
      background-color: var(--gray-100);
      outline: none;
    }
  }
}

.FilterChips {
  width: 100%;
  display: flex;
  flex-flow: wrap row;
  gap: 8px;

  & :global(.justui__icon-wrapper) {
    display: flex;
  }

  & .FilterChip {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 2px 4px;
    border-radius: 2px;

    background-color: var(--indigo-200);
    color: var(--indigo-800);
    font-size: 12px;

    & .closeIcon {
      cursor: pointer;
    }
  }
}
