.intentFaqEditView {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  padding-right: 8px;

  .panel {
    padding: 24px;
    background-color: var(--white);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(12, 43, 66, 0.06), 0 0 1px rgba(17, 60, 92, 0.06);
    border-radius: 4px;
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 12px;

    :global(.faq-answer-part__media) {
      > div {
        overflow: inherit;
      }
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .label {
        font-size: 16px;
      }

      :global(.justui__icon-wrapper) {
        display: flex;
        margin-right: auto;
        margin-left: 8px;
      }
      &--answers {
        justify-content: flex-start;
        :global(.justui__icon-wrapper) {
          display: flex;
          margin-right: 16px;
          margin-left: 8px;
        }
      }
    }
    &__switch {
      display: flex;
      align-items: center;
      margin-left: auto;
      gap: 8px;
      p {
        margin-bottom: 0;
      }
    }
  }
}
