.faq-tabs-context-menu {
  .justui__icon-wrapper {
    svg.fa-sm {
      width: 16px;
      height: 16px;
    }
  }
  .dropdown-item {
    padding: 8px;
    padding-right: 32px;
    padding-left: 4px;
  }
  &__tab-delete.dropdown-item {
    color: var(--danger);
  }
}
