.limit-banner {
  min-height: 30px;
  width: 100%;
  background-color: #ef2d56;
  color: var(--gray-900);
  font-size: 12px;
  text-align: center;
  line-height: 30px;
  z-index: 1020;
}

@media (max-width: 1320px) {
  .limit-banner {
    font-size: 11px;
  }
}
