#ChooseReadyIntent {
  position: absolute;
  width: 348px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 1px 1px rgba(17, 60, 92, 0.06), 0 6px 12px 0 rgba(12, 43, 66, 0.16);
  z-index: 1301;
  padding: 16px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);

  .body {
    margin-top: 16px;

    .p {
      margin-bottom: 8px;
      font-size: 12px;
      color: var(--gray-500);
    }

    .selector {
      max-height: 176px;
      min-width: 100%;
      border: 1px solid #e9e9e8;
      overflow: hidden;

      .scrollbar-container {
        max-height: 174px;
      }

      .item {
        cursor: pointer;
        width: 100%;
        line-height: 32px;
        padding: 0 14px;
        border-bottom: 1px solid #e9e9e8;

        &.selected,
        &:hover {
          background: #246da1;
          color: var(--white);
        }
      }
    }
  }
  .footer {
    .buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 0.25rem;
      button {
        &:last-child {
          margin-left: 0.25rem;
        }
      }
    }
  }
}

.likeButton {
  color: var(--primary);
  cursor: pointer;
  display: inline-flex;
  position: relative;
  font-size: 12px;
  margin-top: 1px;
  font-weight: 600;
  text-transform: uppercase;
}

.crmIntegration-modal {
  .d-flex {
    *[class*='col'] {
      &:nth-child(1) {
        padding-left: 0;
      }
      &:last-of-type {
        padding-right: 0;
      }
    }
  }
  label {
    display: block;
    margin-bottom: 0;
  }
  h3 {
    font-size: 17px;
  }
  .with-remove-btn {
    label {
      display: inline-block;
      width: calc(100% - 35px);
    }
    .input_remove-action {
      width: 35px;
      height: 35px;
      display: inline-block;
      text-align: center;
      line-height: 35px;
    }
  }
  form {
    .input_remove-action {
      cursor: pointer;
      width: 35px;
      display: inline-block;
      height: 35px;
      line-height: 35px;
      text-align: center;
    }
    .multipleInput {
      display: inline-block;
      width: calc(100% - 35px);
    }

    .switch {
      margin-left: 10px;
    }
    .checkbox {
      margin-top: 10px;
    }
    .objectField {
      width: 100%;
      display: flex;
      margin-right: -5px;
      margin-left: -5px;

      .input-wrap {
        padding-left: 5px;
        padding-right: 5px;
        width: calc(50% - 18px);
        flex-basis: calc(50% - 18px);
      }
    }
  }
}

.modal-toggles {
  display: flex;

  .modal-toggle {
    background: #605fe3;
    padding: 5px 10px;
    color: #fff;
    margin-right: 5px;
    cursor: pointer;
  }
}
