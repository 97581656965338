@import 'src/scss/scrollbar';

.RSAddingMenu {
  padding: 16px 32px;
  background-color: var(--gray-100, #f4f5f5);
  height: 100%;
  overflow: auto;
  @include scrollbar();
  h5 {
    font-size: 14px;
    font-weight: 700;
    margin: 12px 0;
    line-height: 20px;
    letter-spacing: 0.0075em;
    color: var(--gray-600, #6a7178);
  }

  .RSAddingMenuSearchBar {
    position: relative;
  }
  .RSAddingMenuListHeaderHint {
    color: var(--gray-600);
    size: 12px;
    margin-bottom: 16px;
  }
  .RSAddingMenuSearchInputIcon {
    position: absolute;
    top: 50%;
    left: 12px;
    margin-top: -8px;
    color: var(--gray-600, #6a7178);
    + input {
      padding-left: 36px;
    }
  }
}
.RSAddingMenuEvent {
  background-color: #ffffff;
}
.RSAddingMenuItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 6px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  margin-bottom: 12px;
  gap: 12px;
  background-color: #fff;
  .name {
    flex-grow: 1;
    color: var(--gray-800, #333c45);
  }

  .RSAddingMenuItemIcon {
    color: var(--gray-600, #6a7178);
  }
  .docLink {
    color: var(--gray-100, #6a7178);
  }

  &:hover {
    background-color: var(--gray-100, rgba(0, 0, 0, 0.03));
  }
}

.tooltipCustomWidth {
  max-width: 256px !important;
  text-align: left !important;
}
