.BottomLeftTriangleWrapper {
  position: relative;

  &__icon {
    color: var(--gray-500);

    position: absolute;
    transform: rotate(-45deg);
    transform-origin: center;
    height: 8px;
    width: 8px;
    bottom: 7px;
    right: 7px;
    pointer-events: none;
  }
}
