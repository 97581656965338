.Tooltip {
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0px 6px 12px rgba(12, 43, 66, 0.16))
    drop-shadow(0px 0px 1px rgba(17, 60, 92, 0.06));
  border: 1px solid var(--gray-300);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  color: var(--gray-800);
  background: var(--white);
  position: relative;

  &__close {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 12px;
    svg {
      color: var(--gray-600);
    }
  }

  &__arrow {
    position: absolute;
    top: -5px;
    right: 15px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid var(--white);
  }
}
