.AutoPlacementButton {
  position: relative;
  display: flex;
  gap: 4px;
  color: var(--gray-800);

  &__dropdown {
    position: absolute;
    width: 226px;
    left: calc(100% + 4px);
    top: 0;
    display: flex;
    flex-direction: column;
    padding: 8px 0;
    background: var(--white);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 6px 12px rgba(12, 43, 66, 0.16), 0 0 1px rgba(17, 60, 92, 0.06);
    border-radius: 4px;

    &__item {
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.02em;
      white-space: nowrap;
      padding: 8px 12px;

      &:hover {
        cursor: pointer;
        background-color: var(--gray-100);
      }
    }

    &__info {
      padding: 8px 12px;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.02em;
      white-space: break-spaces;
      color: var(--gray-600);
    }
  }
}

.divider {
  height: 1px;
  background: var(--gray-200);
  width: 100%;
  margin: 8px 0;
}
