@import '~@just-ai/just-ui/scss/basic/custom/functions';
@import '~@just-ai/just-ui/scss/basic/custom/variables';

.nlu-errors-block.alert {
  display: flex;
  padding-right: 1rem;
  padding-left: 1rem;
  column-gap: 0.5rem;
  margin-top: 1.5rem;
  p {
    margin-bottom: 0.25rem;
    color: black;
    &:first-child {
      color: var(--gray-900);
    }
  }
}

.createProjectModal {
  h5.modal-title {
    display: flex;
    .logo-box {
      margin-top: -2px;
    }
  }
}

.logo-box {
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
}

.nav-item {
  .just-ui-spinner {
    position: fixed !important;
  }
}

.createProjectModalTabs {
  display: grid !important;
  grid-auto-flow: column;
  padding-right: 6rem;
  li a {
    padding: 0.5rem 0 !important;
    justify-content: center;
    align-items: center;
  }
}

.edit-form__alert {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
