.global-test-widget {
  #widget-root {
    height: 100% !important;
    max-height: unset !important;
    width: 100% !important;
    max-width: unset !important;
  }

  .justwidget {
    left: unset !important;
    top: unset !important;
    bottom: unset !important;
    right: unset !important;
    height: 100% !important;
    width: 100% !important;
    position: relative;
    max-height: unset !important;
    box-shadow: unset !important;
  }

  .justwidget--inner {
    width: 100% !important;
    max-width: unset !important;
    height: 100% !important;
    max-height: unset !important;
    border-radius: unset !important;
  }

  .justwidget--headline h2 {
    color: white !important;
  }

  .justwidget--powered_label,
  .justwidget--headline-height-button-container,
  .justwidget--headline-height-button,
  .justwidget--asst {
    display: none !important;
  }
}

.visually-hidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}
