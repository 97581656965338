.faqTreeContextMenu {
  padding: 4px 0 !important;
  :global(.dropdown-item) {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    padding-left: 4px !important;
    &.faqTreeContextMenu__delete {
      color: var(--red-300) !important;
    }
    &:not(.faqTreeContextMenu__delete) {
      color: var(--gray-300) !important;
    }
    &:hover {
      background-color: var(--gray-700) !important;
    }
    &:global(.disabled) {
      opacity: 0.3;
      cursor: unset !important;
    }
  }
}
