.components-status {
  &-list {
    display: table;
    border: 1px solid $table-border;
    padding: 0;
    background-color: $white;
    margin: 0 auto;
  }

  &-caption {
    background-color: $table-border;
    display: table-row;
    & > span {
      display: table-cell;
      padding: 0.75rem;
      vertical-align: top;
      color: $table-caption;
      font-weight: bold;
    }
  }

  &-item {
    display: table-row;

    &:hover {
      background-color: $table-row-hover;
    }

    & > span {
      display: table-cell;
      padding: 0.75rem;
      vertical-align: middle;
      border-top: 1px solid $table-border;
    }
  }
}

.system {
  &-component {
    padding: 0;

    &-name {
      padding-top: 1rem;
      margin-bottom: 0;
    }
    &-version {
    }
  }

  &-status-icon {
    padding: 0.75rem;
    color: $white;
    width: 24px;
    height: 24px;
    box-sizing: content-box;
    text-align: center;

    &.sm {
      //same size as btn-sm
      padding: 0.219rem;
      font-size: 1rem !important;
      line-height: 24px;
    }

    &.small {
      font-size: 1rem !important;
      width: 16px;
      height: 16px;
      padding: 0.5rem;
    }

    &-primary {
      background-color: $brand-primary;
    }

    &-danger {
      background-color: var(--danger);
    }

    &-warning {
      background-color: $brand-warning;
    }

    &-unavaliable {
      background-color: $gray-light;
    }

    &-progress {
      color: $gray;
    }

    &.fa.pull-left {
      margin-right: 1rem;
    }
  }
}
