.audio-block {
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    margin: 8px 0;
    border-radius: 3px;
    font-size: 12px;

    &__title {
        padding: 8px 16px;
        border-bottom: 1px solid #EDEDED;
    }

    &__no-title {
        background-color: #FCEFB5;
    }

    &__tooltip {
        max-width: 300px!important;
    }

    &__duration {
        color: var(--gray);
        border-bottom: 1px dashed var(--gray);
        margin-right: 1rem;
        cursor: pointer;

        &:hover {
            border-bottom: none;
        }
    }

    &__meta {
        color: var(--gray-100);
    }

    &__download-link {
      display: inline-block;
      height: 100%;
      width: 100%;
      line-height: 21px;
      padding: 8px 20px;
    }
}
