.projectedSidebar {
  .nav-dropdown-toggle {
    cursor: pointer;
  }
  .justui__icon-wrapper {
    svg {
      path {
        fill: var(--gray-300);
      }
    }
  }
  svg.justui_icon {
    path {
      fill: var(--gray-300);
    }
  }
  .nav-dropdown-items {
    li {
      &:hover {
        background-color: rgba(255, 255, 255, 0.05);
      }
    }
  }
  .nav-link.active {
    .nav-item-name {
      color: var(--gray-100);
    }
  }

}

.sidebar .projectedSidebar .nav-dropdown-items .nav-item .nav-link:hover {
  color: var(--gray-100) !important;
}
.sidebar .projectedSidebar .nav-dropdown-items .nav-item .nav-link:hover {
  background-color: rgba(255, 255, 255, 0.05) !important;
}

.sidebar .projectedSidebar .nav .nav-item .nav-link {
  transition: all 0.2s;
}

.sidebar button.nav-link{
  width: 100%;
  text-align: left;
  border: 0;
  &:focus{
    box-shadow: none;
  }
}
.link-toggle-shevron {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  & > div:first-child {
    width: auto;
  }
  & > *:last-child {
    transform: rotateZ(0deg);
    transform-origin: center center;
    transition: 300ms ease-in-out;
    width: 12px;
    height: 12px;
    line-height: 12px;
    svg {
      margin: 0 !important;
      padding: 0 !important;
      width: 12px;
      height: 12px;
    }
  }
  &.active {
    & > *:last-child {
      transform: rotateZ(180deg);
    }
  }
}
