.justui_announcement {
  background: var(--primary-100);
  padding-left: 8px;
  padding-right: 46px;
  text-align: center;
  font-size: 14px;
  line-height: 21px;
  color: var(--grey-800);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 46px;
  z-index: 1021;
  position: relative;

  &.danger {
    background: var(--red-100);
  }
  &.success {
    background: var(--green-100);
  }
  &.warning {
    background: var(--yellow-100);
  }

  .justui_icon.svg-inline--fa:not(.btn-color).fa-color-info {
    color: var(--primary);
  }

  .justui_announcement-close {
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: right;
    min-width: 46px;
    min-height: 46px;
    position: absolute;
    right: 0;

    .justui_icon {
      margin: 0 auto;
    }
  }
  .justui_announcement-icon-type {
    margin-right: 8px;
    font-size: 1.25rem;
    width: 20px;
    height: 20px;
  }
  .justui_announcement-text {
    margin-right: 8px;

    a {
      font-weight: 500;
      text-decoration: underline;
    }
  }

  .justui_announcement-buttons {
    display: flex;
    button {
      margin-left: 8px;
    }

    .btn-info {
      background-color: var(--primary);
      border-color: var(--primary);
    }
  }

  &.dark {
    background: #1F282D;

    .justui_announcement-close {
      svg{
        color: #ffffff;
      }
    }
    .justui_announcement-text {
      color: #ffffff;
    }
  }
}
