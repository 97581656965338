.Session_container {
  margin: 0 !important;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  scroll-behavior: smooth;
  overflow: auto;
  background-color: var(--white);
}
.Session_container .Session_backButton {
  padding: 8px 24px;
  border-bottom: 1px solid var(--gray-200);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.Session_container .Session_backButton > *:last-child {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.Session_container .Session_backButton .splitter {
  height: 18px;
  width: 1px;
  left: 325.5px;
  background-color: var(--gray-200);
  margin: 0px 16px;
}
.Session_container .Session_backButton > div > button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.75rem !important;
}
.Session_container .Session_backButton > div > button > svg {
  margin-right: 0.25rem;
  margin-left: 0.25rem;
  width: 6px !important;
}
.Session_container .Session_head {
  width: 100%;
  background-color: white;
}
.Session_container .Session_head > * {
  width: 100%;
}
.Session_container .Session_Audio {
  width: 100%;
  padding: 1rem 2rem;
  display: grid;
  grid-template-columns: 2fr 14fr;
  grid-template-rows: auto;
  background-color: var(--gray-100);
  gap: 1rem;
  position: sticky;
  top: 0;
  z-index: 1;
}
@media (max-width: 900px) {
  .Session_container .Session_Audio {
    grid-template-columns: 2fr 8fr;
  }
}
.Session_container .Session_Audio > *:first-child {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.Session_container .Session_Audio > *:last-child {
  width: 100%;
}
.Session_container .audio-block {
  margin: 0 !important;
}
.Session_container .audio-block .justui-audio-player__custom-button {
  background-color: transparent !important;
  border: none !important;
}
.Session_container .audio-block .justui-audio-player__custom-button svg * {
  fill: var(--gray);
}
.Session_container .audio-progress-bar__point {
  background-color: var(--gray) !important;
}
.Session_container .audio-progress-bar {
  background-color: var(--gray-200) !important;
}
.Session_container .audio-progress-bar__progress {
  background-color: var(--green) !important;
}

.Session_showSettingsButton {
  margin-right: 0.5rem;
  outline: none !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.Session_showSettingsButton:active, .Session_showSettingsButton:focus {
  outline: none !important;
  background-color: var(--gray-200) !important;
}

.AnalyticActionPanel_actionPanel {
  border-top: 1px solid var(--gray-200);
  z-index: 2;
  width: 100%;
  top: 0;
  background-color: var(--white);
}

.Session_SessionInfo_container {
  height: auto;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto minmax(233px, 40%);
  gap: 16px;
}
.Session_SessionInfo_container .shortNameCopyIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  transition: 200ms;
}
.Session_SessionInfo_container .Session_SessionInfo_sessionId_clientId {
  padding: 24px 24px 0 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  gap: 32px;
}
.Session_SessionInfo_container .Session_SessionInfo_sessionId_clientId > * {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto 2rem;
}
.Session_SessionInfo_container .Session_SessionInfo_sessionId_clientId > * > *:first-child {
  grid-column: 1/3;
  color: var(--gray);
}
.Session_SessionInfo_container .Session_SessionInfo_sessionId_clientId > * > *:nth-child(2) {
  white-space: nowrap;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Session_SessionInfo_container .Session_SessionInfo_sessionId_clientId .Session_SessionInfo_clientId {
  color: var(--primary);
}
.Session_SessionInfo_container .Session_SessionInfo_botId {
  padding: 0 24px 24px 24px;
  grid-row: 2/3;
  grid-column: 1/3;
  border-bottom: 1px solid var(--gray-200);
}
.Session_SessionInfo_container .Session_SessionInfo_botId > *:first-child {
  color: var(--gray);
}
.Session_SessionInfo_container .shortNameCopyIcon {
  cursor: pointer;
}
.Session_SessionInfo_container .Session_SessionInfo_time {
  padding: 24px;
  grid-row: 1/3;
  grid-column: 2/3;
  border-left: 1px solid var(--gray-200);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
}
.Session_SessionInfo_container .Session_SessionInfo_time > * > *:first-child {
  color: var(--gray);
}

.Session_form_item_container {
  margin-top: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.Session_form_item_container > span:first-child {
  margin-bottom: 4px;
  font-size: 12px;
  color: var(--gray);
}
.Session_form_item_container > div:last-child {
  width: 100%;
  min-height: 41px;
  padding: 0rem 3px 0rem 1rem;
  border: 1px solid var(--gray-200);
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--gray-100);
}
.Session_form_item_container > div:last-child > * {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.Session_form_item_container > div:last-child > *:last-child {
  justify-content: flex-end;
}
.Session_form_item_green, .Session_form_item_blue {
  margin: 0.5rem;
  color: #215433;
  background-color: #c0e7ce;
  border-radius: 2px;
  padding: 3px 8px !important;
  line-height: 16px;
  background-color: var(--gray-100);
  color: var(--gray);
  font-size: 0.75rem;
}
.Session_form_item_blue {
  background-color: #2289af;
  margin: 0.5rem 0.25rem;
  color: var(--white);
}

.labelGroups_container {
  padding: 16px 24px;
  width: 100%;
  overflow: hidden;
}
.labelGroups_container > *:first-child {
  color: var(--gray);
}
.labelGroups_container > *:nth-child(2) {
  background-color: var(--gray-100);
  padding: 8px;
  border: 1px solid var(--gray-200);
}

