.hr {
  margin: 16px 0;
}
.HowToCreateCustomTag {
  display: flex;
  padding: 12px 16px 10px;
  flex-direction: column;
  border: 1px solid #e0e1e3;
  border-radius: 8px;
  background: var(--white, #fff);
  position: relative;
  background-image: url('./image.svg');
  background-position: right center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;

  b {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: var(--gray-800, #333c45);
    margin-bottom: 0;
  }
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: var(--gray-700, #4b535b);
    margin: 2px 0 0;
  }
}
