.IsolatedWidget {
  position: fixed;
  top: 0;
  left: 0;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 6px 12px 0px #0c2b4229;
  z-index: 9999;
  transform: translate(0, 0);
  height: 500px;
  width: 320px;
}
