.ThemeCreationMenu {
  position: fixed;
  width: 260px;
  background-color: var(--white, #fff);
  z-index: 4;
  padding: 12px 0;
  border-radius: 8px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 6px 12px rgba(12, 43, 66, 0.16), 0 0 1px rgba(17, 60, 92, 0.06);

  .header {
    padding: 0 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 12px;
  }
  .title {
    display: flex;
    color: var(--gray-800, #333c45);
  }

  .footer {
    padding: 0 12px;
    button {
      width: 100%;
    }
  }
}
