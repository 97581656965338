.weekdays-select {
  padding-left: 0;

  .weekdays-select__weekday {
    padding: 0.5rem;
    border-style: solid;
    border-width: 1px;
    color: var(--primary);
    border-color: var(--primary);
    user-select: none;
    cursor: pointer;
    margin-right: 12px;

    &.active {
      background-color: var(--primary);
      color: $white;

      &.disabled {
        background-color: var(--gray-300);
      }
    }

    &.disabled {
      border-color: var(--gray-300);
      color: var(--gray-500);
    }

    input {
      position: absolute;
      clip: rect(0 0 0 0);
      width: 1px;
      height: 1px;
      margin: -1px;
    }
  }
}
