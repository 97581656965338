#IntentGroupEditModal {
  .IntentCollapses {
    margin-bottom: 10px;

    .emptyIntents {
      border: solid 1px rgba(42, 39, 34, 0.1);
    }

    .IntentCollapse {
      border: solid 1px rgba(42, 39, 34, 0.1);
      + .IntentCollapse {
        border-top: none;
      }
    }

    .collapse-title {
      height: 45px;
      display: flex;
      align-items: center;
      padding: 0 15px;
      user-select: none;

      .collapse-arrow {
        margin-right: 15px;
      }

      .dropdown {
        margin-left: auto;
      }

      .delete-context-menu {
        padding-top: 8px;
      }
    }

    .collapse-padding {
      padding: 15px;
      border-top: solid 1px transparent;
    }

    .collapse {
      &.show {
        border-top-color: rgba(42, 39, 34, 0.1);
      }
    }
    .collapsing {
      border-top-color: rgba(42, 39, 34, 0.1);
    }

    .posr {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .change-type {
      width: 35px;
      height: 35px;
      text-align: center;
      line-height: 35px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 0.25rem 0 0 0.25rem;
      border-right: none;

      & + input {
        border-radius: 0 0.25rem 0.25rem 0;
      }
    }

    .remove-example {
      width: 35px;
      height: 35px;
      line-height: 35px;
      text-align: center;
      cursor: pointer;
    }

    .add-example {
      text-transform: uppercase;
      cursor: pointer;

      .subtext {
        margin-left: 10px;
        text-transform: none;
      }
    }

    .examplesCount {
      margin-left: 20px;
    }

    .intent-title {
      font-weight: 500;

      .text-muted {
        font-weight: normal;
      }
    }
  }

  .hiddenInput {
    top: -10000px;
    left: -10000px;
    width: 20px;
    height: 20px;
    position: absolute;
  }

  .emptyIntents {
    display: flex;
    justify-content: center;
    padding: 30px 50px;
    align-items: center;

    .text {
      text-align: center;
      padding-left: 20px;
      padding-right: 20px;

      .title {
        font-weight: bold;
      }
    }
  }

  .switch {
    margin-right: 10px;
  }

  .add-intent {
    margin-bottom: 10px;
  }
  .add-intent-btn {
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
    display: inline-block;
  }
}

#ChooseReadyIntent .close_popper {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 6px;
}
