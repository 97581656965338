.container {
  display: flex;
  background: var(--white);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(12, 43, 66, 0.06), 0 0 1px rgba(17, 60, 92, 0.06);
  border-radius: 8px;
  gap: 4px;
  padding: 4px;

  &:empty {
    display: none;
  }

  &.horizontal {
    flex-direction: row;
  }
  &.vertical {
    flex-direction: column;
  }

  .jgToolbarButton {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
    line-height: 1rem !important;
    font-size: 1rem !important;
    width: 40px;
    height: 40px;

    span {
      pointer-events: none;
    }
    &:hover {
      background: var(--primary-100);
      color: var(--primary-600);
    }
    &:global(.active) {
      background: var(--primary-100);
      color: var(--primary-600);
    }
  }

  :global(.btn) {
    color: var(--gray-600);
  }
}
