.ucb-project-statistics-link {
  display: block;
}

.project-item_overview-picker {
  max-width: 500px;
  width: auto;

  &.react-select {
    min-width: 500px;
  }
  &.ant-calendar-picker {
    width: auto;
  }
}

.project-item_channels-select {
  &.react-select {
    min-width: 300px;
  }
}

.react-select {
  .option__channel {
    display: flex;
    padding: 5px 10px;
    align-items: center;
    height: 38px;
    cursor: pointer;

    &:hover {
      background-color: #deebff;
    }

    &-icon {
      margin-right: 10px;
      min-width: 36px;
    }

    &-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: calc(100% - 46px);

      &_text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

.project-overview_table {
  thead th {
    width: 300px;
    font-weight: normal;
    padding-bottom: 0;
    border-bottom: 0;
    border-left: 1px solid var(--gray-300);
    border-top: 0;

    i.fa {
      margin-left: 10px;
      cursor: pointer;
      color: #605fe3;
      font-size: 1.2rem;
      vertical-align: middle;
    }

    &:first-child,
    &:last-child {
      border-left: 0;
    }
  }

  tbody td {
    border-top: 0;
    border-left: 1px solid var(--gray-300);
    border-bottom: 1px solid var(--gray-300);
    font-size: 1.4rem;
    font-weight: bold;
    padding-top: 0;
    padding-bottom: 0;
    &:first-child,
    &:last-child {
      border-left: 0;
    }
  }
}

.ucb-activity-chart {
  canvas {
    max-width: 100%;
    max-height: 180px;
    height: 180px;
  }

  .card-block {
    padding: 0.5rem 1.25rem;
  }
}

.trend {
  .fa-long-arrow-up {
    color: var(--success);
  }

  .fa-long-arrow-down {
    color: var(--danger);
  }
}

.project-report-filter {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;

  .ant-select {
    width: 100%;
  }
}

.metric-headline {
  line-height: 1;
  max-height: 46px;
}

.chart-wrapper {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 1.5rem;
}

.project-report-chart {
  margin-bottom: 0;
}
