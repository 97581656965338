.dialogs-content,
.class-modal-content {
  .nav-tabs {
    li.nav-item {
      z-index: 0;
      a {
        padding: 0.2rem 1rem;
      }

      .nav-link {
        position: relative;
        background: transparent;
        border-bottom: 0 none;
        border-left: 0 none;
        border-color: transparent;

        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          z-index: -2;
          transform: skew(30deg);
          left: 0.5rem;
          border-width: 1px 1px 1px 0;
          border-style: solid;
          border-color: $gray-lighter;
        }

        &.active {
          border-color: transparent;
          z-index: 1;
          border-left: 1px solid $gray-lighter;
          margin-left: 3px;
          margin-right: -3px;

          &:after {
            background: $white;
            border-color: $gray-lighter $gray-lighter $white $gray-lighter;
          }
          &:before {
            display: block;
            background: $white;
            content: '';
            position: absolute;
            width: 1rem;
            height: 100%;
            top: 2px;
            z-index: -1;
            left: 0;
            border-left: none;
          }
        }
      }

      &:first-child {
        .nav-link {
          &:before {
            display: block;
            background: transparent;
            content: '';
            position: absolute;
            width: 1rem;
            height: 100%;
            top: 2px;
            z-index: -1;
            left: 0;
            border-left: none;
          }
          &.active {
            margin-left: 0;
            margin-right: 0;

            &:before {
              background: white;
            }
          }
        }
      }
    }
  }
}
