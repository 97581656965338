.tree {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: var(--grey-800);
}

.node {
  padding: 10px 16px;
  width: 100%;
  border: 2px solid transparent;
  display: inline-flex;
  align-items: center;
  height: 40px;
  gap: 8px;

  &__dragging {
    color: var(--gray-600) !important;
    background-color: var(--gray-800) !important;
    box-shadow: 0 0 28px 9px rgba(0, 0, 0, 0.15) inset;
  }

  .text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:not(.disableHover):hover {
    cursor: pointer;
    box-sizing: border-box;
    border-color: var(--primary-500);
  }

  &__selected {
    color: var(--gray-200);
    background-color: var(--gray-600);
  }

  &__secondary_selected {
    background-color: var(--gray-700);
  }

  &__active {
    font-weight: bold;
  }

  &__elementDisabled {
    color: var(--gray-500);
  }

  &__groupSelected {
    color: var(--gray-200);
    background-color: var(--gray-600);
  }

  &__isOver {
    border-color: var(--primary-500);
  }

  &__groupChildrenContainer {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
  }

  &__emptyMessage {
    height: 45px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: var(--gray-500);
  }

  &__editControl {
    color: var(--gray-200);
    outline: none;
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    &::placeholder {
      color: var(--gray-400);
    }
  }

  &__category {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    gap: 6px;
    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    span:first-of-type {
      min-width: 5ch;
      flex: 1 1;
    }
  }
}

.disableHover {
}

.searchTitle {
  display: flex;
  align-items: flex-end;

  padding: 0.5rem 1rem;

  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--gray-500);
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.errorIcon {
  color: var(--red-200) !important;
}

.editContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
}
