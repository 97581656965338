.faqTree {
  &__header {
    font-weight: 700;
    > div {
      display: flex;
      gap: 8px;
    }
    .justui_icon-button.justui_button.btn {
      border: none;
      color: var(--gray-200);
      padding: 4px;
      .justui__icon-wrapper {
        display: flex;
      }
      &:focus {
        color: var(--gray-200);
      }
      &:hover {
        cursor: pointer;
        color: var(--gray-200) !important;
        background-color: var(--gray-900);
      }
      &:not(:disabled),
      &:not(.disabled) {
        &:active {
          background-color: var(--gray-950);
          color: var(--gray-200) !important;
        }
      }
    }
  }

  &__input {
    input {
      height: 43px;
      border-radius: 0;
      background-color: var(--gray-900);
      border-color: var(--gray-900);
      color: var(--gray-200) !important;

      &::placeholder {
        color: var(--gray-500);
      }
      &:focus {
        background-color: var(--gray-900);
        border-color: var(--gray-900);
      }
    }
    svg {
      color: var(--gray-300);
    }
    .IconButtons-wrap-prepend {
      button {
        height: 43px !important;
        padding-left: 10px !important;
        cursor: default;
      }
    }
    .IconButtons-wrap-append {
      button {
        height: 43px !important;
        padding-right: 10px !important;
      }
    }
  }
}
