.ListTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 16px;
  table-layout: fixed;

  & :global {
    tbody tr {
      background: white;
      border-spacing: 16px;
    }

    th,
    td {
      text-align: left;
    }

    th {
      color: var(--grey-600);

      padding-left: 16px;

      &:last-child {
        padding-right: 16px;
      }
    }

    td {
      padding: 12px 15px;

      &:first-child {
        border-radius: 6px 0 0 6px;
      }

      &:last-child {
        border-radius: 0 6px 6px 0;
      }
    }
  }
}
