.project-language-popover {
  .popover {
    max-width: 400px;
  }
}

.initial-code-select .initial-code-option {
  p {
    margin-bottom: 0;
  }
  span {
    font-size: 12px;
    padding: 0;
  }
}

.external-repo-warning {
  min-height: unset;
  margin-bottom: 1rem;
  svg {
    font-size: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    box-sizing: border-box;
  }
}

.form-group {
  .btn {
    display: flex;
    .justui__icon-wrapper {
      display: flex;
    }
  }
}
.FileUploadContainer {
  &.disabled {
    pointer-events: none;
  }
}
