@import '../botadmin-frontend/src/scss/scrollbar';

.CailaIntentsSimpleWrapper {
  color: var(--gray-800) !important;
  min-height: 450px;
}

.CailaIntentsSimple {
  &_body {
    border-top: 1px solid var(--gray-200);
    border-bottom: 1px solid var(--gray-200);
  }
  &_listItemsContainer {
    margin: 2px 0;
    gap: 2px;
    flex-direction: column;
  }
  &_listItemTitle {
    padding: 10px 16px;
    border-bottom: 1px solid var(--gray-200);
    line-height: 20px;

    &_counter {
      text-align: center;
      min-width: 24px;
    }
  }
  &_listItem {
    padding: 10px 16px;
    cursor: pointer;
    border-radius: 4px;
    line-height: 20px;

    &_text {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &.isSelected {
      background-color: var(--indigo-100);
      box-shadow: inset 1px 1px 0 var(--indigo-200), inset -1px -1px 0 var(--indigo-200);
    }

    &.isActive {
      box-shadow: inset 2px 2px 0 var(--indigo-500), inset -2px -2px 0 var(--indigo-500);

      .dropDownContainer {
        display: block;
        top: -2px;
        right: 0;
      }

      .CailaIntentsSimple_listItem_counter {
        display: none !important;
      }
    }

    &.disabled {
      color: var(--gray-400);
    }

    &_action {
      &:hover {
        > .dropDownContainer {
          display: block;
          top: -2px;
          right: 0;
        }
      }
    }
    &_counter {
      text-align: center;
      min-width: 24px;
    }
  }
  &_list_container {
    width: 320px;
    border-right: 1px solid var(--gray-200);

    &_header {
      padding: 8px 16px;
      border-bottom: 1px solid var(--gray-200);

      &_customButtons {
        padding: 3px !important;
        line-height: 12px !important;
      }
    }
    &_list {
      max-height: calc(((100vh - 3.5rem) - 72px) - 86px - 41px - 2px);

      overflow: auto;
      padding: 8px 16px;
      @include scrollbar();
    }
  }

  &_content {
    max-height: calc(100vh - 3.5rem - 72px - 86px - 2px);
    overflow: auto;
    width: 480px;
    min-height: 432px;
    background-color: var(--gray-100);
    @include scrollbar();
  }
}

.noBorder {
  border: none;
}
.customDropdownButton {
  box-shadow: none !important;
  padding: 3px !important;
  line-height: 12px !important;
  position: relative;
  z-index: 1;

  &:hover,
  &:active,
  &:focus {
    color: var(--gray-600) !important;
    background-color: var(--gray-200) !important;
    outline: none;
  }
}
.dropDownContainer {
  display: none;
}

.IntentItemPlaceholder {
  align-self: center;
  width: 164px;
  padding: 32px 0;
  align-items: center !important;
  justify-content: center !important;

  &_text {
    max-width: 100%;
    text-align: center;
  }
}

.IntentContentPlaceholder {
  width: 288px;
  gap: 4px;
  align-self: center;
  align-items: center !important;
  justify-content: center !important;

  &_text {
    margin-bottom: 28px;
  }
}

.IntentDetail {
  padding: 16px;
  width: 100%;

  &_card {
    border-radius: 8px;
    width: 100%;
    background-color: var(--white);
    padding: 16px;
  }

  &_warning {
    padding: 8px 16px;
    gap: 8px;
    background-color: var(--yellow-100);
    border: 1px solid var(--yellow-300);
    color: var(--yellow-600);
    border-radius: 4px;
    font-size: 12px;
    line-height: 16px;
  }

  :global {
    .intents-page__add-phrase-block {
      margin-top: 0;
    }
    .intents-page__edit-phrase-block {
      margin: 0;
    }
    .justui-table__head,
    .virtual-table__checkbox-cell {
      display: none;
    }
    .intents-item-edit__table-row--wrapper:hover .intents-item-edit__table-row--view {
      box-shadow: inset 0 0 0 1px var(--grey-300);
    }
    .textAreaLikeElement {
      &.form-control,
      .textAreaLikeElement_text {
        line-height: 20px !important;
      }
    }
    .intents-item-edit__table-row--view span.phrase-text {
      min-height: 20px;
      line-height: 20px;
    }
  }
}

.ShowFullPath {
  margin-top: 8px;
  gap: 4px;
  padding: 12px;
  background-color: var(--gray-100);
  border-radius: 4px;
  b {
    line-height: 1rem;
  }
}

.disabledInputContrast {
  color: var(--gray-950) !important;
  background-color: var(--gray-100) !important;
  border: 1px solid var(--gray-200) !important;
}

.Search {
  left: 0;
  top: 0;
  background-color: var(--white);
  height: 100%;
  max-height: 100%;
  &_inputGroup {
    gap: 8px;
  }
  &_inputText {
    border: none;
    outline: none;
    padding: 2px 2px;
    line-height: 20px;
  }
  &_customButton {
    color: var(--gray-800);
    padding: 3px !important;
    line-height: 12px !important;
  }
}
