@use "sass:math";

.checkboxWithSmall{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;
  color: var(--gray-800, #333C45);

  small{
    color: var(--gray-600, #6A7178);
  }
}

.formGroupCustom{
  margin-bottom: 0 !important;
}
.formGroup{
  margin-bottom: #{math.div(20, 16)}rem!important;
}
.formGroupDisabled{
  label, .checkboxWithSmall, .checkboxWithSmall small{
    color: var(--gray-300, #C3C6C9);
  }
}

.LabelComponentSelectState{
  display: flex;
  div:nth-child(1){
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.LabelComponentSelectStateCreate{
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  letter-spacing: 0.02em;
  color: var(--gray-600, #6A7178);
}

button{
  &.noHoverEffect{
    &:hover{
      background-color: transparent!important;
    }
    &:hover,&:focus{
      outline: none !important;
      box-shadow: none !important;
    }
  }
}

.stateSelectionAndCreation{
  .title{
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0075em;
    color: var(--gray-800, #333C45);
    margin-bottom: 12px;
  }

  .RadioOffset{
    margin: 8px 0;
    padding-left: 22px;
  }
}
