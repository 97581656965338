.floatingMenu {
  position: absolute;
  min-width: 256px;
  background-color: var(--white, #fff);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 6px 12px rgba(12, 43, 66, 0.16), 0 0 1px rgba(17, 60, 92, 0.06);
  border-radius: 4px;
  padding: 8px 12px;
  z-index: 3;
  display: flex;
  flex-direction: row;
  align-items: center;

  .fromToContainer {
    display: flex;
    flex-grow: 1;
    border-right: 1px solid var(--gray-200, #e0e1e3);
    flex-direction: column;
    .fromTo {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      padding-right: 12px;

      .fromToName {
        cursor: pointer;
        color: var(--gray-800, #333c45);
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        letter-spacing: 0.0075em;
        border-bottom: 1px solid transparent;

        &:hover {
          color: var(--primary-600, #2375b0);
          border-bottom: 1px solid var(--primary-600);
        }
      }
    }
    .transitionName {
      text-align: center;
      font-size: 12px;
      line-height: 16px;
      align-items: center;
      letter-spacing: 0.01em;
      color: var(--gray-600, #6a7178);
    }
  }
  .delete {
  }
}
