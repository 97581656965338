.Unprojected_container {
  width: 242px;
  padding-left: 16px;
  padding-top: 1rem;

  position: relative;

  display: flex;
  flex-direction: column;

  background-color: var(--white);
  border-right: 1px solid #cfd8dc;

  & > a,
  & > button {
    color: var(--gray-800);
    padding: 0 1rem;
    height: 40px;
    width: 100%;
    border-radius: 4px;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    transition: background-color 300ms;
    font-size: 14px;
    font-weight: normal;
    cursor: pointer;
    &:hover,
    &.active {
      background-color: var(--gray-100);
      font-weight: bold;
      color: var(--gray-950);
      &:hover:not(.active) {
        font-weight: normal;
      }
    }
    &:focus{
      box-shadow: none;
    }
  }
  .divider {
    margin: 0.5rem 0;
    width: 100%;
    height: 1px;

    background-color: var(--gray-200);
  }
  a > button {
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
    &:focus {
      background-color: var(--primary);
    }
  }

  button.dropdown-item {
    color: var(--gray-800);
    outline: none;

    &:focus {
      outline: none;
      background-color: var(--primary);
      color: var(--white);
      * {
        color: var(--white) !important;
      }
    }
  }
}

.Unprojected_personalInfo {
  padding: 1rem;
  * {
    color: var(--gray-800);
  }
  b {
    font-weight: 500;
  }
  .Unprojected_date {
    color: var(--gray-600) !important;
  }
  a {
    display: block;
    margin-top: 0.5rem;
    text-decoration: var(--gray-600);
    text-decoration-line: underline;
    color: var(--gray-600) !important;
    span {
      color: var(--gray-600) !important;
      text-decoration-line: underline;
    }
  }
}

.header-menu__item_logout {
  color: var(--gray-800) !important;
}
