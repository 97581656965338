.sadmin-header {
  background-color: black;
  padding: 0 1rem;
  min-height: $sadminbar-height;

  .navbar-brand {
    color: white;
    padding: 0;
    font-size: 1.1rem;
    line-height: 1;
    text-decoration: none;
  }

  .nav {
    .nav-item {
      .nav-link {
        color: white;
        padding: 0.3rem 1rem;

        &.active {
          background-color: $brand-primary;
        }
      }

      .logout-link {
        padding: 0.3rem 1rem;
        display: block;
        color: #ffffff;
        color: var(--white);
      }
    }
  }
}

.account-manager {
  max-width: 1208px;
  color: var(--gray-800);

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: var(--gray-800);
  }

  .main-container > & {
    margin-left: 10%;

    .sidebar-hidden & {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .account-status {
    padding: 1px 9px;
    border-radius: 10px;
    font-size: 12px;
    line-height: 18px;
    font-weight: bold;

    &_active {
      color: $white;
      background-color: $success;
    }

    &_blocked {
      color: $white;
      background-color: $danger;
    }
    &_unverified {
      color: $white;
      background-color: $secondary;
    }
  }

  .separator {
    display: inline-block;
    margin: 0 10px;
  }

  .account-actions .card {
    height: 100%;
    p {
      font-size: 12px;
      color: $gray-light-alt;
    }
    b {
      color: var(--gray-800);
      font-size: 12px;
    }
  }
}

.change-tariff-modal {
  .tariff-items {
    input {
      display: none;
    }

    .row {
      margin-left: -5px;
      margin-right: -5px;
    }

    .col-3 {
      padding: 5px;
    }

    label {
      padding: 0;
      font-size: 0;
      display: block;
    }

    .tariff-item {
      border: 1px solid #797979;
      height: 90px;
      padding: 16px;
      border-radius: 3px;
      font-weight: bold;
      cursor: pointer;
      font-size: 0.875rem;

      &.current {
        border-color: #ffbe34;
      }

      &.active {
        padding: 15px;
        border-color: #ffbe34;
        border-width: 2px;
      }
    }
  }
}

.users-adding-modal {
  .user-package-label {
    width: calc(100% - 50px);
    margin-left: 10px;
    margin-bottom: 0;
    vertical-align: middle;
  }
}

.account-history {
  th {
    border: none;
  }

  .account-history-filter > div {
    display: inline-block;
  }
}

@media (max-width: 1750px) {
  .account-manager {
    .main-container > & {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media (max-width: 1500px) {
  .account-manager {
    .main-container > & {
      margin-left: 30px;
      margin-right: 30px;
    }
  }
}

@media (max-width: 1350px) {
  .sadmin-header .nav .nav-item .nav-link {
    padding: 0.3rem 0.4rem;
  }
}
