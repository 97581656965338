.justui_checkbox_container {
  height: 14px;
  width: 14px;
  box-sizing: border-box;
  border-radius: 2px;
  overflow: hidden;
  border: 1px solid #CFD8DC;
  flex-shrink: 0;
  & > * {
    padding: 0 !important;
    margin: 0 !important;
  }

  & > label {
    height: 12px;
    width: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    & > svg {
      display: none ;
    }
  }
  & > input {
    display: none !important;
    &:checked ~ label {
      background-color: var(--primary);
      & > svg {
        display: initial;
      }
    }
  }

}
