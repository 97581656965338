.ScriptSettings {
  height: 100%;

  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 8px;

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    label {
      margin-bottom: 0;
    }
  }

  &__fieldContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}
