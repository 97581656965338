@import '~@just-ai/just-ui/scss/basic/custom/functions';
@import '~@just-ai/just-ui/scss/basic/custom/variables';
@import '~bootstrap-scss/mixins/breakpoints';

.justui_search-input__search-icon {
  cursor: pointer;
}

.justui_search-input.input-group.compact {
  height: 24px;

  .IconButtons-wrap .btn-flat {
    height: unset;
    width: unset;
  }

  .IconButtons-wrap.IconButtons-wrap-prepend .btn-flat:last-child:nth-of-type(1) {
    margin-left: 0.5em;
  }

  .IconButtons-wrap.IconButtons-wrap-append .btn-flat:first-child:nth-last-of-type(1) {
    margin-left: -1.8em;
  }
}

.justui_search-input {
  input.form-control {
    &.dark {
      &:focus {
        border-color: $input-focus-border-color--dark !important;
      }
    }
    &:focus {
      z-index: 11;
    }
    &.right-sharp {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
    &.left-sharp {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }

  .IconButtons-wrap {
    z-index: 12;
    .justui__icon-wrapper {
      display: flex;
    }
  }

  &.input-group .IconButtons-wrap {
    z-index: 12;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .IconButtons-wrap-append {
    .justui_icon-button.spinner > svg {
      animation-name: spin;
      animation-duration: 2500ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }

  .input-group-append,
  .input-group-prepend {
    z-index: 10;
    .btn-flat {
      border-color: var(--secondary);
      padding: 0 0.375rem;
      height: 100%;
      .justui__icon-wrapper {
        display: flex;
      }
      &:active {
        border-color: $grey-800 !important;
      }
    }
  }
  .input-group-append {
    .btn-flat {
      border-radius: 0px 4px 4px 0px;
    }
  }
  .input-group-prepend {
    .btn-flat {
      border-radius: 4px 0px 0px 4px;
    }
  }
}
