.MultiLevelSelect {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  min-width: var(--dropdown-width);

  &.disabled {
    pointer-events: none;

    svg {
      color: var(--secondary) !important;
    }
  }

  &__content {
    padding: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid var(--gray-300);
    box-sizing: border-box;
    border-radius: 4px 0 0 4px;
    gap: 8px;

    font-size: 12px;
  }

  &__menu {
    padding-bottom: 0.5rem !important;
    min-width: var(--dropdown-width) !important;
  }

  &__menuElement {
    border: 1px solid var(--gray-300);

    &:not(.disabled) {
      &:hover {
        cursor: pointer;
        background: rgba(0, 0, 0, 0.04);
      }
      &:active {
        background: var(--gray-200);
      }
      &:focus {
        border-color: var(--indigo-600);
      }
    }

    &.disabled {
      color: var(--gray-300);
      svg {
        color: var(--gray-300);
      }
    }
  }

  &__dropdownToggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    border: 1px solid var(--gray-300);
    border-left: none;
    box-sizing: border-box;
    border-radius: 0 4px 4px 0;
  }
}
