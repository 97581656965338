.classifier-page {
  margin: -20px -30px 0 -30px;

  .card {
    margin-bottom: 0;
    min-height: calc(100vh - 140px);
  }
  .classifier {
    display: flex;
    flex-wrap: wrap;

    &--class-phrases {
      height: calc(100vh - 350px);
      max-width: 100%;
      word-break: break-all;
      overflow-y: auto;

      div {
        padding: 5px 0;
        line-height: 1;
        border-top: 1px solid $gray-lightest;

        &:first-child {
          border-top: none;
        }
      }
    }

    &-tree {
      margin-top: 1px;
      position: relative;
      min-height: calc(100vh - 140px);

      .change-width {
        position: absolute;
        right: -2px;
        height: 100%;
        width: 4px;
        top: 0;
        background: rgba(var(--gray-950), 0.5);
        cursor: w-resize;
        &:hover {
          box-shadow: 0 0 1px 1px rgba(#000, 0.9);
        }
      }
    }

    &-class {
      flex: 1;
      display: flex;
      flex-direction: column;

      .classifier_form {
        flex: 1 0 auto;
      }

      .classifier_actions {
        flex: 0 0 auto;
      }
    }

    &_actions {
      border-top: 1px solid var(--gray-300);
    }
  }
}

.modal .treebeard {
  max-height: 50vh !important;
}

.treebeard {
  .draggable {
    white-space: nowrap;
  }
}
